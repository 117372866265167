import React from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = () => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Dénomination <InputRequire/></Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="code" className="form-label">Code <InputRequire/></Label>
                    <Input
                        id="code"
                        name="code"
                        className="form-control"
                        placeholder="Veuillez entrer le code"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                            !!((validation.touched.code && validation.errors.code) || formError.code.length)
                        }
                    />
                    {(validation.touched.code && validation.errors.code) || formError.code.length ? (
                        <FormFeedback type="invalid">
                            {formError.code.length ? formError.code[0] : validation.errors.code}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="code" className="form-label">IFU <InputRequire/></Label>
                    <Input
                        id="ifu"
                        name="ifu"
                        className="form-control"
                        placeholder="Veuillez entrer l'ifu"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.ifu || ""}
                        invalid={
                            !!((validation.touched.code && validation.errors.ifu) || formError.ifu?.length)
                        }
                    />
                    {(validation.touched.ifu && validation.errors.ifu) || formError.ifu?.length ? (
                        <FormFeedback type="invalid">
                            {formError.ifu?.length ? formError.ifu[0] : validation.errors.ifu}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="telephone" className="form-label">Téléphone <InputRequire/></Label>
                    <Input
                        id="telephone"
                        name="telephone"
                        className="form-control"
                        placeholder="Veuillez entrer le téléphone"
                        type="tel"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.telephone || ""}
                        invalid={
                            !!((validation.touched.telephone && validation.errors.telephone) || formError.telephone.length)
                        }
                    />
                    {(validation.touched.telephone && validation.errors.telephone) || formError.telephone.length ? (
                        <FormFeedback type="invalid">
                            {formError.telephone.length ? formError.telephone[0] : validation.errors.telephone}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="address" className="form-label">Adresse <InputRequire/></Label>
                    <Input
                        id="address"
                        name="address"
                        className="form-control"
                        placeholder="Veuillez entrer l'addresse"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                            !!((validation.touched.address && validation.errors.address) || formError.address.length)
                        }
                    />
                    {(validation.touched.address && validation.errors.address) || formError.address.length ? (
                        <FormFeedback type="invalid">
                            {formError.address.length ? formError.address[0] : validation.errors.address}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="sector" className="form-label">Secteur <InputRequire/></Label>
                    <Input
                        id="sector"
                        name="sector"
                        className="form-control"
                        placeholder="Veuillez entrer le secteur"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.sector || ""}
                        invalid={
                            !!((validation.touched.sector && validation.errors.sector) || formError.sector.length)
                        }
                    />
                    {(validation.touched.sector && validation.errors.sector) || formError.sector.length ? (
                        <FormFeedback type="invalid">
                            {formError.sector.length ? formError.sector[0] : validation.errors.sector}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
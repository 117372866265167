import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {useAxios} from "../../hooks";
import {Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import {LabelingContainer} from "./LabelingCardsContainer";
import {toast} from "react-toastify";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";


const LocalLabeling = () => {
    const {axios} = useAxios();
    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [level, setLevel] = useState(null);
    const [labelingsLoading, setLabelingsLoading] = useState(false);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [labelings, setLabelings] = useState([]);
    const componentRef = useRef();
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: []
    });
    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id,
            label: item.name,
            level_id: item.level_id || '',
        }));
    }, []);

    useEffect(() => {
        setLoadFilterData(true);
        axios.get(`/api/labeling-filters`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    levels: formatData(data.levels),
                    buildings: [],
                    locals: []
                })
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
        ;
    }, [formatData]);
    document.title ="Etiquette | "+env('NAME');


    useEffect(() => {
        setLoadFilterData(true);
        axios.get(`/api/property-inventories-ecrats`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    levels: formatData(data.levels),
                    buildings: [],
                    locals: []
                })
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
        ;
    }, [formatData]);

    useEffect( () => {
        setLabelingsLoading(true);

        const newSite = site ? site.value : '';
        const newBuilding = building ? building.value : '';
        const newLocal = local ? local.value : '';
        const newLevel = level ? level.value : '';
        axios.get(`/api/labeling-locals?site_id=${newSite}&batiment_id=${newBuilding}&level_id=${newLevel}&local_id=${newLocal}`)
            .then(({data}) => {
                setLabelings(data)
            })
            .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
            .finally(() => setLabelingsLoading(false))
        ;

    }, [building, site, level, local]);

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            levels: filterData.levels,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                levels: filterData.levels,
                buildings: formatData(value.batiments),
                locals: []
            })
        }
    }

    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            levels: filterData.levels,
            locals: []
        });
        setBuilding(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                levels: filterData.levels,
                locals: formatData(value.locals)
            })
        }
    }
    const handleLevelChange = (value) => {
        setLocal(null);
        setLevel(value);
    }

    const handlePrint =  () => {
        setPrinting(true)
        const newSite = site ? site.value : '';
        const newBuilding = building ? building.value : '';
        const newLocal = local ? local.value : '';
        const newLevel = level ? level.value : '';
        axios.get(`/api/export-labeling-locals?site_id=${newSite}&batiment_id=${newBuilding}&local_id=${newLocal}&level_id=${newLevel}`,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then((data) => {
                let blob = new Blob([data.data], { type: 'application/pdf' });
                let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                let anchor = document.createElement('a');
                anchor.href = url;
                anchor.target = '_blank';
                anchor.click();
            })
            .catch(error => {
                console.log({error})
                toast( "Echec d'exportation", {type: 'error'});

            })
            .finally(() => setPrinting(false));
    }

    return (
        <PageContent title={"Etiquettes Bureaux"} pageTitle={"Tableau de bord"}>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">Filtre</h5>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button disabled={printing} onClick={handlePrint}
                                        className="btn btn-primary">
                                    <i className="ri-printer-line align-bottom"></i> Imprimer
                                </button>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <Row className="mb-3 g-3">
                                <Col sm={4}>
                                    <ReactSelect
                                        id="site"
                                        name="site"
                                        placeholder="Veuillez sélectionner le site"
                                        value={site}
                                        onChange={handleSiteChange}
                                        options={filterData.sites}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <ReactSelect
                                        id="building"
                                        name="building"
                                        placeholder="Veuillez sélectionner le batiment"
                                        value={building}
                                        onChange={handleBuildingChange}
                                        options={filterData.buildings}
                                        isLoading={loadFilterData}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <ReactSelect
                                        id="levels"
                                        name="levels"
                                        placeholder="Veuillez sélectionner un niveau"
                                        value={level}
                                        onChange={handleLevelChange}
                                        options={filterData.levels}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <ReactSelect
                                        id="local"
                                        name="local"
                                        placeholder="Veuillez sélectionner le local"
                                        value={local}
                                        onChange={value => {setLocal(value)}}
                                        options={filterData.locals.filter((item) => level?.value ? item.level_id === level.value : true)}
                                        isLoading={loadFilterData}
                                    />
                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card>
                {labelingsLoading && (
                    <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center" style={{top: "0"}}>
                        <Spinner color={"info"} size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                    </div>
                )}
                <CardBody>
                    <LabelingContainer labelingCards={labelings} ref={componentRef} />
                </CardBody>
            </Card>
        </PageContent>
    );
};

export default LocalLabeling;
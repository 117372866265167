import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";

const Cities = () => {
    document.title ="Villes | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Pays",
                accessor: "country.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);
    const [country, setCountry] = useState(null);
    const {list, loading} = useFetchDataList('/api/cities/create', (data) => {
        return data.map(item => {
            return {
                label: item.name,
                value: item.id,
            }
        });
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter une ville',
                update: 'Modifier une ville'
            },
            formDefaultError: {name: [], city_id: []},
            initialValues: {name: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer la dénomination"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    country_id: country ? country.value : ''
                };
            },
            formatResponse: data => {
                let result = list.filter(item => item.value === data.country_id);
                result = result.length ? result[0] : null;
                return {
                    ...data,
                    country: {
                        name: result ? result.label : ''
                    }
                };
            },
            onSuccess: (response, type) => {
                setCountry(null);
            },
            onFail: (response, type) => {},
        };
    }, [country, list]);

    const onUpdateEntity = useCallback((editData) => {
        let result = list.filter(item => item.value === editData.country_id);
        result = result.length ? result[0] : null;
        if (result) {
            setCountry(result);
        }
    }, [list]);

    const onAddEntity = useCallback(() => {
        setCountry(null);
    }, []);

    return (
        <PageContent title={"Villes"} pageTitle={"Localisation"}>
            <DatatablePageProvider
                title={""}
                endpoint={"/api/cities"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-city"}
                updatePermission={"edit-city"}
                deletePermission={"destroy-city"}
            >
                <Datatable
                    country={country}
                    setCountry={setCountry}
                    loading={loading}
                    countries={list}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Cities;
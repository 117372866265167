import React, {useState} from "react";
import {Button, Col, FormFeedback, Input, Label, Table, NavLink} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({
       fiscalYear, setFiscalYears, list,
       loading, building, local, site,
       setSite, setLocal, setBuilding,
       setInventoriste, inventoriste, buildings,
       setBuildings, setLocals, locals,
       otherData, setOtherData, addText = ""
}) => {
    const {validation, formError} = useDatatablePage();

    const handleSiteChange = (newSite) => {
        if (building?.value) {
            setBuilding(null);
        }
        if (buildings.length) {
            setBuildings([]);
        }
        if (local?.value) {
            setLocal(null);
        }
        if (locals.length) {
            setLocals([]);
        }
        setSite(newSite);
        if (newSite) {
            setBuildings(newSite.batiments.map(item => {
                return {
                    ...item,
                    value: item.id,
                    label: item.name
                };
            }));
        }
    };

    function handleBuildingChange (newBuilding) {
        if (local?.value) {
            setLocal(null);
        }
        if (locals.length) {
            setLocals([]);
        }
        setBuilding(newBuilding);
        if (newBuilding) {
            setLocals(newBuilding.locals.map(item => ({
                ...item,
                value: item.id,
                label: item.name
            })));
        }
    }

    const addToOtherData = () => {
        if (inventoriste && local && local.length) {
            setOtherData([
                ...otherData,
                {
                    inventorists: inventoriste,
                    locals: local
                }
            ]);
            setLocals([]);
            setLocal(null);
            setBuildings([]);
            setBuilding(null);
            setSite(null);
            setInventoriste(null);
        }
    };

    return (
        <DatatablePage formWidth={"lg"} addText={addText}>
            <h4>Informations de base</h4>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Nom <InputRequire/></Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="date" className="form-label">Date <InputRequire/></Label>
                    <Input
                        id="date"
                        name="date"
                        className="form-control"
                        placeholder="Veuillez entrer la date"
                        type="date"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.date || ""}
                        invalid={
                            !!((validation.touched.date && validation.errors.date) || formError.date.length)
                        }
                    />
                    {(validation.touched.date && validation.errors.date) || formError.date.length ? (
                        <FormFeedback type="invalid">
                            {formError.date.length ? formError.date[0] : validation.errors.date}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            {/*<h4>Configuration</h4>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="inventoriste" className="form-label">Inventoriste</Label>
                    <ReactSelect
                        id="inventoriste"
                        name="inventoriste"
                        placeholder="Veuillez sélectionner l'inventoriste"
                        value={inventoriste}
                        onChange={value => setInventoriste(value)}
                        options={list?.inventorists || []}
                        isLoading={loading}
                    />
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="site" className="form-label">Site</Label>
                    <ReactSelect
                        id="site"
                        name="site"
                        placeholder="Veuillez sélectionner le site"
                        value={site}
                        onChange={handleSiteChange}
                        options={list?.sites || []}
                        isLoading={loading}
                    />
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="building" className="form-label">Batiment</Label>
                    <ReactSelect
                        id="building"
                        name="building"
                        placeholder="Veuillez sélectionner le batiment"
                        value={building}
                        onChange={handleBuildingChange}
                        options={buildings}
                        isLoading={loading}
                    />
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="local" className="form-label">Local</Label>
                    <ReactSelect
                        isMulti={true}
                        id="local"
                        name="local"
                        placeholder="Veuillez sélectionner le local"
                        value={local}
                        onChange={value => setLocal(value)}
                        options={locals || []}
                        isLoading={loading}
                    />
                </div>
            </Col>

            <Col>
                <Button type="button" color="secondary" onClick={addToOtherData}>Ajouter</Button>
            </Col>

            <Col sm={12}>
                <div className="table-responsive">
                    <Table className="align-middle table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">Inventoriste</th>
                            <th scope="col">Locals</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {otherData.map((item, index) => (
                            <tr key={index}>
                                <td>{item?.inventorists?.name}</td>
                                <td>{item?.locals.map(item => item?.name).join(" / ")}</td>
                                <td>
                                    <NavLink to="#" className="link-success cursor-pointer" onClick={() => {
                                        setOtherData(otherData.filter((item, itemIndex) => index !== itemIndex))
                                    }}>
                                        Supprimer<i className="ri-delete-back-2-line align-middle"></i>
                                    </NavLink>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
            </Col>*/}
        </DatatablePage>
    );
};

export default Datatable;
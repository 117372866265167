import React from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";

const Datatable = ({file, setFile}) => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage formWidth="xl">
            <h4>Information sur la société</h4>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="company_code" className="form-label">Code</Label>
                    <Input
                        id="company_code"
                        name="company_code"
                        className="form-control"
                        placeholder="Veuillez entrer le code"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_code || ""}
                        invalid={
                            !!((validation.touched.company_code && validation.errors.company_code) || formError.company_code.length)
                        }
                    />
                    {(validation.touched.company_code && validation.errors.company_code) || formError.company_code.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_code.length ? formError.company_code[0] : validation.errors.company_code}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="company_name" className="form-label">Dénomination</Label>
                    <Input
                        id="company_name"
                        name="company_name"
                        className="form-control"
                        placeholder="Veuillez entrer la Dénomination"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_name || ""}
                        invalid={
                            !!((validation.touched.company_name && validation.errors.company_name) || formError.company_name.length)
                        }
                    />
                    {(validation.touched.company_name && validation.errors.company_name) || formError.company_name.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_name.length ? formError.company_name[0] : validation.errors.company_name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="company_address" className="form-label">Adresse</Label>
                    <Input
                        id="company_address"
                        name="company_address"
                        className="form-control"
                        placeholder="Veuillez entrer l'adresse"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_address || ""}
                        invalid={
                            !!((validation.touched.company_address && validation.errors.company_address) || formError.company_address.length)
                        }
                    />
                    {(validation.touched.company_address && validation.errors.company_address) || formError.company_address.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_address.length ? formError.company_address[0] : validation.errors.company_address}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="company_phone" className="form-label">Téléphone</Label>
                    <Input
                        id="company_phone"
                        name="company_phone"
                        className="form-control"
                        placeholder="Veuillez entrer le téléphone"
                        type="tel"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_phone || ""}
                        invalid={
                            !!((validation.touched.company_phone && validation.errors.company_phone) || formError.company_phone.length)
                        }
                    />
                    {(validation.touched.company_phone && validation.errors.company_phone) || formError.company_phone.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_phone.length ? formError.company_phone[0] : validation.errors.company_phone}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="company_sectors" className="form-label">Secteurs d'activités</Label>
                    <Input
                        id="company_sectors"
                        name="company_sectors"
                        className="form-control"
                        placeholder="Veuillez entrer le secteur d'activité"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_sectors || ""}
                        invalid={
                            !!((validation.touched.company_sectors && validation.errors.company_sectors) || formError.company_sectors.length)
                        }
                    />
                    {(validation.touched.company_sectors && validation.errors.company_sectors) || formError.company_sectors.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_sectors.length ? formError.company_sectors[0] : validation.errors.company_sectors}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="company_city" className="form-label">Ville</Label>
                    <Input
                        id="company_city"
                        name="company_city"
                        className="form-control"
                        placeholder="Veuillez entrer la ville"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_city || ""}
                        invalid={
                            !!((validation.touched.company_city && validation.errors.company_city) || formError.company_city.length)
                        }
                    />
                    {(validation.touched.company_city && validation.errors.company_city) || formError.company_city.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_city.length ? formError.company_city[0] : validation.errors.company_city}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="company_logo" className="form-label">Logo</Label>
                    <Input
                        id="company_logo"
                        name="company_logo"
                        className="form-control"
                        placeholder="Veuillez entrer le logo"
                        type="file"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={e => {
                            setFile({
                                name: e.target.value,
                                file: e.target.files[0]
                            });
                        }}
                        onBlur={validation.handleBlur}
                        value={file.name}
                        invalid={
                            !!(formError.company_logo.length)
                        }
                    />
                    {(validation.touched.company_logo && validation.errors.company_logo) || formError.company_logo.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_logo.length ? formError.company_logo[0] : validation.errors.company_logo}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="company_description" className="form-label">Description</Label>
                    <Input
                        id="company_description"
                        name="company_description"
                        className="form-control"
                        placeholder="Veuillez entrer la description"
                        type="textarea"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.company_description || ""}
                        invalid={
                            !!((validation.touched.company_description && validation.errors.company_description) || formError.company_description.length)
                        }
                    />
                    {(validation.touched.company_description && validation.errors.company_description) || formError.company_description.length ? (
                        <FormFeedback type="invalid">
                            {formError.company_description.length ? formError.company_description[0] : validation.errors.company_description}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <h4>Information sur l'administrateur</h4>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="admin_civility" className="form-label">Civilité</Label>
                    <Input
                        id="admin_civility"
                        name="admin_civility"
                        className="form-control"
                        placeholder="Veuillez choisir la civilité"
                        type="select"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin_civility || ""}
                        invalid={
                            !!((validation.touched.admin_civility && validation.errors.admin_civility) || formError.admin_civility.length)
                        }
                    >
                        <option value='' disabled={true} selected={true}>Veuillez choisir la civilité</option>
                        <option value="M.">M.</option>
                        <option value="Mme">Mme</option>
                        <option value="Mlle">Mlle</option>
                    </Input>
                    {(validation.touched.admin_civility && validation.errors.admin_civility) || formError.admin_civility.length ? (
                        <FormFeedback type="invalid">
                            {formError.admin_civility.length ? formError.admin_civility[0] : validation.errors.admin_civility}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="admin_name" className="form-label">Nom</Label>
                    <Input
                        id="admin_name"
                        name="admin_name"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin_name || ""}
                        invalid={
                            !!((validation.touched.admin_name && validation.errors.admin_name) || formError.admin_name.length)
                        }
                    />
                    {(validation.touched.admin_name && validation.errors.admin_name) || formError.admin_name.length ? (
                        <FormFeedback type="invalid">
                            {formError.admin_name.length ? formError.admin_name[0] : validation.errors.admin_name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="admin_first_name" className="form-label">Prénom</Label>
                    <Input
                        id="admin_first_name"
                        name="admin_first_name"
                        className="form-control"
                        placeholder="Veuillez entrer le prénom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin_first_name || ""}
                        invalid={
                            !!((validation.touched.admin_first_name && validation.errors.admin_first_name) || formError.admin_first_name.length)
                        }
                    />
                    {(validation.touched.admin_first_name && validation.errors.admin_first_name) || formError.admin_first_name.length ? (
                        <FormFeedback type="invalid">
                            {formError.admin_first_name.length ? formError.admin_first_name[0] : validation.errors.admin_first_name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="admin_email" className="form-label">Email</Label>
                    <Input
                        id="admin_email"
                        name="admin_email"
                        className="form-control"
                        placeholder="Veuillez entrer le nom de l'email"
                        type="email"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin_email || ""}
                        invalid={
                            !!((validation.touched.admin_email && validation.errors.admin_email) || formError.admin_email.length)
                        }
                    />
                    {(validation.touched.admin_email && validation.errors.admin_name) || formError.admin_email.length ? (
                        <FormFeedback type="invalid">
                            {formError.admin_email.length ? formError.admin_email[0] : validation.errors.admin_email}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="admin_address" className="form-label">Addresse</Label>
                    <Input
                        id="admin_address"
                        name="admin_address"
                        className="form-control"
                        placeholder="Veuillez entrer le nom de l'addresse"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin_address || ""}
                        invalid={
                            !!((validation.touched.admin_address && validation.errors.admin_address) || formError.admin_address.length)
                        }
                    />
                    {(validation.touched.admin_address && validation.errors.admin_address) || formError.admin_address.length ? (
                        <FormFeedback type="invalid">
                            {formError.admin_address.length ? formError.admin_address[0] : validation.errors.admin_address}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="admin_telephone" className="form-label">Téléphone</Label>
                    <Input
                        id="admin_telephone"
                        name="admin_telephone"
                        className="form-control"
                        placeholder="Veuillez entrer le téléphone"
                        type="tel"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.admin_telephone || ""}
                        invalid={
                            !!((validation.touched.admin_telephone && validation.errors.admin_telephone) || formError.admin_telephone.length)
                        }
                    />
                    {(validation.touched.admin_telephone && validation.errors.admin_telephone) || formError.admin_telephone.length ? (
                        <FormFeedback type="invalid">
                            {formError.admin_telephone.length ? formError.admin_telephone[0] : validation.errors.admin_telephone}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
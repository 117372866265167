import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col, DropdownItem, DropdownMenu, DropdownToggle, Input, Label,
    Row, UncontrolledDropdown,
} from "reactstrap";
import {toast} from "react-toastify";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import DbDatatable from "../../Components/Common/DbDatatable";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";
import {useAxios} from "../../hooks";
import {Date, Text} from "../../Components/Common/ColTableFormat";
import ReactSelect from "../../Components/Common/ReactSelect";
import {formatDatInput} from "../../helpers";
import {Link} from "react-router-dom";

const HistoryInventories = () => {
    document.title ="Biens | "+env('NAME');
    const {axios} = useAxios();
    const [entities, setEntities] = useState([]);
    const [entitiesLoading, setEntitiesLoading] = useState(false);
    const [fiscalYear, setFiscalYear] = useState(null);
    const [fiscalYears, setFiscalYears] = useState([]);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [maxDate, setMaxDate] = useState({
        start: "",
        end: ""
    });

    // Fetch Data
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        setEntitiesLoading(true);
        const newFiscalYear = fiscalYear ? fiscalYear.value : null;
        axios.get(`/api/history-inventories/filters?page=${pageIndex}&per_page=${pageSize}&search=${search}&fiscalYears=${newFiscalYear}&begin_at=${startDate}&end_at=${endDate}`)
            .then(({data}) => {
                console.log("data:", data);
                setEntities(data.data || []);
            })
            .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
            .finally(() => setEntitiesLoading(false))
        ;
    }, [endDate, fiscalYear, startDate]);

    useEffect(() => {
        setLoadingCreate(true);
        axios.get(`/api/history-inventories`)
            .then(({data}) => {
                console.log("data:", data);
                function formatData (array) {
                    if (Array.isArray(array)) {
                        return array.map(item => ({
                            value: item.id,
                            label: item.name
                        }));
                    }
                    return [];
                }
                setFiscalYears(data.fiscalYears.map(item => ({value: item.id, label: item.name})));
                setMaxDate({
                    start: formatDatInput(data.fiscalDateFilters.begin_at),
                    end: formatDatInput(data.fiscalDateFilters.end_at)
                })
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadingCreate(false)})
        ;
    }, []);

    useEffect(() => {
        const newValue = fiscalYear;
        fetchData();
    }, [fetchData, fiscalYear]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Exercice fiscal",
                accessor: "fiscal_year.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Nom",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Date",
                accessor: "date",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Total bien",
                accessor: "inventories_count",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
               Header: "Actions",
               accessor: "property.is_editable",
               Cell: (cellProps) => {
                   const button = (
                       <DropdownToggle tag="a" className="btn btn-soft-secondary btn-sm">
                           <i className="ri-more-fill align-middle"/>
                       </DropdownToggle>
                   );

                   const detail = (
                       <Link
                           to={`/inventories/${cellProps.row.original.id}`}
                           className="edit-item-btn dropdown-item"
                       >
                           <i className="ri-eye-2-line align-bottom me-2 text-muted"></i> Détail
                       </Link>
                   );

                   return (
                       <UncontrolledDropdown>
                           {button}

                           <DropdownMenu className="dropdown-menu-end">
                               {detail}
                           </DropdownMenu>
                       </UncontrolledDropdown>
                   );
               },
           },
        ]
    }, []);

    return (
        <PageContent title={"Inventaires"} pageTitle={"Historique inventaire"}>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">Historique des inventaires</h5>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <Row className="mb-3">
                                <Col sm={12} lg={6} xl={4} className="mb-3">
                                    <Label htmlFor="standard" className="form-label">Exercice comptable</Label>
                                    <ReactSelect
                                        id="standard"
                                        name="standard"
                                        placeholder="Veuillez sélectionner l'exercice"
                                        value={fiscalYear}
                                        onChange={value => {setFiscalYear(value)}}
                                        options={fiscalYears}
                                        isLoading={loadingCreate}
                                    />
                                </Col>

                                <Col sm={12} lg={6} xl={4}>
                                    <div id="modal-id mb-3">
                                        <Label htmlFor="start_date" className="form-label">Date de début</Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="start_date"
                                            placeholder="Veuillez entrer la date de début"
                                            value={startDate}
                                            min={maxDate.start}
                                            max={maxDate.end}
                                            onChange={e => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </Col>

                                <Col sm={12} lg={6} xl={4}>
                                    <div id="modal-id mb-3">
                                        <Label htmlFor="end_date" className="form-label">Date de fin</Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="end_date"
                                            placeholder="Veuillez entrer la date de fin"
                                            value={endDate}
                                            min={maxDate.start}
                                            max={maxDate.end}
                                            onChange={e => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <DbDatatable
                                columns={columns}
                                data={entities}
                                className="custom-header-css"
                                loading={entitiesLoading}
                                fetchData={fetchData}
                                perPage={entities ? entities['per_page'] : undefined}
                                totalPage={entities ? entities['last_page'] : undefined}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
};

export default HistoryInventories;
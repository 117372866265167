import React from "react";
import {Col, FormFeedback, Input, InputGroup, InputGroupText, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({family, setFamily, families, standards, parent, setParent, loading, active, setActive}) => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
           {/* <Col lg={12} className="mb-3">
                <div className="form-check form-switch form-switch-custom form-switch-primary">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="active"
                        checked={active}
                        onChange={e => setActive(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="active">Etiquette réquise ?</label>
                </div>
            </Col>*/}

            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="family_id" className="form-label">Famille <InputRequire/></Label>
                    <ReactSelect
                        id="family_id"
                        name="family_id"
                        placeholder="Veuillez sélectionner la famille"
                        value={family}
                        onChange={value => setFamily(value)}
                        options={families}
                        isLoading={loading}
                        error={formError['family_id']}
                    />
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="code" className="form-label">Code <InputRequire/></Label>
                    <InputGroup>
                        <InputGroupText>
                            {family?.code}
                        </InputGroupText>
                    <Input
                        id="code"
                        name="code"
                        className="form-control"
                        placeholder="Veuillez entrer le code"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                            !!((validation.touched.code && validation.errors.code) || formError.code.length)
                        }
                    />
                        {(validation.touched.code && validation.errors.code) || formError.code.length ? (
                            <FormFeedback type={'invalid'}>
                                {formError.code.length ? formError.code[0] : validation.errors.code}
                            </FormFeedback>
                        ) : null}
                    </InputGroup>

                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Dénomination <InputRequire/></Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer la dénomination"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

        </DatatablePage>
    );
};

export default Datatable;
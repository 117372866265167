import React, {useEffect, useState} from "react";
import {
    Badge,
    Button,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    NavLink,
    Row, Spinner,
    Table
} from "reactstrap";
import InventoristForm from "./InventoristForm";
import axios from "axios";
import {toast} from "react-toastify";
import {downloadReport} from "../../helpers";
import { useHistory } from "react-router-dom";

const InventoristList = ({toggle, open, inventory,inventoristData,localsInventoried, refresh}) => {

    const [inventorist,setInventorist] = useState(null)
    const [editModal,setEditModal] = useState(false)
    const [confirmationModal,setConfirmationModal] = useState(false)
    const [invUser,setInvUser] = useState(null)
    const [submitting,setSubmitting] = useState(null)
    const location = useHistory();

    useEffect(() => {
    }, [inventory]);

    const editInventorist = (item = null) => {
        //toggle()
        //setInventorist(item)
        //setEditModal(true)
        location.push(`/inventory-config/${inventory?.id}/${item.user_id}`)
    }

    const confirmDeletion = (item) => {
       setInvUser(item);
       setConfirmationModal(true)
    }
    const deleteInventorist = () => {
        axios.delete(`/api/inventorist-locals/${invUser?.id}`)
            .then(({data}) => {
                toast.success("Inventoriste supprimé avec succès");
                setConfirmationModal(false)
                window.location.reload()
            })
            .catch(() => {
                console.log("Something is wrong")});
    }
    const exportInventorists = () => {
        setSubmitting(true)
        axios.get(`/api/exports/inventorists/${inventory?.id}`,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data,'liste-inventoristes','xlsx')
                toast("Exportation effectuée avec succès", {type: 'success'});
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});

            })
            .finally(() => setSubmitting(false))
        ;
    }

    return (
        <>
            <Modal id="Inventorist" isOpen={open} centered toggle={toggle} size="lg" className="border-0" modalClassName="zoomIn">
                <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                    Liste des inventoristes
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={12}>
                            <div className="table-responsive">
                                {
                                    inventory?.inventorists?.length >0 ?
                                        <Table className="align-middle table-nowrap mb-0">
                                            <thead>
                                            <tr>
                                                <th scope="col">Inventoriste</th>
                                                <th scope="col">Site</th>
                                                <th scope="col">Batiments</th>
                                                <th scope="col">Bureaux</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {inventory?.inventorists?.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{item?.user.name}</td>
                                                    <td>{item?.site.name}</td>
                                                    <td>
                                                        {
                                                            item.batiments?.length ? item.batiments?.map((item,index) => (
                                                                <div key={index}>{item.name}</div>
                                                            )) : <span>---</span>

                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            item.locals?.length ? item.locals?.map((item,index) => (
                                                                <div key={index} className={'bg-light p-1 mt-2 rounded'}>
                                                                    <div>{item.full_name}</div>
                                                                    <div>
                                                                        {
                                                                            localsInventoried?.includes(item.id) ?
                                                                                <Badge color={'primary'} className={'rounded-pill'}>exporté</Badge>
                                                                                :
                                                                                <Badge color={'danger'} className={'rounded-pill'}>non exporté</Badge>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            )) : <span>---</span>

                                                        }
                                                    </td>
                                                    <td>
                                                        <div className={'d-flex'}>
                                                            <NavLink to="#" className="link-success cursor-pointer" onClick={() => { editInventorist(item) }}>
                                                                <i className="ri-edit-2-line align-middle"></i>
                                                            </NavLink>
                                                            <NavLink to="#" color={'danger'} className="link-success cursor-pointer" onClick={() => { confirmDeletion(item) }}>
                                                                <i className="ri-delete-bin-2-line align-middle"></i>
                                                            </NavLink>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </Table>
                                        :
                                        <div className={"text-center"}>
                                            Aucun inventoriste n'est encore configuré.

                                            <NavLink to="#" className="link-success cursor-pointer" onClick={() => editInventorist()}>
                                                Ajouter un inventoriste ici
                                            </NavLink>
                                        </div>
                                }
                            </div>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <button type="submit" className="btn btn-primary" id="add-btn" onClick={() => exportInventorists()}>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <i className="ri-download-2-line align-bottom"></i> {"Exporter"}
                    </button>
                    <Button color="light" onClick={toggle}>
                        Fermer
                    </Button>
                </ModalFooter>
            </Modal>

            {editModal &&
                <InventoristForm
                inventory={inventory}
                toggle={() => setEditModal(!editModal)}
                inventoristData={inventoristData}
                open={editModal}
                inventorist={inventorist}/>
            }

            <Modal id="ConfirmationModal" isOpen={confirmationModal}  size="md" className="border-0" modalClassName="zoomIn">
                <ModalHeader toggle={() => setConfirmationModal(!confirmationModal) } className="p-3 bg-soft-info">
                    Confirmer la suppression
                </ModalHeader>
                <ModalBody>
                    <Row className="g-3 text-center">
                        Êtes vous sûre de vouloir vraiment supprimé cet inventoriste?
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => setConfirmationModal(false)}>
                        Annuler
                    </Button>

                    <button type="submit" className="btn btn-success" id="add-btn" onClick={deleteInventorist}>
                        {/*{closingInv && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}*/} Supprimer
                    </button>
                </ModalFooter>
            </Modal>

        </>
    );
};

export default InventoristList;
import React, {useState} from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({role, setRole, loading, roles, staff, setStaff, staffs}) => {
    const {validation, formError} = useDatatablePage();
    const [inputType, setInputType] = useState("password");

    return (
        <DatatablePage>
            {(staffs !== null && staffs.length > 0) && (
                <Col lg={6}>
                    <div id="modal-id">
                        <Label htmlFor="staff_id" className="form-label">Agents</Label>
                        <ReactSelect
                            id="staff_id"
                            name="staff_id"
                            placeholder={`Veuillez sélectionner l'agent`}
                            value={staff}
                            onChange={value => {
                                setStaff(value)
                            }}
                            options={staffs}
                            isLoading={loading}
                            error={formError['staff_id']}
                        />
                    </div>
                </Col>
            )}
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Nom <InputRequire /> </Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="email" className="form-label">Email <InputRequire /> </Label>
                    <Input
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Veuillez entrer l'email"
                        type="email"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                            !!((validation.touched.email && validation.errors.email) || formError.email.length)
                        }
                    />
                    {(validation.touched.email && validation.errors.email) || formError.email.length ? (
                        <FormFeedback type="invalid">
                            {formError.email.length ? formError.email[0] : validation.errors.email}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={(staffs !== null && staffs.length) ? 6 : 12}>
                <div id="modal-id">
                    <Label htmlFor="roles" className="form-label">Roles <InputRequire /> </Label>
                    <ReactSelect
                        id="roles"
                        name="roles"
                        isMulti={true}
                        placeholder={`Veuillez sélectionner les roles`}
                        value={role}
                        onChange={value => setRole(value)}
                        options={roles}
                        isLoading={loading}
                        error={formError['roles']}
                    />
                    { formError.roles.length ? (
                        <FormFeedback type="invalid">
                            {formError.roles.length ? formError.roles[0] : validation.errors.roles}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={12}>
                <Label htmlFor="password" className="form-label">Mot de passe <InputRequire /> </Label>
                <div id="modal-id" className="position-relative auth-pass-inputgroup mb-3">
                    <Input
                        name="password"
                        type={inputType}
                        className="form-control pe-5"
                        placeholder="Veuillez entrer le mot de passe"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        value={validation.values.password || ""}
                        invalid={
                            !!(formError.password.length)
                        }
                    />
                    {(validation.touched.password && validation.errors.password) || formError.password.length ? (
                        <FormFeedback type="invalid">
                            {formError.password.length ? formError.password[0] : validation.errors.password}
                        </FormFeedback>
                    ) : null}
                    <button onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
import React, {useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Spinner} from "reactstrap";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {useAxios} from "../../hooks";
import {removeNullValue} from "../../helpers";
import {toast} from "react-toastify";

const ResetPassword = props => {
    const history = useHistory();
    const defaultError = {
        code: [],
        password: []
    };
    const {axios} = useAxios();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(defaultError);
    const [ inputType, setInputType] = useState("password");

    useEffect(() => {
        const button = document.getElementById("password-addon");
    });

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            code: '',
            password: '',
        },
        validationSchema: Yup.object({
            code: Yup.string().required("Veuillez entrer le code"),
            password: Yup.string().required("Veuillez entrer le nouveau mote de passe"),
        }),
        onSubmit: (values) => {
            setLoading(true);
            axios.post(`/api/forgot/password/reset`, removeNullValue({code: values.code.toString(), password: values.password}))
                .then(() => {
                    toast.success("Veuillez consulter votre mail");
                    history.push("/");
                })
                .catch(({response}) => {
                    setError({...defaultError, ...response.data.errors})
                    toast.error("Echec de la réinitialisation du mot de passe.");
                })
                .finally(() => {setLoading(false)});
        }
    });

    document.title ="Réinitialisation mot de passe | "+process.env.REACT_APP_NAME;
    return (
        <ParticlesAuth>
            <div className="auth-page-content">

                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={"/imgs/logo.jpg"} className="rounded-circle" alt="" height="90" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Mot de passe oublié?</h5>
                                        <lord-icon
                                            src="https://cdn.lordicon.com/rhvddzym.json"
                                            trigger="loop"
                                            colors="primary:#0ab39c"
                                            className="avatar-xl"
                                            style={{ width: "120px", height: "120px" }}
                                        >
                                        </lord-icon>

                                    </div>

                                    <Alert className="alert-borderless alert-info text-center mb-2 mx-2" role="alert">
                                        Veuillez saisir le code que vous avez reçu par mail, et votre nouveau mot de passe
                                    </Alert>
                                    <div className="p-2">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-4">
                                                <Label className="form-label">Code</Label>
                                                <Input
                                                    name="code"
                                                    className="form-control"
                                                    placeholder="Entrez le code"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.code || null}
                                                    invalid={
                                                        !!(validation.touched.code && validation.errors.code) || error.code.length
                                                    }
                                                />

                                                {(validation.touched.code && validation.errors.code) || error.code.length ? (
                                                    <FormFeedback type="invalid">
                                                        <div>{validation.errors.code || error.code[0]}</div>
                                                    </FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-4">

                                                <Label className="form-label">Nouveau mot de passe</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="password"
                                                        type={inputType}
                                                        className="form-control pe-5"
                                                        placeholder="Veuillez entrer le mot de passe"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            !!(validation.touched.password && validation.errors.password) || error.password.length
                                                        }
                                                    />
                                                    {(validation.touched.password && validation.errors.password) || error.password.length ? (
                                                        <FormFeedback type="invalid">
                                                            <div>{validation.errors.password || error.password[0]}</div>
                                                        </FormFeedback>
                                                    ) : null}
                                                    <button onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button"><i className="ri-eye-fill align-middle"></i></button>
                                                </div>
                                            </div>

                                            <div className="text-center mt-4">
                                                <button className="btn btn-success w-100" type="submit">
                                                    <span className="d-flex align-items-center justify-content-center">
                                                            {loading && (
                                                                <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement... </Spinner>
                                                            )}
                                                        <span className="ms-2">{loading ? "Traitement" : "Réinitialiser"}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-4 text-center">
                                <p className="mb-0">Attendez, je me souviens de mon mot de passe...  <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Cliquez ici </Link> </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default withRouter(ResetPassword);

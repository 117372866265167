import React, {useEffect, useState} from "react";
import {Link, useHistory, useParams,useLocation} from "react-router-dom";

import {
    Card,
    CardBody,
    CardHeader,
    Row,
    Col, Nav, NavItem, NavLink, TabContent, TabPane, Label, Input, FormFeedback, Spinner
} from "reactstrap";
import { loadAnimation } from "lottie-web";
import {toast} from "react-toastify";
import classnames from "classnames";
import env from "../../helpers/env";
import {useAxios, useFetchDataList} from "../../hooks";
import PageContent from "../../Layouts/PageContent";
import {InputRequire} from "../../Components/personal/utils";
import ReactSelect from "../../Components/Common/ReactSelect";
import { defineLordIconElement } from "lord-icon-element";
import {formatDatInput, removeNullValue, scrollTopPage} from "../../helpers";
defineLordIconElement(loadAnimation);

const PropertiesForm = () => {
    let history = useHistory();
    const {id} = useParams();
    const property_id = (new URLSearchParams(window.location.search)).get("property_id")

    document.title ="Biens | "+env('NAME');
    const {axios} = useAxios();
    const [properties, setProperties] = useState([]);

    const defaultError = {
        // Generale
        code_immo: [],
        reference: [],
        description: [],
        serial_number: [],
        origin: [],
        date_of_purchase: [],
        date_of_use: [],
        etat_id: [],
        mark_id: [],
        standard_id: [],
        attachment_id: [],
        images: [],

        // Affectation
        assignment_date: [],
        service_id: [],
        staff_id: [],
        local_id: [],

        // Financières
        supplier_id: [],
        invoice_number: [],
        real_estate_number: [],
        purchase_amount: [],
        contract_number: [],
        period_of_rent: [],
        number_of_period: [],
        contract_begin_date: [],
        contract_end_date: [],
        amount_of_rent: [],
        contract_status: [],
        contract_files: [],
        acquisition_amount: [],
        rate_of_funding: [],
        donor_name: [],
        residual_value: []
    };

    const [submitError, setSubmitError] = useState(defaultError);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    // Generale
    const [codeImmo, setCodeImmo] = useState("");
    const [state, setState] = useState(null);
    const [standard, setStandard] = useState(null);
    const [mark, setMark] = useState(null);
    const [reference, setReference] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [origin, setOrigin] = useState(null);
    const [dateOfPurchase, setDateOfPurchase] = useState("");
    const [dateOfUse, setDateOfUse] = useState("");
    const [attachement, setAttachement] = useState(null);
    const [images, setImages] = useState("");
    const [description, setDescription] = useState("");

    // Affectation
    const [assignmentDate, setAssignmentDate] = useState("");
    const [service, setService] = useState(null);
    const [staff, setStaff] = useState(null);
    const [local, setLocal] = useState(null);

    // Financières
    const [supplier, setSupplier] = useState(null);
    const [subsidie, setSubsidie] = useState(null);
    const [invoiceNumber, setInvoiceNumber] = useState(null);
    const [realEstateNumber, setRealEstateNumber] = useState(null);
    const [purchaseAmount, setPurchaseAmount] = useState(null);
    const [contractNumber, setContractNumber] = useState(null);
    const [periodOfRent, setPeriodOfRent] = useState(null);
    const [numberOfPeriod, setNumberOfPeriod] = useState(null);
    const [contractBeginDate, setContractBeginDate] = useState(null);
    const [contractEndDate, setContractEndDate] = useState(null);
    const [amountOfRent, setAmountOfRent] = useState(null);
    const [contractStatus, setContractStatus] = useState(null);
    const [contractFiles, setContractFiles] = useState(null);
    const [acquisitionAmount, setAcquisitionAmount] = useState(null);
    const [rateOfFunding, setRateOfFunding] = useState(null);
    const [donorName, setDonorName] = useState(null);
    const [residualValue, setResidualValue] = useState(0);
    const [typeAmortization, setTypeAmortization] = useState(null);

    const {list, loading} = useFetchDataList(id ? `/api/properties/${id}/edit` : `/api/properties/create${property_id?'?property-not-found='+property_id:''}`, (data) => {
        const formatList = (list, key = "name") => {
            return list.map(item => {
                return {
                    value: item.id,
                    label: item[key]
                }
            });
        };

        return {
            etats: formatList(data.etats),
            locals: formatList(data.locals, "full_name"),
            marks: formatList(data.marks),
            standards: formatList(data.standards),
            services: formatList(data.services),
            suppliers: formatList(data.suppliers),
            subsidies: formatList(data.subsidies),
            property: data.property,
            propertyNotFound: data.propertyNotFound,
            properties: data.properties.map(item => {
                return {
                    value: item.id,
                    label: `${item.reference} / ${item.code_immo}`,
                    standard_id: item.standard_id
                }
            }),
            staffs: data.staff.map(item => {
                return {
                    value: item.id,
                    label: `${item.first_name} ${item.last_name}`,
                }
            }),
            rentFrequency: data.rentFrequency.map(item => {
                return {
                    value: item,
                    label: item,
                }
            }),
            type_amortization: data.type_amortization.map(item => {
                return {
                    value: item,
                    label: item
                }
            }),
            origins: data.propertyOrigins.map(item => ({
                value: item,
                label: item
            }))
        };
    });

    useEffect(() => {
        if (property_id && list && list.propertyNotFound) {
            setStandard(list.propertyNotFound.standard ? {
                value: list.propertyNotFound.standard.id,
                label: list.propertyNotFound.standard.name
            } : null);

            setState(list.propertyNotFound.etat ? {
                value: list.propertyNotFound.etat.id,
                label: list.propertyNotFound.etat.name
            } : null);

            setSerialNumber(list.propertyNotFound.serial_number);

            let currentLocal = list.propertyNotFound?.local?.id;
            if (currentLocal) {
                const result = list.locals.filter(item => item.value === currentLocal);
                currentLocal = result.length ? result[0] : null;
            }
            let currentStaff = list.property?.staff?.id;
            if (currentStaff) {
                const result = list.staffs.filter(item => item.value === currentStaff);
                currentStaff = result.length ? result[0] : null;
            }

            setStaff(currentStaff);
            setLocal(currentLocal);
        }

            if (id && list && list.property) {
            // Générale
            setCodeImmo(list.property.code_immo);
            setState(list.property.etat ? {
                value: list.property.etat.id,
                label: list.property.etat.name
            } : null);
            setStandard(list.property.standard ? {
                value: list.property.standard.id,
                label: list.property.standard.name
            } : null);

            if (list.property.standard) {
                setProperties(list.properties.filter(item => item.standard_id === list.property.standard.id));
            }
            setMark(list.property.mark ? {
                value: list.property.mark.id,
                label: list.property.mark.name
            } : null);
            setReference(list.property.reference);
            setSerialNumber(list.property.serial_number);
            const correspondence =  {};
            list?.origins.forEach(item => {
                correspondence[item.value] = item.label;
            });
            setOrigin((list?.property?.origin !== null && list.property.origin in correspondence) ? {
                value: list.property.origin,
                label: correspondence[list.property.origin]
            } : null);
            setDateOfPurchase(formatDatInput(list.property.date_of_purchase));
            setDateOfUse(formatDatInput(list.property.date_of_use));
            setAttachement(list.property.attachment ? {
                value: list.property.attachment.id,
                label: `${list.property.attachment.reference} / ${list.property.attachment.code_immo}`,
                standard_id: list.property.attachment.standard_id
            } : null);
            setDescription(list.property.description);

            // Affectation
            setAssignmentDate(list.property?.assignment_active ? formatDatInput(list.property?.assignment_active?.assignment_date) : "");
            let currentStaff = list.property?.assignment_active?.staff_id;
            if (currentStaff) {
                const result = list.staffs.filter(item => item.value === currentStaff);
                currentStaff = result.length ? result[0] : null;
            }
            let currentLocal = list.property?.assignment_active?.local_id;
            if (currentLocal) {
                const result = list.locals.filter(item => item.value === currentLocal);
                currentLocal = result.length ? result[0] : null;
            }
            let currentService = list.property?.assignment_active?.service_id;
            if (currentService) {
                const result = list.services.filter(item => item.value === currentService);
                currentService = result.length ? result[0] : null;
            }
            setStaff(currentStaff);
            setLocal(currentLocal);
            setService(currentService);

            // Financières
            setSupplier(list?.property.amortization_property.supplier_id ? {
                value: list.property.amortization_property.supplier_id,
                label: list.property.amortization_property.supplier.name,
            } : null);

            const amortizationProperty = list?.property?.amortization_property;
            setInvoiceNumber(amortizationProperty?.invoice_number);
            setRealEstateNumber(amortizationProperty?.real_estate_number);
            setPurchaseAmount(amortizationProperty?.purchase_amount);
            setContractNumber(amortizationProperty?.contract_number);
            if (amortizationProperty?.type_amortization) {
                setTypeAmortization({
                    value: amortizationProperty?.type_amortization,
                    label: amortizationProperty?.type_amortization,
                });
            }
            if (amortizationProperty?.period_of_rent) {
                setPeriodOfRent({
                    value: amortizationProperty?.period_of_rent,
                    label: amortizationProperty?.period_of_rent,
                });
            }
            setResidualValue(amortizationProperty?.residual_value);
            setContractBeginDate(formatDatInput(amortizationProperty?.contract_begin_date));
            setContractEndDate(formatDatInput(amortizationProperty?.contract_end_date));
            setAmountOfRent(amortizationProperty?.amount_of_rent);
            setContractStatus(amortizationProperty?.contract_status);
            setAcquisitionAmount(amortizationProperty?.acquisition_amount);
            setRateOfFunding(amortizationProperty?.rate_of_funding);
            setDonorName(amortizationProperty?.donor_name);
            setNumberOfPeriod(amortizationProperty?.number_of_period);
        }
    }, [id, list]);

    const handleStandardChange = value => {
        setAttachement(null);
        setProperties(list.properties.filter(item => item.standard_id === value.value));
        setStandard(value);
    };

    const [activeVerticalTab, setactiveVerticalTab] = useState(7);
    const [passedverticalSteps, setPassedverticalSteps] = useState([1]);

    function toggleVerticalTab(tab) {
        if (activeVerticalTab !== tab) {
            const modifiedSteps = [...passedverticalSteps, tab];

            if (tab >= 7 && tab <= 11) {
                setactiveVerticalTab(tab);
                setPassedverticalSteps(modifiedSteps);
            }
        }
    }

    const handleFileChange = (e, key) => {
        if (key === "images") {
            setImages(Object.values(e.target.files));
        }

        if (key === "contractFiles") {
            setContractFiles(Object.values(e.target.files));
        }
    }

    const StepTitle = ({children}) => (
        <span style={{fontSize: "0.7rem"}}>{children}</span>
    );

    const formatFormData = (newData) => {
        const formData = new FormData();
        formData.append("_method", id ? "put" : "post");
        for (const key in newData) {
            if (["images", "contract_files"].includes(key)) {
                for (let i = 0; i < (newData[key]).length; i++)
                    formData.append(`${key}[]`, (newData[key])[i]);
            } else {
                formData.set(key, newData[key]);
            }
        }
        return formData;
    };

    const resetForm = () => {
        setCodeImmo("");
        setReference("");
        setDescription("");
        setSerialNumber("");
        setOrigin(null);
        setDateOfPurchase("");
        setDateOfUse("");
        setState(null);
        setMark(null);
        setStandard(null);
        setAttachement(null);
        setImages("");

        setAssignmentDate("");
        setService(null);
        setStaff(null);
        setLocal(null);

        setSubsidie(null);
        setSupplier(null);
        setInvoiceNumber("");
        setRealEstateNumber("");
        setPurchaseAmount("");
        setContractNumber("");
        setPeriodOfRent("");
        setNumberOfPeriod("");
        setContractBeginDate("");
        setContractEndDate("");
        setAmountOfRent("");
        setContractStatus("");
        setContractFiles("");
        setAcquisitionAmount("");
        setRateOfFunding("");
        setDonorName("");
        setTypeAmortization(null);
    };

    const submit = (e) => {
        setSubmitting(true);
        let sendData = {
            // Generale
            code_immo: codeImmo,
            reference: reference,
            description: description,
            serial_number: serialNumber,
            origin: origin ? origin.value : origin,
            date_of_purchase: dateOfPurchase,
            date_of_use: dateOfUse,
            etat_id: state ? state.value : state,
            mark_id: mark ? mark.value : mark,
            standard_id: standard ? standard.value : standard,
            attachment_id: attachement ? attachement.value : attachement,
            images: images,

            // Affectation
            assignment_date: assignmentDate,
            service_id: service ? service.value : service,
            staff_id: staff ? staff.value : staff,
            local_id: local ? local.value : local,

            // Financières
            subsidy_id: subsidie ? subsidie.value : subsidie,
            supplier_id: supplier ? supplier.value : supplier,
            invoice_number: invoiceNumber,
            real_estate_number: realEstateNumber,
            purchase_amount: purchaseAmount,
            contract_number: contractNumber,
            period_of_rent: periodOfRent ? periodOfRent.value : periodOfRent,
            number_of_period: numberOfPeriod,
            contract_begin_date: contractBeginDate,
            contract_end_date: contractEndDate,
            amount_of_rent: amountOfRent,
            contract_status: contractStatus,
            contract_files: contractFiles,
            acquisition_amount: acquisitionAmount,
            rate_of_funding: rateOfFunding,
            donor_name: donorName,
            residual_value: residualValue,
            type_amortization: typeAmortization ? typeAmortization.value : typeAmortization
        };

        if (property_id){
            sendData.property_not_found_id = property_id;
        }

        sendData = removeNullValue(sendData);

        axios.post(id ? `/api/properties/${id}` : "/api/properties", formatFormData(sendData))
            .then(response => {
                toggleVerticalTab(10);
                toast(id ? "Succès de la modification" : "Succès de l'enregistrement", {type: 'success'});
                if (!id) {
                    resetForm();
                }
                setSubmitError(defaultError);
            })
            .catch(error => {
                toast(error.response.data.message, {type: 'error'});
                if (error.response.status === 422) {
                    toggleVerticalTab(7);
                    scrollTopPage();
                    setSubmitError({...defaultError, ...error.response.data.errors});
                }
            })
            .finally(() => setSubmitting(false))
        ;
    };

    return (
        <PageContent title={"Biens"} pageTitle={!id ? "Nouveau bien" : "Modification du bien"}>
            <Row>
                <Col xl={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">{id ? "Modification du bien" : "Nouveau bien"}</h4>
                        </CardHeader>

                        <CardBody className="form-steps">
                            <form className="vertical-navs-step">
                                <Row className="gy-5">
                                    <Col lg={3}>
                                        <Nav className="flex-column custom-nav nav-pills">
                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={
                                                        (classnames({
                                                            active: activeVerticalTab === 7,
                                                            done: (activeVerticalTab <= 11 && activeVerticalTab > 7)
                                                        }))
                                                    }
                                                    onClick={() => {
                                                        toggleVerticalTab(7);
                                                    }}
                                                >
                                                    <span className="step-title me-2"><i className="ri-close-circle-fill step-icon me-2"></i>Etape 1 </span>
                                                    <StepTitle></StepTitle>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={
                                                        (classnames({
                                                            active: activeVerticalTab === 8,
                                                            done: (activeVerticalTab <= 11 && activeVerticalTab >= 8)
                                                        }))
                                                    }
                                                    onClick={() => {
                                                        toggleVerticalTab(8);
                                                    }}
                                                >
                                                    <span className="step-title me-2"><i className="ri-close-circle-fill step-icon me-2"></i>Etape 2</span>
                                                    <StepTitle></StepTitle>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    href="#"
                                                    className={classnames({
                                                        active: activeVerticalTab === 9,
                                                        done: (activeVerticalTab <= 11 && activeVerticalTab >= 9)
                                                    })}
                                                    onClick={() => {
                                                        toggleVerticalTab(9);
                                                    }}
                                                >
                                                    <span className="step-title me-2"><i className="ri-close-circle-fill step-icon me-2"></i>Etape 3</span>
                                                    <StepTitle></StepTitle>
                                                </NavLink>
                                            </NavItem>

                                            <NavItem>
                                                <NavLink
                                                    disabled={true}
                                                    href="#"
                                                    className={classnames({
                                                        active: activeVerticalTab === 10,
                                                        done: (activeVerticalTab <= 11 && activeVerticalTab >= 10)
                                                    })}
                                                    onClick={() => {
                                                        toggleVerticalTab(10);
                                                    }}
                                                >
                                                    <span className="step-title me-2"><i className="ri-close-circle-fill step-icon me-2"></i>Fin</span>
                                                    <StepTitle></StepTitle>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </Col>

                                    <Col lg={9}>
                                        <div className="px-lg-4">
                                            <TabContent activeTab={activeVerticalTab}>
                                                <TabPane tabId={7}>
                                                    <div>
                                                        <h5>INFORMATIONS GÉNÉRALES</h5>
                                                        <p className="text-muted">
                                                            Remplissez toutes les informations ci-dessous
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <Row className="g-3">
                                                            <Col sm={4}>
                                                                <Label htmlFor="code_immo" className="form-label">Code immobilier </Label>
                                                                <Input
                                                                    id="code_immo"
                                                                    name="code_immo"
                                                                    className="form-control"
                                                                    placeholder="Veuillez entrer le code immobilier"
                                                                    type="text"
                                                                    validate={{
                                                                        required: { value: submitError.code_immo.length > 0 },
                                                                    }}
                                                                    onChange={e => {setCodeImmo(e.target.value)}}
                                                                    value={codeImmo}
                                                                    invalid={submitError.code_immo.length > 0}
                                                                />
                                                                {submitError.code_immo.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.code_immo[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={4}>
                                                                <Label htmlFor="reference" className="form-label">Code ERP (compta) <InputRequire/> </Label>
                                                                <Input
                                                                    id="reference"
                                                                    name="reference"
                                                                    className="form-control"
                                                                    placeholder="Veuillez entrer la référence"
                                                                    type="text"
                                                                    validate={{
                                                                        required: { value: submitError.reference.length > 0 },
                                                                    }}
                                                                    onChange={e => {setReference(e.target.value)}}
                                                                    value={reference}
                                                                    invalid={submitError.reference.length > 0}
                                                                />
                                                                {submitError.reference.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.reference[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={4}>
                                                                <Label htmlFor="serial_number" className="form-label">Numéro de série </Label>
                                                                <Input
                                                                    id="serial_number"
                                                                    name="serial_number"
                                                                    className="form-control"
                                                                    placeholder="Veuillez entrer le numéro de série"
                                                                    type="text"
                                                                    validate={{
                                                                        required: { value: submitError.serial_number.length > 0 },
                                                                    }}
                                                                    onChange={e => {setSerialNumber(e.target.value)}}
                                                                    value={serialNumber}
                                                                    invalid={submitError.serial_number.length > 0}
                                                                />
                                                                {submitError.serial_number.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.serial_number[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={6}>
                                                                <Label htmlFor="origin" className="form-label">Origine du bien <InputRequire/></Label>
                                                                <ReactSelect
                                                                    id="origin"
                                                                    name="origin"
                                                                    placeholder="Veuillez sélectionner l'origine du bien"
                                                                    value={origin}
                                                                    onChange={value => {setOrigin(value)}}
                                                                    options={(list?.origins || [])}
                                                                    isLoading={loading}
                                                                    error={submitError.origin}
                                                                />
                                                            </Col>

                                                            <Col sm={6}>
                                                                <Label htmlFor="date" className="form-label">Date d'acquisition du bien <InputRequire/></Label>
                                                                <Input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="date"
                                                                    placeholder="Veuillez entrer la date d'achat du bien"
                                                                    validate={{
                                                                        required: { value: submitError.date_of_purchase.length > 0 },
                                                                    }}
                                                                    value={dateOfPurchase}
                                                                    onChange={e => setDateOfPurchase(e.target.value)}
                                                                    invalid={submitError.date_of_purchase.length > 0}
                                                                />
                                                                {submitError.date_of_purchase.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.date_of_purchase[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={6}>
                                                                <Label htmlFor="date" className="form-label">Date de mise en service </Label>
                                                                <Input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="date"
                                                                    placeholder="Veuillez entrer la date date de mise en service du bien"
                                                                    value={dateOfUse}
                                                                    onChange={e => setDateOfUse(e.target.value)}
                                                                    invalid={submitError.date_of_use.length > 0}
                                                                />
                                                                {submitError.date_of_use.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.date_of_use[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={6}>
                                                                <Label htmlFor="etat_id" className="form-label">Etat </Label>
                                                                <ReactSelect
                                                                    id="etat_id"
                                                                    name="etat_id"
                                                                    placeholder="Veuillez sélectionner l'état du bien"
                                                                    value={state}
                                                                    onChange={value => setState(value)}
                                                                    options={list['etats']}
                                                                    isLoading={loading}
                                                                    error={submitError.etat_id}
                                                                />
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Label htmlFor="mark_id" className="form-label">Marque </Label>
                                                                <ReactSelect
                                                                    id="mark_id"
                                                                    name="mark_id"
                                                                    placeholder="Veuillez sélectionner la marque"
                                                                    value={mark}
                                                                    onChange={value => setMark(value)}
                                                                    options={list['marks']}
                                                                    isLoading={loading}
                                                                    error={submitError.mark_id}
                                                                />
                                                            </Col>

                                                            <Col xs={6}>
                                                                <Label htmlFor="standard_id" className="form-label">Sous standard <InputRequire/></Label>
                                                                <ReactSelect
                                                                    id="standard_id"
                                                                    name="standard_id"
                                                                    placeholder="Veuillez sélectionner le standard"
                                                                    value={standard}
                                                                    onChange={handleStandardChange}
                                                                    options={list['standards']}
                                                                    isLoading={loading}
                                                                    error={submitError.standard_id}
                                                                />
                                                            </Col>

                                                            <Col sm={6}>
                                                                <Label htmlFor="file" className="form-label">Image du bien</Label>
                                                                <Input
                                                                    type="file"
                                                                    className="form-control"
                                                                    id="file"
                                                                    validate={{
                                                                        required: { value: submitError.images.length > 0 },
                                                                    }}
                                                                    multiple={true}
                                                                    placeholder="Veuillez entrer l'image du bien"
                                                                    onChange={e => handleFileChange(e, "images")}
                                                                    invalid={submitError.images.length > 0}
                                                                />
                                                                {submitError.images.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.images[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={6}>
                                                                <Label htmlFor="ra" className="form-label">Bien principal</Label>
                                                                <ReactSelect
                                                                    id="ra"
                                                                    name="ra"
                                                                    placeholder="Veuillez sélectionner le bien auquel il est rattaché"
                                                                    value={attachement}
                                                                    onChange={value => setAttachement(value)}
                                                                    options={properties}
                                                                    isLoading={loading}
                                                                    error={submitError.attachment_id}
                                                                />
                                                            </Col>

                                                            <Col xs={12}>
                                                                <Label htmlFor="description" className="form-label">Description <InputRequire/></Label>
                                                                <Input
                                                                    id="description"
                                                                    name="description"
                                                                    className="form-control"
                                                                    placeholder="Veuillez entrer la description"
                                                                    type="textarea"
                                                                    validate={{
                                                                        required: { value: submitError.description.length > 0 },
                                                                    }}
                                                                    onChange={e => setDescription(e.target.value)}
                                                                    value={description}
                                                                    invalid={submitError.description.length > 0}
                                                                />
                                                                {submitError.description.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.description[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-label right ms-auto nexttab nexttab"
                                                            onClick={() => {
                                                                toggleVerticalTab(activeVerticalTab + 1);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                            Suivant
                                                        </button>
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId={8}>
                                                    <div>
                                                        <h5>INFORMATIONS SUR L'AFFECTATION DU BIEN</h5>
                                                        <p className="text-muted">
                                                            Remplissez toutes les informations ci-dessous
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <Row className="g-3">
                                                            <Col sm={12}>
                                                                <Label htmlFor="date_affectation" className="form-label">Date d'affectation</Label>
                                                                <Input
                                                                    type="date"
                                                                    className="form-control"
                                                                    id="date_affectation"
                                                                    placeholder="Veuillez entrer la date d'affectation du bien"
                                                                    validate={{
                                                                        required: { value: submitError.assignment_date.length > 0 },
                                                                    }}
                                                                    value={assignmentDate}
                                                                    onChange={e => setAssignmentDate(e.target.value)}
                                                                    invalid={submitError.assignment_date.length > 0}
                                                                />
                                                                {submitError.assignment_date.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.assignment_date[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col md={6}>
                                                                <Label htmlFor="service" className="form-label"> Direction/Service </Label>
                                                                <ReactSelect
                                                                    id="service"
                                                                    name="service"
                                                                    placeholder={`Veuillez selectionner le service ou la direction`}
                                                                    value={service}
                                                                    onChange={value => setService(value)}
                                                                    options={  list['services'] || []}
                                                                    isLoading={loading}
                                                                    error={[submitError.service_id]}
                                                                />
                                                            </Col>

                                                            <Col md={6}>
                                                                <Label htmlFor="staff_id" className="form-label">Agent</Label>
                                                                <ReactSelect
                                                                    id="staff_id"
                                                                    name="staff_id"
                                                                    placeholder="Veuillez sélectionner celui à qui on affecte le bien"
                                                                    value={staff}
                                                                    onChange={value => setStaff(value)}
                                                                    options={list['staffs']}
                                                                    isLoading={loading}
                                                                    error={submitError.staff_id}
                                                                />
                                                            </Col>

                                                            <Col md={6}>
                                                                <Label htmlFor="local_id" className="form-label">Local <InputRequire/></Label>
                                                                <ReactSelect
                                                                    id="local_id"
                                                                    name="local_id"
                                                                    placeholder="Veuillez sélectionner le local"
                                                                    value={local}
                                                                    onChange={value => setLocal(value)}
                                                                    options={list.locals}
                                                                    isLoading={loading}
                                                                    error={submitError.local_id}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-label previestab"
                                                            onClick={() => {
                                                                toggleVerticalTab(activeVerticalTab - 1);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                            Précédent
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-label right ms-auto nexttab"
                                                            onClick={() => {
                                                                toggleVerticalTab(activeVerticalTab + 1);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                            Suivant
                                                        </button>
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId={9}>
                                                    <div>
                                                        <h5>INFORMATIONS FINANCIÈRES</h5>
                                                        <p className="text-muted">
                                                            Remplissez toutes les informations ci-dessous
                                                        </p>
                                                    </div>

                                                    <div>
                                                        <Row className="g-3">
                                                            {(origin !== null && (origin.value === "Fonds propres" || origin.value === "Production / Livraison à soi-même")) && (
                                                                <>
                                                                    <Col sm={origin && origin.value === "Fonds propres" ? 6 : 12}>
                                                                        <Label htmlFor="contract_status" className="form-label">Valeur d'entrée du bien <InputRequire/></Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="acquisition_amount"
                                                                            placeholder="Veuillez renseigner la valeur d'entrée du bien"
                                                                            value={acquisitionAmount}
                                                                            validate={{
                                                                                required: { value: submitError.acquisition_amount.length > 0 },
                                                                            }}
                                                                            onChange={e => setAcquisitionAmount(e.target.value)}
                                                                            invalid={submitError.acquisition_amount.length > 0}
                                                                        />
                                                                        {submitError.acquisition_amount.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.acquisition_amount[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <Label htmlFor="type_amortization" className="form-label">Type d'amortissement</Label>
                                                                        <ReactSelect
                                                                            id="type_amortization"
                                                                            name="type_amortization"
                                                                            placeholder={`Veuillez selectionner le type d'amortissement`}
                                                                            value={typeAmortization}
                                                                            onChange={value => setTypeAmortization(value)}
                                                                            options={list['type_amortization']}
                                                                            isLoading={loading}
                                                                            error={[submitError.type_amortization]}
                                                                        />
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <Label htmlFor="supplier_id" className="form-label">Fournisseur</Label>
                                                                        <ReactSelect
                                                                            id="supplier_id"
                                                                            name="supplier_id"
                                                                            placeholder={`Veuillez selectionner le fournisseur`}
                                                                            value={supplier}
                                                                            onChange={value => setSupplier(value)}
                                                                            options={list['suppliers']}
                                                                            isLoading={loading}
                                                                            error={[submitError.supplier_id]}
                                                                        />
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="invoice_number" className="form-label">Numéro de facture </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="invoice_number"
                                                                            placeholder="Veuillez entrer le numéro de facture"
                                                                            validate={{
                                                                                //required: { value: submitError.invoice_number.length > 0 },
                                                                            }}
                                                                            value={invoiceNumber}
                                                                            onChange={e => setInvoiceNumber(e.target.value)}
                                                                            invalid={submitError.invoice_number.length > 0}
                                                                        />
                                                                        {submitError.invoice_number.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.invoice_number[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    {/*<Col sm={6}>
                                                                        <Label htmlFor="purchase_amount" className="form-label">Montant d'achat <InputRequire/></Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="purchase_amount"
                                                                            placeholder="Veuillez entrer le montant d'achat"
                                                                            value={purchaseAmount}
                                                                            validate={{
                                                                                required: { value: submitError.purchase_amount.length > 0 },
                                                                            }}
                                                                            onChange={e => setPurchaseAmount(e.target.value)}
                                                                            invalid={submitError.purchase_amount.length > 0}
                                                                        />
                                                                        {submitError.purchase_amount.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.purchase_amount[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>*/}

                                                                </>
                                                            )}

                                                            {(origin !== null && (origin.value === "Subvention")) && (
                                                                <>
                                                                    <Col md={6}>
                                                                        <Label htmlFor="subsidy_id" className="form-label">Type de financement</Label>
                                                                        <ReactSelect
                                                                            id="subsidy_id"
                                                                            name="subsidy_id"
                                                                            placeholder={`Veuillez selectionner le type de financement`}
                                                                            value={subsidie}
                                                                            onChange={value => setSubsidie(value)}
                                                                            options={list['subsidies']}
                                                                            isLoading={loading}
                                                                            error={[submitError.subsidy_id]}
                                                                        />
                                                                    </Col>
                                                                    </>
                                                            )}

                                                            {(origin !== null && origin.value === "Location") && (
                                                                <>
                                                                    <Col sm={6}>
                                                                        <Label htmlFor="contract_number" className="form-label">Numéro de contrat <InputRequire/></Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="contract_number"
                                                                            placeholder="Veuillez entrer le numéro de contrat"
                                                                            value={contractNumber}
                                                                            validate={{
                                                                                required: { value: submitError.contract_number.length > 0 },
                                                                            }}
                                                                            onChange={e => setContractNumber(e.target.value)}
                                                                            invalid={submitError.contract_number.length > 0}
                                                                        />
                                                                        {submitError.contract_number.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.contract_number[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="period_of_rent" className="form-label">Périodicité <InputRequire/></Label>
                                                                        <ReactSelect
                                                                            id="supplier_id"
                                                                            name="supplier_id"
                                                                            placeholder={`Veuillez selectionner le fournisseur`}
                                                                            value={periodOfRent}
                                                                            onChange={value => setPeriodOfRent(value)}
                                                                            options={list['rentFrequency']}
                                                                            isLoading={loading}
                                                                            error={[submitError.period_of_rent]}
                                                                        />
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="number_of_period" className="form-label">Nombre de période <InputRequire/></Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="number_of_period"
                                                                            placeholder="Veuillez entrer le nombre de période"
                                                                            value={numberOfPeriod}
                                                                            validate={{
                                                                                required: { value: submitError.number_of_period.length > 0 },
                                                                            }}
                                                                            onChange={e => setNumberOfPeriod(e.target.value)}
                                                                            invalid={submitError.number_of_period.length > 0}
                                                                        />
                                                                        {submitError.number_of_period.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.number_of_period[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="contract_begin_date" className="form-label">Date de début du contrat <InputRequire/></Label>
                                                                        <Input
                                                                            type="date"
                                                                            className="form-control"
                                                                            id="contract_begin_date"
                                                                            placeholder="Veuillez entrer la date de début du contrat"
                                                                            value={contractBeginDate}
                                                                            validate={{
                                                                                required: { value: submitError.contract_begin_date.length > 0 },
                                                                            }}
                                                                            onChange={e => setContractBeginDate(e.target.value)}
                                                                            invalid={submitError.contract_begin_date.length > 0}
                                                                        />
                                                                        {submitError.contract_begin_date.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.contract_begin_date[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="contract_begin_date" className="form-label">Date de fin du contrat <InputRequire/></Label>
                                                                        <Input
                                                                            type="date"
                                                                            className="form-control"
                                                                            id="contract_begin_date"
                                                                            placeholder="Veuillez entrer la date de début du contrat"
                                                                            value={contractEndDate}
                                                                            validate={{
                                                                                required: { value: submitError.contract_end_date.length > 0 },
                                                                            }}
                                                                            onChange={e => setContractEndDate(e.target.value)}
                                                                            invalid={submitError.contract_end_date.length > 0}
                                                                        />
                                                                        {submitError.contract_end_date.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.contract_end_date[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="amount_of_rent" className="form-label">Montant du loyer <InputRequire/></Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="amount_of_rent"
                                                                            placeholder="Veuillez entrer le montant du loyer"
                                                                            value={amountOfRent}
                                                                            validate={{
                                                                                required: { value: submitError.amount_of_rent.length > 0 },
                                                                            }}
                                                                            onChange={e => setAmountOfRent(e.target.value)}
                                                                            invalid={submitError.amount_of_rent.length > 0}
                                                                        />
                                                                        {submitError.amount_of_rent.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.amount_of_rent[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col sm={12}>
                                                                        <Label htmlFor="contract_status" className="form-label">Status du contrat <InputRequire/></Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="contract_status"
                                                                            placeholder="Veuillez entrer le status du contrat"
                                                                            value={contractStatus}
                                                                            validate={{
                                                                                required: { value: submitError.contract_status.length > 0 },
                                                                            }}
                                                                            onChange={e => setContractStatus(e.target.value)}
                                                                            invalid={submitError.contract_status.length > 0}
                                                                        />
                                                                        {submitError.contract_status.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.contract_status[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>
                                                                </>
                                                            )}

                                                            {(origin !== null && (origin.value === "Subvention" || origin.value === "Don")) && (
                                                                <>
                                                                    <Col sm={6}>
                                                                        <Label htmlFor="contract_status" className="form-label">Valeur d'entrée du bien <InputRequire/></Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="acquisition_amount"
                                                                            placeholder="Veuillez renseigner la valeur d'entrée du bien"
                                                                            value={acquisitionAmount}
                                                                            validate={{
                                                                                required: { value: submitError.acquisition_amount.length > 0 },
                                                                            }}
                                                                            onChange={e => setAcquisitionAmount(e.target.value)}
                                                                            invalid={submitError.acquisition_amount.length > 0}
                                                                        />
                                                                        {submitError.acquisition_amount.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.acquisition_amount[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col md={6}>
                                                                        <Label htmlFor="type_amortization" className="form-label">Type d'amortissement</Label>
                                                                        <ReactSelect
                                                                            id="type_amortization"
                                                                            name="type_amortization"
                                                                            placeholder={`Veuillez selectionner le type d'amortissement`}
                                                                            value={typeAmortization}
                                                                            onChange={value => setTypeAmortization(value)}
                                                                            options={list['type_amortization']}
                                                                            isLoading={loading}
                                                                            error={[submitError.type_amortization]}
                                                                        />
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="rate_of_funding" className="form-label">Taux de financement </Label>
                                                                        <Input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="rate_of_funding"
                                                                            placeholder="Veuillez entrer le taux de financement"
                                                                            value={rateOfFunding}
                                                                            onChange={e => setRateOfFunding(e.target.value)}
                                                                            invalid={submitError.rate_of_funding.length > 0}
                                                                            validate={{
                                                                                required: { value: submitError.rate_of_funding.length > 0 },
                                                                            }}
                                                                        />
                                                                        {submitError.rate_of_funding.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.rate_of_funding[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>

                                                                    <Col sm={6}>
                                                                        <Label htmlFor="donor_name" className="form-label">Nom du donateur </Label>
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id="donor_name"
                                                                            placeholder="Veuillez entrer le nom du donateur"
                                                                            value={donorName}
                                                                            onChange={e => setDonorName(e.target.value)}
                                                                            validate={{
                                                                                required: { value: submitError.donor_name.length > 0 },
                                                                            }}
                                                                            invalid={submitError.donor_name.length > 0}
                                                                        />
                                                                        {submitError.donor_name.length > 0 && (
                                                                            <FormFeedback type="invalid">
                                                                                {submitError.donor_name[0]}
                                                                            </FormFeedback>
                                                                        ) }
                                                                    </Col>
                                                                </>
                                                            )}
                                                            <Col sm={(origin !== null && origin.value === "Fonds propres") ? 6 : 12}>
                                                                <Label htmlFor="value_residual" className="form-label">Valeur Résiduelle </Label>
                                                                <Input
                                                                    type="number"
                                                                    className="form-control"
                                                                    id="value_residual"
                                                                    placeholder="Veuillez entrer la valeur résiduelle"
                                                                    value={residualValue}
                                                                    validate={{
                                                                        required: { value: submitError.residual_value.length > 0 },
                                                                    }}
                                                                    onChange={e => setResidualValue(e.target.value)}
                                                                    invalid={submitError.residual_value.length > 0}
                                                                />
                                                                {submitError.residual_value.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.residual_value[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>

                                                            <Col sm={12}>
                                                                <Label htmlFor="contract_files" className="form-label">Attacher des fichiers </Label>
                                                                <Input
                                                                    type="file"
                                                                    multiple={true}
                                                                    className="form-control"
                                                                    id="contract_files"
                                                                    placeholder="Veuillez entrer les fichiers du contrat / complémentaires"
                                                                    validate={{
                                                                        required: { value: submitError.contract_files.length > 0 },
                                                                    }}
                                                                    onChange={e => handleFileChange(e, "contractFiles")}
                                                                    invalid={submitError.contract_files.length > 0}
                                                                />
                                                                {submitError.contract_files.length > 0 && (
                                                                    <FormFeedback type="invalid">
                                                                        {submitError.contract_files[0]}
                                                                    </FormFeedback>
                                                                ) }
                                                            </Col>
                                                        </Row>
                                                    </div>

                                                    <div className="d-flex align-items-start gap-3 mt-4">
                                                        <button
                                                            type="button"
                                                            className="btn btn-light btn-label previestab"
                                                            onClick={() => {
                                                                toggleVerticalTab(activeVerticalTab - 1);
                                                            }}
                                                        >
                                                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                            Précédent
                                                        </button>

                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-label right ms-auto nexttab"
                                                            onClick={submit}
                                                            disabled={submitting}
                                                        >
                                                            {submitting && (
                                                                <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                                                            )}
                                                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>{" "}
                                                            {id ? "Modifier" : "Enregistrer"}
                                                        </button>
                                                    </div>
                                                </TabPane>

                                                <TabPane tabId={10}>
                                                    <div className="text-center pt-4 pb-2">
                                                        <div className="mb-4">
                                                            <lord-icon
                                                                src="https://cdn.lordicon.com/lupuorrc.json"
                                                                trigger="loop"
                                                                colors="primary:#0ab39c,secondary:#405189"
                                                                style={{ width: "120px", height: "120px" }}
                                                            ></lord-icon>
                                                        </div>
                                                        <h5>Succès de l'opération !</h5>
                                                        <p className="text-muted">
                                                            Vous venez d'enregistrer un bien.
                                                        </p>
                                                    </div>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                    </Col>
                                </Row>
                            </form>

                            <hr/>

                            <Link to={"/inputs"} className="btn btn-outline-secondary add-btn">
                                {/*<i className="ri-add-line align-bottom"></i>*/} Retour
                            </Link>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
};

export default PropertiesForm;
import React, {useCallback, useEffect, useMemo, useState} from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";

const Standards = () => {
    let history = useHistory();
    document.title ="Standards | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Compte comptable",
                accessor: "displayed_code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Famille",
                accessor: "family.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
           /* {
                Header: "Compte comptable",
                accessor: "accounting_account",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            }*/
        ]
    }, []);
    const [family, setFamily] = useState(null);
    const [parent, setParent] = useState(null);
    const [active, setActive] = useState(false);
    const {list, loading} = useFetchDataList('/api/standards/create', (data) => {
        return {
            families: data['families'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                    code: item.displayed_code,
                }
            })
        };
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter un standard',
                update: 'Modifier un standard'
            },
            formDefaultError: {name: [], code: [], family_id: []},
            initialValues: {name: '', code: '', },
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
            }),
            formatFormData: (values) => {
                const sendData = {
                    ...values,
                    family_id: family ? family.value : '',
                };


                return sendData;
            },
            formatResponse: data => {
                history.replace('/');
                setTimeout(() => {
                    history.replace('/standards');
                }, 0);
                return data;
            },
            onSuccess: () => {
                setFamily(null);
            },
            onFail: () => {},
        };
    }, [active, family, history, parent]);

    const onUpdateEntity = useCallback((editData) => {
        let result = list['families'].filter(item => item.value === editData['family_id']);
        result = result.length ? result[0] : null;
        if (result) {
            setFamily(result);
        } else {
            setFamily(null);
        }

        setActive(editData.sticker_is_requis === null ? false : editData.sticker_is_requis)

    }, [list]);

    const onAddEntity = useCallback(() => {
        setFamily(null);
    }, []);

    return (
        <PageContent title={"Standards"} pageTitle={"Biens"}>
            <DatatablePageProvider
                title={"Standards"}
                endpoint={"/api/standards"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-standard"}
                updatePermission={"edit-standard"}
                deletePermission={"destroy-standard"}
            >
                <Datatable
                    family={family}
                    setFamily={setFamily}
                    families={list["families"]}
                    standards={list["standards"]}
                    parent={parent}
                    setParent={setParent}
                    loading={loading}
                    active={active}
                    setActive={setActive}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Standards;
import {Col, Form, Input, Label, Row} from "reactstrap";

const UpdateAccount = () => {
    return (
        <Form>
            <Row>
                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="firstnameInput" className="form-label">Nom d'utilisateur</Label>
                        <Input
                            type="text"
                            className="form-control"
                            id="firstnameInput"
                            placeholder="Entrez votre nom"
                            defaultValue="Dave"
                        />
                    </div>
                </Col>

                <Col lg={6}>
                    <div className="mb-3">
                        <Label htmlFor="emailInput" className="form-label">Adresse e-mail</Label>
                        <Input
                            type="email"
                            className="form-control"
                            id="emailInput"
                            placeholder="Entrer votre Email"
                            defaultValue="daveadame@DigitImmo.com"
                        />
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-primary">Modifier</button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default UpdateAccount;
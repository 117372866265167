import env from "./helpers/env";

const config = {
    AUTH: {
        DRIVER: localStorage,
        TYPE: 'LARAVEL_PASSPORT',
        REDIRECT_LOGIN: '/dashboard',
        REDIRECT_LOGOUT: '/login',
        API_HOST: env('API_HOST'),
        LARAVEL_PASSPORT: {
            client_id: env('LARAVEL_PASSPORT_CLIENT') ? env('LARAVEL_PASSPORT_CLIENT') : "",
            client_secret: env('LARAVEL_PASSPORT_CLIENT_SECRET') ? env('LARAVEL_PASSPORT_CLIENT_SECRET') : "",
        }
    },
    EDIT_LAYOUT: false
};

export default config;
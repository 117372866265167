import React from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = () => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Dénomination <InputRequire/></Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer la dénimination"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
import React, {useState} from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({position, setPosition, positions, services, locals, local, service, setService, setLocal, loading, setKey}) => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="code" className="form-label">N° Matricule <InputRequire/></Label>
                    <Input
                        id="code"
                        name="code"
                        className="form-control"
                        placeholder="Veuillez entrer le code"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                            !!((validation.touched.code && validation.errors.code) || formError.code.length)
                        }
                    />
                    {(validation.touched.code && validation.errors.code) || formError.code.length ? (
                        <FormFeedback type="invalid">
                            {formError.code.length ? formError.code[0] : validation.errors.code}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="last_name" className="form-label">Nom <InputRequire/></Label>
                    <Input
                        id="last_name"
                        name="last_name"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.last_name || ""}
                        invalid={
                            !!((validation.touched.last_name && validation.errors.last_name) || formError.last_name.length)
                        }
                    />
                    {(validation.touched.last_name && validation.errors.last_name) || formError.last_name.length ? (
                        <FormFeedback type="invalid">
                            {formError.last_name.length ? formError.last_name[0] : validation.errors.last_name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="first_name" className="form-label">Prénom <InputRequire/></Label>
                    <Input
                        id="first_name"
                        name="first_name"
                        className="form-control"
                        placeholder="Veuillez entrer le prénom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.first_name || ""}
                        invalid={
                            !!((validation.touched.first_name && validation.errors.first_name) || formError.first_name.length)
                        }
                    />
                    {(validation.touched.first_name && validation.errors.first_name) || formError.first_name.length ? (
                        <FormFeedback type="invalid">
                            {formError.first_name.length ? formError.first_name[0] : validation.errors.first_name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="telephone" className="form-label">Téléphone <InputRequire/></Label>
                    <Input
                        id="telephone"
                        name="telephone"
                        className="form-control"
                        placeholder="Veuillez entrer le téléphone"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.telephone || ""}
                        invalid={
                            !!((validation.touched.telephone && validation.errors.telephone) || formError.telephone.length)
                        }
                    />
                    {(validation.touched.telephone && validation.errors.telephone) || formError.telephone.length ? (
                        <FormFeedback type="invalid">
                            {formError.telephone.length ? formError.telephone[0] : validation.errors.telephone}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="email" className="form-label">Email <InputRequire/></Label>
                    <Input
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Veuillez entrer l'email"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                            !!((validation.touched.email && validation.errors.email) || formError.email.length)
                        }
                    />
                    {(validation.touched.email && validation.errors.email) || formError.email.length ? (
                        <FormFeedback type="invalid">
                            {formError.email.length ? formError.email[0] : validation.errors.email}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={4}>
                <div id="modal-id">
                    <Label htmlFor="address" className="form-label">Adresse</Label>
                    <Input
                        id="address"
                        name="address"
                        className="form-control"
                        placeholder="Veuillez entrer l'adresse"
                        type="text"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.address || ""}
                        invalid={
                            !!(formError.address.length)
                        }
                    />
                    { formError.address.length ? (
                        <FormFeedback type="invalid">
                            {formError.address.length ? formError.address[0] : validation.errors.address}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="civility" className="form-label">Civilité </Label>
                    <Input
                        id="civility"
                        name="civility"
                        className="form-control"
                        placeholder="Veuillez choisir la civilité"
                        type="select"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.civility || ""}
                        invalid={
                            !!((validation.touched.civility && validation.errors.civility) || formError.civility.length)
                        }
                    >

                        <option value="">Veuillez choisir la civilité</option>
                        <option value="M.">M.</option>
                        <option value="Mme.">Mme</option>
                        <option value="Mlle.">Mlle</option>
                    </Input>
                    {(validation.touched.civility && validation.errors.civility) || formError.civility.length ? (
                        <FormFeedback type="invalid">
                            {formError.civility.length ? formError.civility[0] : validation.errors.civility}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="position_id" className="form-label">Fonction  </Label>
                    <ReactSelect
                        id="position_id"
                        name="position_id"
                        placeholder="Veuillez sélectionner la fonction"
                        value={position}
                        onChange={value => setPosition(value)}
                        options={positions}
                        isLoading={loading}
                        error={formError['position_id']}
                    />
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="service_id" className="form-label">Direction/Service</Label>
                    <ReactSelect
                        id="service_id"
                        name="service_id"
                        placeholder="Veuillez sélectionner le service"
                        value={service}
                        onChange={value => setService(value)}
                        options={services}
                        isLoading={loading}
                        error={formError['service_id']}
                    />
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="local_id" className="form-label">Bureau</Label>
                    <ReactSelect
                        id="local_id"
                        name="local_id"
                        placeholder="Veuillez sélectionner le bureau"
                        value={local}
                        onChange={value => setLocal(value)}
                        options={locals}
                        isLoading={loading}
                        error={formError['local_id']}
                    />
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
import {useState} from "react";
import classnames from "classnames";
import { Card, CardBody, CardHeader, Col, Container, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import {useAuth} from "../../hooks";
import env from "../../helpers/env";

//import images
import progileBg from '../../assets/images/profile-bg.jpg';
import avatar1 from '../../assets/images/users/avatar-1.jpg';
import UpdatePassword from "./UpdatePassword";
import UpdateStaff from "./UpdateStaff";
import UpdateAccount from "./UpdateAccount";

const Profile = () => {
    document.title = "Paramètres | "+env('NAME');

    const {user} = useAuth();
    const [activeTab, setActiveTab] = useState("1");

    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    return (
        <div className="page-content">
            <Container fluid>
                <div className="position-relative mx-n4 mt-n4">
                    <div className="profile-wid-bg profile-setting-img">
                        <img src={progileBg} className="profile-wid-img" alt="" />
                        <div className="overlay-content">
                            <div className="text-end p-3">
                                <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                                    <Input id="profile-foreground-img-file-input" type="file"
                                           className="profile-foreground-img-file-input" />
                                    <Label htmlFor="profile-foreground-img-file-input"
                                           className="profile-photo-edit btn btn-light">
                                        <i className="ri-image-edit-line align-bottom me-1"></i> Change Cover
                                    </Label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Row>
                    <Col xxl={3}>
                        <Card className="mt-n5">
                            <CardBody className="p-4">
                                <div className="text-center">
                                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                        <img
                                            src={avatar1}
                                            className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                            alt="user-profile"
                                        />
                                        <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                            <Input
                                                id="profile-img-file-input"
                                                type="file"
                                                className="profile-img-file-input"
                                            />
                                            <Label htmlFor="profile-img-file-input" className="profile-photo-edit avatar-xs">
                                                <span className="avatar-title rounded-circle bg-light text-body">
                                                    <i className="ri-camera-fill"></i>
                                                </span>
                                            </Label>
                                        </div>
                                    </div>
                                    <h5 className="fs-16 mb-1">{user?.first_name} {user?.last_name}</h5>
                                    <p className="text-muted mb-0">Entreprise / {user?.company?.company_name}</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xxl={9}>
                        <Card className="mt-xxl-n5">
                            <CardHeader>
                                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => tabChange("1")} type="button">
                                            <i className="fas fa-home"></i>
                                            Détail du compte
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink to="#" className={classnames({ active: activeTab === "2" })} onClick={() => tabChange("2")} type="button">
                                            <i className="far fa-user"></i>
                                            Changer le mot de passe
                                        </NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink className={classnames({ active: activeTab === "3" })} onClick={() => tabChange("3")} type="button">
                                            <i className="fas fa-home"></i>
                                            Détail du personnel
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </CardHeader>

                            <CardBody className="p-4">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                        <UpdateAccount/>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <UpdatePassword/>
                                    </TabPane>

                                    <TabPane tabId="3">
                                        <UpdateStaff/>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Profile;
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
    Card,
    CardBody,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
    Spinner,
    UncontrolledDropdown
} from 'reactstrap';

import CountUp from "react-countup";

import {toast} from "react-toastify";
import {useAxios} from "../../hooks";

const TileBoxs = ({options = []}) => {
    const [property, setProperty] = useState(null);
    const [loadingData, setLoadingData] = useState(false);
    const {axios} = useAxios();
    const [data, setData] = useState(null);
    const [globalStatsLoading, setGlobalStatsLoading] = useState(true);

    const formatData = useCallback((data) => {
        let result = [];
        Object.entries(data).forEach(([key, item]) => {
            switch (key) {
                case "totalProperties":
                    result = [...result, {
                        id: key,
                        label: "Nombre de biens enregistrés",
                        badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-numbers-line",
                        counter: item,
                        decimals: 0,
                        suffix: "",
                        prefix: ""
                    }];
                    break;
                case "totalInUse":
                    result = [...result, {
                        id: key,
                        label: "Biens en service",
                        badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-service-line",
                        counter: item,
                        decimals: 0,
                        suffix: "",
                        prefix: ""
                    }];
                    break;
                case "acquisitionAmount":
                    result = [...result, {
                        id: key,
                        label: "Montant total des acquisitions",
                        badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-price-tag-2-line",
                        counter: item,
                        decimals: 0,
                        suffix: "",
                        prefix: ""
                    }];
                    break;
                case "totalAmortize":
                    result = [...result, {
                        id: key,
                        label: "Biens amortis",
                        badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-exchange-cny-line",
                        counter: item,
                        decimals: 0,
                        suffix: "",
                        prefix: ""
                    }];
                    break;
                case "totalByEtats":
                    result = [...result, {
                        id: key,
                        label: "Biens en bon etat",
                        badge: "ri-arrow-up-circle-line text-success",
                        icon: "ri-space-ship-line",
                        counter: item,
                        decimals: 0,
                        suffix: "",
                        prefix: ""
                    }];
                    break;
                default:
                    break;
            }
        });
        return result;
    }, []);

    const fetchData = useCallback((property) => {
        setLoadingData(true);
        const queryString = property ? `?etat_id=${property.value}` : "";
        axios.get(`/api/dashboard/global-stats${queryString}`)
            .then(({data}) => {
                if (data?.etat) {
                    if (property === null) {
                        setProperty({
                            value: data.etat.id,
                            label: data.etat.name
                        })
                    }
                }
                setData(formatData(data));
            })
            .catch(error => toast.error("Something is wrong"))
            .finally(() => setLoadingData(false))
        ;
    }, [formatData]);

    useEffect(() => {
        fetchData(property);
    }, [formatData, property]);

    const drop = useMemo(() => {
        return (
            <div>
                <UncontrolledDropdown direction='start'>
                    <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm" >
                        <span className="text-uppercase">{property?.label}<i className="mdi mdi-chevron-down align-middle ms-1"></i></span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu dropdown-menu-end">
                        {options.map((item, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => { setProperty(item); }}
                                className={property?.value === item?.value ? "active" : ""}
                            >
                                {item?.label}
                            </DropdownItem>
                        ))}
                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        );
    }, [property, options]);

    return (
        <Row>
            <Col xl={12}>
                {
                    loadingData ?
                        <Row className={"d-flex justify-content-center mb-2"}>
                            <Spinner content={"Chargement..."} color={"gray"} />
                        </Row> :
                        <Card className="crm-widget">
                            <CardBody className="p-0">
                                <Row className="row-cols-md-3 row-cols-1">
                                    {(data || []).map((item, key) => (
                                        <Col className={item.id === 4 ? "col-lg" : "col-lg border-end"} key={key}>
                                            <div className="mt-3 mt-md-0 py-4 px-3">
                                                <h5 className="text-muted text-uppercase fs-13 d-flex align-items-center justify-content-between">
                                                    {item.id === "totalByEtats" ? drop : (
                                                        <span style={{fontSize: ".7rem"}}>{item.label}</span>
                                                    )}
                                                </h5>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className={"display-6 text-muted " + item.icon}></i>
                                                    </div>

                                                    <div className="flex-grow-1 ms-3">
                                                        <h2 className="mb-0">
                                                    <span className="counter-value">
                                                        <CountUp
                                                            start={0}
                                                            prefix={item.prefix}
                                                            suffix={item.suffix}
                                                            separator={item.separator}
                                                            end={item.counter}
                                                            decimals={item.decimals}
                                                            duration={4}
                                                        />
                                                    </span>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>))}
                                </Row>
                            </CardBody>
                        </Card>
                }

            </Col>
        </Row>
    );
};

export default TileBoxs;
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Input,
    Label,
    Row,
} from "reactstrap";
import {toast} from "react-toastify";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import DbDatatable from "../../Components/Common/DbDatatable";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";
import {useAxios} from "../../hooks";
import {CodeQR, Date, StaffCol, Text} from "../../Components/Common/ColTableFormat";
import ReactSelect from "../../Components/Common/ReactSelect";
import {formatDatInput, userHavePermissions} from "../../helpers";
import TransfertForm from "./TransfertForm";

const Mouvements = () => {
    document.title ="Biens | "+env('NAME');
    const {axios} = useAxios();
    const [entities, setEntities] = useState([]);
    const [entitiesLoading, setEntitiesLoading] = useState(false);
    const [standard, setStandard] = useState(null);
    const [standards, setStandards] = useState([]);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [states, setStates] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [showTransferForm, setShowTransferForm] = useState(false);
    const [maxDate, setMaxDate] = useState({
        start: "",
        end: ""
    });

    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: []
    });

    // Fetch Data
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        setEntitiesLoading(true);
        const newSite = site ? site.value : '';
        const newBuilding = building ? building.value : '';
        const newLocal = local ? local.value : '';
        const newStandard = standard ? standard.value : "";
        axios.get(`/api/property-movements/filters?page=${pageIndex}&per_page=${pageSize}&search=${search}&site_id=${newSite}&batiment_id=${newBuilding}&local_id=${newLocal}&standard_id=${newStandard}&begin_at=${startDate}&end_at=${endDate}`)
            .then(({data}) => {
                setEntities(data.data);
            })
            .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
            .finally(() => setEntitiesLoading(false))
        ;
    }, [building, endDate, local, site, standard, startDate]);

    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }));
    }, []);

    useEffect(() => {
        setLoadingCreate(true);
        axios.get(`/api/property-movements`)
            .then(({data}) => {
                function formatData (array) {
                    if (Array.isArray(array)) {
                        return array.map(item => ({
                            ...item,
                            value: item.id,
                            label: item.name
                        }));
                    }
                    return [];
                }
                setFilterData({
                    sites: formatData(data.sites),
                    buildings: [],
                    locals: []
                })
                setStates(formatData(data.etats));
                setStandards(formatData(data.standards));
                setMaxDate({
                    start: formatDatInput(data.fiscalDateFilters.begin_at),
                    end: formatDatInput(data.fiscalDateFilters.end_at)
                })
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadingCreate(false)})
        ;
    }, []);

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: formatData(value.batiments),
                locals: []
            })
        }
    };

    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            locals: []
        });
        setBuilding(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: formatData(value.locals)
            })
        }
    }

    useEffect(() => {
        if (site || building || local || standard) {
            fetchData(1, 10,  "", {site, building, local, standard});
        }
    }, [building, fetchData, local, site, standard]);

    const columns = useMemo(() => {
        return [
            {
                Header: "Code QR",
                accessor: "property.qr_code.link",
                filterable: false,
                Cell: (cellProps) => {
                    return <CodeQR {...cellProps} />;
                },
            },
            {
                Header: "Codification",
                accessor: "property.codification",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard",
                accessor: "property.standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Date",
                accessor: "assignment_date",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Code immo",
                accessor: "property.code_immo",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Nouvel utilisateur",
                accessor: "staff",
                filterable: false,
                Cell: (cellProps) => {
                    return <StaffCol {...cellProps} />;
                },
            },
            {
                Header: "Ancien utilisateur",
                accessor: "assignment_old.staff",
                filterable: false,
                Cell: (cellProps) => {
                    return <StaffCol {...cellProps} />;
                },
            },
            /*{
                Header: "Actions",
                accessor: "property.is_editable",
                Cell: (cellProps) => {
                    const button = (
                        <DropdownToggle tag="a" className="btn btn-soft-secondary btn-sm">
                            <i className="ri-more-fill align-middle"/>
                        </DropdownToggle>
                    );

                    const detail = (
                        <li>
                            <DropdownItem
                                className="edit-item-btn"
                                href="#showModal"
                                data-bs-toggle="modal"
                                onClick={() => {
                                    setProperty(cellProps.row.original);
                                }}
                            >
                                <i className="ri-git-branch-line align-bottom me-2 text-muted"/> Ecart
                            </DropdownItem>
                        </li>
                    );

                    const update = (
                        <li>
                            <DropdownItem
                                className="edit-item-btn"
                                href="#showModal"
                                data-bs-toggle="modal"
                                onClick={() => {
                                    setProperty(cellProps.row.original);
                                }}
                            >
                                <i className="ri-user-settings-line align-bottom me-2 text-muted"/> Inventoriste
                            </DropdownItem>
                        </li>
                    );

                    return (
                        <UncontrolledDropdown>
                            {button}

                            <DropdownMenu className="dropdown-menu-end">
                                {detail}
                                {update}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    );
                },
            },*/
        ]
    }, []);

    return (
        <PageContent title={"Transferts"} pageTitle={"Gestion des biens"}>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">Liste des transferts</h5>
                            </div>
                        </CardHeader>

                        <CardBody>
                            {userHavePermissions(["store-transfert"]) && (
                                <Row className="mb-4">
                                    <Col className="d-flex justify-content-end">
                                        <Button color="primary" onClick={() => setShowTransferForm(!showTransferForm)}> Effectuer un transfert </Button>

                                        <TransfertForm
                                            show={showTransferForm}
                                            toggle={() => {
                                                setShowTransferForm(!showTransferForm);
                                            }}
                                            close={() => {
                                                setShowTransferForm(false);
                                            }}
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Row className="mb-3">
                                <Col sm={12} lg={6} xl={4} className="mb-3">
                                    <Label htmlFor="site" className="form-label">Site</Label>
                                    <ReactSelect
                                        id="site"
                                        name="site"
                                        placeholder="Veuillez sélectionner le site"
                                        value={site}
                                        onChange={handleSiteChange}
                                        options={filterData.sites}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={12} lg={6} xl={4} className="mb-3">
                                    <Label htmlFor="building" className="form-label">Batiment</Label>
                                    <ReactSelect
                                        id="building"
                                        name="building"
                                        placeholder="Veuillez sélectionner le batiment"
                                        value={building}
                                        onChange={handleBuildingChange}
                                        options={filterData.buildings}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={12} lg={6} xl={4} className="mb-3">
                                    <Label htmlFor="local" className="form-label">Local</Label>
                                    <ReactSelect
                                        id="local"
                                        name="local"
                                        placeholder="Veuillez sélectionner le local"
                                        value={local}
                                        onChange={value => {setLocal(value)}}
                                        options={filterData.locals}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={12} lg={6} xl={4} className="mb-3">
                                    <Label htmlFor="standard" className="form-label">Standard</Label>
                                    <ReactSelect
                                        id="standard"
                                        name="standard"
                                        placeholder="Veuillez sélectionner le standard"
                                        value={standard}
                                        onChange={value => {setStandard(value)}}
                                        options={standards}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={12} lg={6} xl={4}>
                                    <div id="modal-id mb-3">
                                        <Label htmlFor="start_date" className="form-label">Date de début</Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="start_date"
                                            placeholder="Veuillez entrer la date de début"
                                            value={startDate}
                                            min={maxDate.start}
                                            max={maxDate.end}
                                            onChange={e => setStartDate(e.target.value)}
                                        />
                                    </div>
                                </Col>

                                <Col sm={12} lg={6} xl={4}>
                                    <div id="modal-id mb-3">
                                        <Label htmlFor="end_date" className="form-label">Date de fin</Label>
                                        <Input
                                            type="date"
                                            className="form-control"
                                            id="end_date"
                                            placeholder="Veuillez entrer la date de fin"
                                            value={endDate}
                                            min={maxDate.start}
                                            max={maxDate.end}
                                            onChange={e => setEndDate(e.target.value)}
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <DbDatatable
                                columns={columns}
                                data={entities}
                                className="custom-header-css"
                                loading={entitiesLoading}
                                fetchData={fetchData}
                                perPage={entities ? entities['per_page'] : undefined}
                                totalPage={entities ? entities['last_page'] : undefined}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </PageContent>
    );
};

export default Mouvements;
import React, {useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Date, Status, Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {formatDatInput} from "../../helpers";
import {toast} from "react-toastify";
import {useAxios} from "../../hooks";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner} from "reactstrap";
import CloseInventoryForm from "../properties-inventory/CloseInventoryForm";
import CloseFiscalYearForm from "./CloseFiscalYearForm";

const AccountExercises = () => {

    const [confirmationModal,setConfirmationModal] = useState(false)
    const [fiscalYearAction,setFiscalYearAction] = useState('')
    const [closingFiscalYear, setClosingFiscalYear] = useState(false);
    const [action, setAction] = useState()
    const [id, setId] = useState()
    const [fiscalYear, setFiscalYear] = useState()
    const [showErrorForm, setShowErrorForm] = useState(false)
    const [inventories, setInventories] = useState()
    const {axios} = useAxios();

    document.title = "Exercices comptables | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Référence",
                accessor: "reference",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                    },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                    },
            },
            {
                Header: "Date de début",
                accessor: "begin_at",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Date de fin",
                accessor: "end_at",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Statut",
                accessor: "status",
                filterable: false,
                Cell: (cellProps) => {
                    return <Status  {...cellProps} />;
                },
            },
        ]
    }, []);

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Créer un exercice comptable',
                update: 'Modifier un exercice comptable'
            },
            formDefaultError: {name: [], begin_at: [], end_at: []},
            initialValues: {name: '', begin_at: '', end_at: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                begin_at: Yup.string().required("Veuillez entrer la date de début"),
                end_at: Yup.string().required("Veuillez entrer la date de fin"),
            }),
            formatFormData: (values) => values,
            onSuccess: (response, type) => {},
            onFail: (response, type) => {},
        };
    }, []);

    const formatList = (list) => {
        return {
            ...list,
            data: list.data.map(item => {
                return {
                    ...item,
                    begin_at: formatDatInput(item.begin_at),
                    end_at: formatDatInput(item.end_at),
                }
            })
        };
    };

    const closeFiscalYear = () => {
        axios.post(`/api/closing-fiscal-years/${fiscalYear.id}`)
            .then(({data}) => {
                setClosingFiscalYear(false)
                setConfirmationModal(false)
                toast.success("Exercice comptable clôturé avec succès !")
            })
            .catch((error) => {
                switch (error.response.status)
                {
                    case 422 :
                        setInventories(error.response.data.error)
                        setConfirmationModal(false)
                        setShowErrorForm(true)
                        break;
                    case 500:
                        toast.error("Oups! Quelque chose s'est mal passée")
                        break;
                }
            })
            .finally(() => {
                setClosingFiscalYear(false)
            });
    };

    const openFiscalYear = () => {
        axios.post(`/api/reopen-fiscal-years/${fiscalYear.id}`)
            .then(({data}) => {
                setClosingFiscalYear(false)
                setConfirmationModal(false)
                toast.success("Exercice comptable réouvert avec succès !")

            })
            .catch((error) => {
                toast.error("Oups! Quelque chose s'est mal passée")
            })
            .finally(() => {setClosingFiscalYear(false)});

    };

    const closeOrOpenFiscalYear = (fiscalYear) => {

        if (fiscalYear.closed_at == null)
        {
            closeFiscalYear();
        }

        if (action === 'open')
        {
            openFiscalYear();
        }

    }
    const showConfirmDialog = (fiscalYear) =>{
        if (fiscalYear.closed_at == null)
        {
            setAction('close')
        }else
        {
            setAction('open')
        }

        setFiscalYear(fiscalYear)
        setConfirmationModal(true)
    }

    return (
        <PageContent title={"Exercices comptables"} pageTitle={"Configuration"}>
            <DatatablePageProvider
                title={""}
                endpoint={"/api/fiscal-years"}
                formatList={formatList}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                closePermission={"closing-an-fiscal-year"}
                reopenPermission={"closing-an-fiscal-year"}
                storePermission={"store-fiscal-year"}
                updatePermission={"edit-fiscal-year"}
                deletePermission={"destroy-fiscal-year"}
                closeOrOpenFiscalYear={showConfirmDialog}
                activeExercise={
                    {
                        text: "Activer l'exercice",
                        endpoint: "/api/fiscal-years/:id/enable",
                        permissions: ["store-fiscal-year", "edit-fiscal-year"],
                        actionIf: [
                            {
                                key: "is_active",
                                value: false,
                            },
                            {
                                key: "status",
                                value: "not_started"
                            }
                        ]
                    }
                }
            >
                <Datatable/>
            </DatatablePageProvider>
            <Modal id="ConfirmationModal" isOpen={confirmationModal}  size="md" className="border-0" modalClassName="zoomIn">
                <ModalHeader toggle={() => setConfirmationModal(!confirmationModal) } className="p-3 bg-soft-info">
                    Confirmation
                </ModalHeader>
                <ModalBody>
                    <Row className="g-3 text-center">
                        Êtes vous sûre de vouloir vraiment {action === 'open' ? 'réouvrir' : 'clôturer' } cet exercice?
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => setConfirmationModal(false)}>
                        Annuler
                    </Button>

                    <button type="submit" className="btn btn-success" id="add-btn" onClick={closeOrOpenFiscalYear}>
                        {closingFiscalYear && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <i className="ri-close-line align-bottom"></i> {closingFiscalYear ? "Clôture de l'exercice en cours..." :"Oui, continuer"}
                    </button>
                </ModalFooter>
            </Modal>

            <CloseFiscalYearForm
                toggle={() => setShowErrorForm(!showErrorForm) }
                open={showErrorForm}
                inventories={inventories}
            />

        </PageContent>
    );
};

export default AccountExercises;
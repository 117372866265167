import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {useAxios} from "../../hooks";
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Input,
    Label,
    Row,
    Spinner,
    Table,
    UncontrolledCollapse
} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import {Icons, toast} from "react-toastify";
import {useParams} from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const InventoryConfig = () => {
    const {axios} = useAxios();
    const [loadingFilterData, setLoadingFilterData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [site, setSite] = useState(null);
    const [local, setLocal] = useState([]);
    const [locals, setLocals] = useState([]);
    const [batiments, setBatiments] = useState([]);
    const [building, setBuilding] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const [user, setUser] = useState(null);
    const [sending, setSending] = useState(false);
    const [inventory, setInventory] = useState();
    const [inventoristData, setInventoristData] = useState(null);
    const [inventorist, setInventorist] = useState(null);
    const [currentBuilding, setCurrentBuilding] = useState(null);
    const [searchLocal, setSearchLocal] = useState("");
    const [searchBuilding, setSearchBuilding] = useState("");
    const history = useHistory()

    const [formError, setFormError] = useState({
        user_id : [],
        site_id : [],
        batiment_id : [],
        locals : [],
        inventory_id : [],
    });
    const {id} = useParams()
    const {userId} = useParams()

    useEffect(() => {
        if (inventorist){
            setUser({label : inventorist?.user.name,value : inventorist?.user.id})
            let site = inventoristData?.sites?.find((sit) => sit.id === inventorist?.site_id);
            setSite({value: site?.id, label : site?.name})
            setBuildings(inventoristData?.batiments.filter((b) => b.site_id === site.id));

            setBatiments(inventorist?.batiments.map((item) => item.id))
            setLocals(inventorist?.locals.map((item) => item.id))
        }

        setLoadingFilterData(true)
        axios.get(`/api/inventorist-locals/create?inventory_id=${id}&user_id=${userId || ''}`)
            .then(({data}) => {
            
                setInventoristData(data)
                setInventory(data.inventory)

                if(data.edit_data){
                    setUser( data.edit_data.user)
                    setLocals(data.edit_data.locals)
                    setSite(data.edit_data.site)
                    setBuildings(data.batiments?.filter((b) => b.site_id === data.edit_data.site.value));
                    setBatiments(data.edit_data.batiments)
                }

            }).catch(() => {console.log("Something is wrong")})
            .finally(() => setLoadingFilterData(false
            ));
    }, []);

  

    const submitForm = (submitAndQuit = false) => {
        
        const sendingData = {
            user_id: user ? user.value : null,
            locals: locals,
            site_id: site ? site.value : null,
            batiments: batiments,
            inventory_id: inventory?.id,
        };

        axios.post(`api/inventorist-locals`, sendingData)
            .then(() => {
                toast.success("Inventoriste configuré avec succès");
                if(submitAndQuit){
                    history.goBack()
                }else{
                    window.location.reload()
                }
            })
            .catch((error) => {
                if (error?.response?.status === 422){
                    setFormError(error?.response.data.errors)
                }
            })
            .finally(() => {
                setSending(false);
            });
    }
    const handleSiteChange = (newSite) => {
        if (building?.value) {
            setBuilding(null);
        }
        setBuildings(inventoristData?.batiments.filter((b) => b.site_id === newSite?.value));

        if (locals?.length) {
            setLocals([]);
        }

        setSite(newSite);

    };
    function formatData (data) {
        return data?.map(item => {
            return {
                ...item,
                label: item.name || item.fullName,
                value: item.id,
            }
        });
    }
    function handleBuildingChange (newBuilding) {
        let bats = batiments;
        if (batiments?.includes(newBuilding.id)){
            bats = batiments.filter((bat) => bat !== newBuilding.id)
        }else{
            bats = [...batiments, ...[newBuilding.id]]
        }
        setBatiments(bats)
    }
    function handleLocalChange (input) {
        console.log(input.target.value)
        let locs = [];
        if (locals?.includes(input.target.value)){
            locs = locals.filter((loc) => loc !== input.target.value)
        }else{
            locs = [...locals, ...[input.target.value]]

        }
        setLocals(locs)
    }
    const selectAllLocals = (input) => {
        let batimentId = input.target.value
        let batimentLocals = inventoristData?.locals?.filter((b) => b.batiment_id === batimentId)?.map((l) => l.id);

        if(buildingIsSelected(batimentId)){
            setLocals(locals.filter(item => !batimentLocals.includes(item)))
        }else{
            let allLocals = [...locals, ...batimentLocals];
            setLocals([...new Set(allLocals)])
        }
 
    }

    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: [],
        standards: [],
    });
   
    const buildingIsSelected = (buildingId) => {
        let building = buildings.find((b) => b.id === buildingId)

        return haveCommonElements(building.locals.map((l) => l.id), locals)
    }

    const haveCommonElements = (arr1, arr2) => {
        return arr1.some(item => arr2.includes(item));
      }

    const containsAllElements = (arr1, arr2) => {
        return arr2.every(item => arr1.includes(item));
      }

    document.title ="Configuration d'inventaire | " + env('NAME');

    return (
        <PageContent title={"Configuration de l'inventaire"} pageTitle={"Tableau de bord"}>

           <Card>
               <CardBody>
                   <Row>
                       <div className={'container'}>
                           <Row className={'g-3'}>
                               <Col lg={6}>
                                   <div id="modal-id">
                                       <Label htmlFor="inventoriste" className="form-label">Veuillez sélectionner l'nventoriste</Label>
                                       <ReactSelect
                                           id="inventoriste"
                                           name="inventoriste"
                                           placeholder="Veuillez sélectionner l'inventoriste"
                                           value={user}
                                           onChange={value => setUser(value)}
                                           options={formatData(inventoristData?.users) || []}
                                           isLoading={loadingFilterData}
                                           error={formError?.user_id?.[0]}
                                       />
                                   </div>
                               </Col>
                               <Col lg={6}>
                                   <div id="modal-id">
                                       <Label htmlFor="site" className="form-label">Veuillez sélectionner le site</Label>
                                       <ReactSelect
                                           id="site"
                                           name="site"
                                           placeholder="Veuillez sélectionner le site"
                                           value={site}
                                           onChange={handleSiteChange}
                                           options={formatData(inventoristData?.sites) || []}
                                           isLoading={loadingFilterData}
                                           error={formError?.site_id?.[0]}
                                       />
                                   </div>
                               </Col>
                           </Row>
                       </div>
                   </Row>
                   {
                    site && 
                    <Row className="d-flex m-4 justify-content-center">
                    <Col md="4">
                    <Input
                    placeholder={'Rechercher un bâtiment'}
                                    value={searchBuilding}
                                    onChange={(e) => {
                                        setSearchBuilding(e.target.value)
                                    }}
                                />
                    </Col>
               </Row>
                   }

                  
                                                           

                   <Row className={"m-4"}>

                       {
                           buildings.filter((item) => item.name.toLowerCase().includes(searchBuilding?.toLowerCase()))?.map((building) => (
                                   <div key={building.id}>
                                   <Card>
                                    <CardBody                                                
                                         onClick={() => { handleBuildingChange(building); setSearchLocal("");  (building.id === currentBuilding) ? setCurrentBuilding(null) : setCurrentBuilding(building.id) }}                                    >
                                        <a href="#" onClick={(e) => e.preventDefault()} className={"d-flex justify-content-between"}>
                                            <strong style={{fontSize: '1.2em'}}>{building.name}</strong>
                                            {
                                               buildingIsSelected(building.id) &&
                                                <span>
                                                    <img src="/icons/checked.png" alt="" />
                                                </span>
                                            }
                                           
                                        </a>
                                    </CardBody>
                                   </Card>
                                       
                                       <Collapse isOpen={ building.id === currentBuilding}>
                                           <Card>
                                               <CardBody>
                                                   {
                                                       building.locals.length > 0 &&
                                                       <div className="d-flex mb-4">
                                                           
                                                               <Input
                                                                   id="select-all"
                                                                   type="checkbox"
                                                                   checked={containsAllElements(locals, building.locals.map((l) => l.id))}
                                                                   className="form-check-input"
                                                                   value={building.id}
                                                                   onChange={selectAllLocals} />&nbsp;&nbsp;
                                                           <span >Tout cocher/décocher</span>
                                                       </div>

                                                   }
                                                   {
                                                       building.locals.length === 0 &&
                                                       <Row className={'text-center text-muted'}>
                                                           Ce batiment ne dispose pas de bureaux ou tout les bureaux ont été
                                                           déjà assignés.
                                                       </Row>
                                                   }
                                                   <Row className="d-flex m-4 justify-content-center">
                    <Col md="4">
                    <Input
                    placeholder={'Rechercher un bureau'}
                                    value={searchLocal}
                                    onChange={(e) => {
                                        setSearchLocal(e.target.value)
                                    }}
                                />
                    </Col>
               </Row>
                                                   {
                                                       building.locals?.filter((it) => it.name.toLowerCase().includes(searchLocal?.toLowerCase()))?.map((item, index) => (
                                                           <div key={item.id}>
                                                               <Label
                                                                   className="form-check-label"
                                                                   htmlFor={item.id}>
                                                                   <Input
                                                                       id={item.id}
                                                                       type="checkbox"
                                                                       className="form-check-input"
                                                                       value={item.id}
                                                                       checked={locals?.includes(item.id)}
                                                                       onChange={handleLocalChange}
                                                                   />&nbsp;
                                                                   <span>{item.name}</span>
                                                               </Label>
                                                           </div>
                                                       ))
                                                   }

                                               </CardBody>
                                           </Card>
                                       </Collapse>
                                   </div>
                           ))
                       }

                   </Row>

                   {
                    <div className="d-flex justify-content-right">
                
                <Button onClick={() => { history.goBack() }} color="light">
                    Annuler
                </Button>&nbsp;
                <button disabled={locals?.length === 0} type="submit" className="btn btn-success" id="add-btn" onClick={() => { submitForm(true) }}>
                    <span className="d-flex align-items-center justify-content-center">
                        {sending && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                        )}
                        <span className="ms-2">{sending ? "Chargement" : "Enregistrer"}</span>
                    </span>
                </button> &nbsp;
                <button disabled={locals?.length === 0} type="submit" className="btn btn-success" id="add-btn" onClick={() => { submitForm() }}>
                    <span className="d-flex align-items-center justify-content-center">
                        {sending && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                        )}
                        <span className="ms-2">{sending ? "Chargement" : "Enregistrer et contunier"}</span>
                    </span>
                </button>
            </div>
            }
               </CardBody>
           </Card>
        </PageContent>
    );
};

export default InventoryConfig;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";
import axios from "axios";
import {downloadDataUrlFromJavascript, downloadReport, formatDate, scrollTopPage} from "../../helpers";
import {toast} from "react-toastify";
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";

const Staffs = () => {
    document.title ="Agents | "+env('NAME');
    const [submitting, setSubmitting] = useState(false);
    const [showHolderCard, setShowHolderCard] = useState(false)
    const [holderCard, setHolderCard] = useState(null)
    const [staffId, setStaffId] = useState(null)
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportModalErrors, setShowImportModalErrors] = useState(false);
    const [importErrors, setImportErrors] = useState(null);
    const [submitError, setSubmitError] = useState();
    const [file, setFile] = useState();
    const [errorMessage, setErrorMessage] = useState("");


    const downloadStaffProperties = (staffId) => {
        setSubmitting(true)
        setStaffId(staffId)
        axios.get(`/api/export/staff-properties/${staffId}`,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data,'fiche-detenteur','pdf')
                toast("Exportation effectuée avec succès", {type: 'success'});
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});

            })
            .finally(() => setSubmitting(false))
        ;
    }
    const submitImport = () => {
        setSubmitting(true)
        let data = new FormData();
        data.append('file',file[0])

        axios.post('/api/import-staffs',data)
            .then(({data}) => {
                if (data?.error){
                    toast("Des erreurs ont été rencontrées lors de l'importation", {type: 'error'});
                    setImportErrors(data.errors)
                    setShowImportModalErrors(true)
                }else{
                    setShowImportModal(false)
                    toast("Importaion effectuée avec succès", {type: 'success'});
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log({error})
                toast( "Echec de d'importation", {type: 'error'});
                if (error.response?.status === 422) {
                    setSubmitError({...error.response.data.errors});
                }
            })
            .finally(() => setSubmitting(false))
        ;
    }
    const downloadDataUrlFromJavascript = (filename, dataUrl) => {

        // Construct the 'a' element
        const link = document.createElement("a");
        link.download = filename;
        link.target = "_blank";

        // Construct the URI
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();

        // Cleanup the DOM
        document.body.removeChild(link);
        //delete link;
    }
    const handleFileChange = (e) => {
        setFile(Object.values(e.target.files));
    }

    const showHolder = (staffId) => {
        setSubmitting(true)
        setStaffId(staffId)
        axios.get(`/api/staff-properties/${staffId}`)
            .then(({data}) => {
                setHolderCard(data)
                setShowHolderCard(true)
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});
            })
            .finally(() => setSubmitting(false))
        ;
    }

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "N° Matricule",
                accessor: "code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Nom",
                accessor: "last_name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Prénom",
                accessor: "first_name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Email",
                accessor: "email",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Téléphone",
                accessor: "telephone",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Fonction",
                accessor: "position.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Bureau",
                accessor: "local.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Fiche détenteur",
                accessor: "",
                filterable: false,
                Cell: (cellProps) => {
                    return <>
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={() => showHolder(cellProps.row.original.id)}>
                            <i className="ri-eye-2-line align-bottom"></i> Consulter
                        </button>
                    </>;
                },
            }
        ]
    }, []);

    const [key, setKey] = useState('');
    const [position, setPosition] = useState(null);
    const [service, setService] = useState(null);
    const [local, setLocal] = useState(null);
    const {list, loading} = useFetchDataList('/api/staff/create', (data) => {
        return {
            positions: data['positions'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }),
            services: data['services'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }),
            locals: data['locals'].map(item => {
                return {
                    label: item.full_name,
                    value: item.id,
                }
            }),
        };
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter un agent',
                update: 'Modifier un agent'
            },
            formDefaultError: {first_name: [], last_name: [], code: [], address: [], service_id: [], local_id: [], civility: [], telephone: [],email: []},
            initialValues: {first_name: '', last_name: '', code: '', address: '', service_id: '', local_id: '', civility: 'M.', telephone: '', email : ''},
            validationSchema: Yup.object({
                telephone: Yup.string().required("Veuillez entrer le téléphone"),
                first_name: Yup.string().required("Veuillez entrer le prénom"),
                last_name: Yup.string().required("Veuillez entrer le nom"),
                email: Yup.string().required("Veuillez entrer l'email"),
                code: Yup.string().required("Veuillez entrer le code"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    position_id: position ? position.value : '',
                    service_id: service ? service.value : '',
                    local_id: local ? local.value : '',
                };
            },
            formatResponse: data => {
                const newData = {...data};
                let result = list['positions'].filter(item => item.value === data['position_id']);
                result = result.length ? result[0] : null;
                newData['position'] = {name: result.label};
                result = list['services'].filter(item => item.value === data['service_id']);
                result = result.length ? result[0] : null;
                newData['service'] = {name: result.label};
                result = list['locals'].filter(item => item.value === data['local_id']);
                result = result.length ? result[0] : null;
                newData['local'] = {name: result.label};

                return newData;
            },
            onSuccess: (response, type) => {
                setPosition(null);
                setLocal(null)
                setService(null)
            
            },
            onFail: (response, type) => {},
        };
    }, [key, list, position, service, local]);

    const onUpdateEntity = useCallback((editData) => {
        let result = list['positions'].filter(item => item.value === editData['position_id']);
        result = result.length ? result[0] : null;
        if (result) {
            setPosition(result);
        } else {
            setPosition(null);
        }

        result = list['locals'].filter(item => item.value === editData['local_id']);
        result = result.length ? result[0] : null;
        if (result) {
            setLocal(result);
        } else {
            setLocal(null);
        }

        result = list['services'].filter(item => item.value === editData['service_id']);
        result = result.length ? result[0] : null;
        if (result) {
            setService(result);
        } else {
            setService(null);
        }

    }, [list]);

    const onAddEntity = useCallback(() => {
        setService(null);
        setLocal(null);
        setPosition(null);
    }, []);

    return (
        <PageContent title={"Agents"} pageTitle={"Personnel"}>
            <DatatablePageProvider
                title={""}
                endpoint={"/api/staff"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-staff"}
                updatePermission={"edit-staff"}
                deletePermission={"destroy-staff"}
            >
                <div className={"d-flex justify-content-end mb-2"}>
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => setShowImportModal(true)}>
                        <i className="ri-add-line align-bottom"></i> Importer
                    </button>&nbsp;
                </div>

                <Datatable
                    position={position}
                    setPosition={setPosition}
                    positions={list['positions']}
                    locals={list['locals']}
                    services={list['services']}
                    local={local}
                    service={service}
                    setService={setService}
                    setLocal={setLocal}
                    setKey={setKey}
                    loading={loading}
                />
            </DatatablePageProvider>

            <Modal id="holderCardModal" isOpen={showHolderCard}  size="lg" className="border-0" modalClassName="zoomIn">
                <ModalBody>
                    <h3 className={'text-center text-decoration-underline'}>Fiche détenteur</h3>
                    {
                        holderCard &&
                        <>
                            <Row className="g-3">
                                <table className={'table'}>
                                    <tbody>
                                    <tr>
                                        <th>N° Matricule</th>
                                        <td>{holderCard.staff.code}</td>
                                    </tr>
                                    <tr>
                                        <th>Nom</th>
                                        <td>{holderCard.staff.last_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Prénom</th>
                                        <td>{holderCard.staff.first_name}</td>
                                    </tr>
                                    <tr>
                                        <th>Direction/Service</th>
                                        <td>{holderCard.staff?.service?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Ville</th>
                                        <td>{holderCard.staff.local?.batiment?.site?.city?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Bâtiment</th>
                                        <td>{holderCard.staff.local?.batiment?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Niveau</th>
                                        <td>{holderCard.staff.local?.level?.name}</td>
                                    </tr>
                                    <tr>
                                        <th>Local</th>
                                        <td>{holderCard.staff.local?.name}</td>
                                    </tr>
                                    </tbody>
                                </table>

                                <h4>Liste des biens</h4>
                                <div className={'table-responsive'}>
                                    <table className={'table'}>
                                        <thead>
                                        <tr className={'bg-dark text-white'}>
                                            <th>QR Code</th>
                                            <th>Code bien</th>
                                            <th>Code IMMO</th>
                                            <th>Code ERP (compta)</th>
                                            <th>Sous standard</th>
                                            <th>Localisation</th>
                                            <th>Etat</th>
                                            <th>Date d’acquisition</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            holderCard?.properties?.map((property) => (
                                                <tr key={property?.id}>
                                                    <td>
                                                        <img src={property.qr_code.link} alt="" width={50} height={50} className="img-fluid rounded" />
                                                    </td>
                                                    <td>{property.codification}</td>
                                                    <td>{property.code_immo}</td>
                                                    <td>{property.reference}</td>
                                                    <td>{property.standard.name}</td>
                                                    <td>{property.assignment_active?.local?.name}</td>
                                                    <td>{property.etat?.name}</td>
                                                    <td>{formatDate(property.date_of_purchase)}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'d-flex justify-content-between'}>
                                    <h6 className={'text-decoration-underline'}>Détenteur</h6>
                                    <h6 className={'text-decoration-underline'}>Gestionnaire des Immobilisations</h6>
                                </div>
                            </Row>
                        </>
                    }
                </ModalBody>

                <ModalFooter>
                    <Button color="light" onClick={() => setShowHolderCard(false)}>
                        Annuler
                    </Button>

                    <button type="submit" className="btn btn-primary" id="add-btn" onClick={() => downloadStaffProperties(staffId)}>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <i className="ri-download-2-line align-bottom"></i> {"Exporter"}
                    </button>
                </ModalFooter>
            </Modal>

            <Modal
                id={"import-modal"}
                isOpen={showImportModal}>
                <ModalHeader  className="p-3 bg-soft-info">
                    Importer les agents
                </ModalHeader>

                <ModalBody>
                    <div className="table-responsive">
                        {errorMessage.length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                    <form>
                        <Row className={'g-3'}>
                            <Col sm={12}>
                                <Label htmlFor="file" className="form-label">Fichier</Label>
                                <Input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    validate={{
                                        required: {value: submitError && submitError.file.length > 0},
                                    }}
                                    multiple={false}
                                    placeholder="Veuillez selectionner le fichier"
                                    onChange={e => handleFileChange(e)}
                                    invalid={submitError && submitError.file?.length > 0}
                                />
                                {submitError && submitError.file?.length > 0 && (
                                    <FormFeedback type="invalid">
                                        {submitError.file[0]}
                                    </FormFeedback>
                                )}
                            </Col>
                            <div className={"mt-2"}>
                                Télécharger <a href="#"
                                               onClick={() => downloadDataUrlFromJavascript('format_staff.xlsx', `${process.env.REACT_APP_API_HOST}/storage/model-files/format_staff.xlsx`)}
                                               className={"fw-bold"}>le format d'importation <i
                                className={"ri ri-download-2-line"}></i></a>
                            </div>
                        </Row>
                    </form>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModal(false)}>
                        Annuler
                    </Button>

                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab"
                        onClick={submitImport}
                        disabled={submitting || !file }>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement... </Spinner>
                        )}
                        {"Importer"}
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                id={"import-modal-errors"}
                size={"lg"}
                isOpen={showImportModalErrors}>
                <ModalHeader  className="p-3 bg-soft-info">
                    Erreur d'importation
                </ModalHeader>

                <ModalBody>

                    <div className={"p-4"}>
                        <div className={"text-danger text-center"}>
                            Veuillez corriger ces lignes et réessayer la réimportation
                        </div>
                        { importErrors?.map((error,index) =>
                            (
                                <Row key={`row-${index}`}>
                                    <h5>Sur la ligne N° {error.line}</h5>
                                    {<ul>
                                        {Object.values(error?.messages)?.map((msg,index) =>
                                            (<li key={`list-${index}`} className="text-danger">{msg[0]}</li>)
                                        )}

                                    </ul>}
                                </Row>
                            ))}

                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModalErrors(false) }>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>

        </PageContent>
    );
};

export default Staffs;
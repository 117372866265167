import {Button, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner, Table} from "reactstrap";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useAxios} from "../../hooks";
import {toast} from "react-toastify";

const AttachmentForm = ({show, idProperty, toggle, close, title = "", id = "", submitText = "", data, setData, refreshData}) => {
    const {axios} = useAxios();
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleCheck = (e, itemId) => {
        setData([...data.map(item => {
            return {
                ...item,
                checked: item.id === itemId ? e.target.checked : item.checked
            }
        })]);
    };

    const submit = () => {
        const checkData = data.filter(item => item.checked);
        const sendData = {[id === "attach" ? "attachments" : "dettachments"]: checkData.map(item => item.id)};

        if (sendData[id === "attach" ? "attachments" : "dettachments"].length) {
            setSubmitting(true);
            axios[id === "attach" ? "post" : "put"](`api/properties/${idProperty}/attachments`, sendData)
                .then(response => {
                    if (errorMessage.length) {
                        setErrorMessage("");
                    }
                    toast.success("Succès de l'opération");
                    toggle()
                    refreshData(checkData.map(item => {
                        return {
                            ...item,
                            checked: false
                        }
                    }));
                })
                .catch(error => {
                    toast.error("Echec de l'opération");
                    if (error.response.status === 422) {
                        setErrorMessage(error.response.data.error[id === "attach" ? "attachments" : "dettachments"][0]);
                    }
                })
                .finally(() => setSubmitting(false))
            ;
        } else {
            toast.error("Veillez sélectionner des éléments");
        }
    };

    return (
        <Modal
            id={id}
            isOpen={show}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                {title}
            </ModalHeader>

            <ModalBody>
                <div className="table-responsive">
                    {errorMessage.length > 0 && (
                        <div className="alert alert-danger" role="alert">
                            {errorMessage}
                        </div>
                    )}

                    <Table className="align-middle table-nowrap mb-0">
                        <thead>
                        <tr>
                            <th scope="col">Numero Série</th>
                            <th scope="col">Référence</th>
                            <th scope="col">Code Immo</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <th scope="row"><Link to="#" className="fw-medium">{item?.serial_number}</Link></th>
                                <td>{item?.reference}</td>
                                <td>{item?.code_immo}</td>
                                <td>
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id={item.id}
                                        checked={item.checked}
                                        onChange={e => handleCheck(e, item.id)}
                                    />
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                </div>
            </ModalBody>

            <ModalFooter>
                <Button
                    color="light"
                    onClick={close}
                >
                    Annuler
                </Button>

                <button
                    type="button"
                    className="btn btn-primary btn-label right nexttab"
                    onClick={submit}
                    disabled={submitting}
                >
                    {submitting && (
                        <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                    )}
                    {" "}
                    {submitText}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default AttachmentForm;
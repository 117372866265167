import React from "react";
import DatatablePage from "../DatatablePage";

const Datatable = () => {

    return (
        <DatatablePage formWidth={"xl"}>
            Nothing
        </DatatablePage>
    );
};

export default Datatable;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useAxios, useFetchDataList} from "../../hooks";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";
import {toast} from "react-toastify";

const Roles = () => {
    document.title = "Rôles | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Nom",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Description",
                accessor: "label",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            }
        ]
    }, []);

    const {axios} = useAxios();
    const [permissions, setPermissions] = useState([]);
    const [loadEdit, setLoadEdit] = useState(false);
    const {list, loading} = useFetchDataList(`/api/roles/create`);

    const entityForm = useMemo(() => {
        return {
            title: {
                store: "Ajouter un rôle",
                update: "Modifier un rôle",
            },
            formDefaultError: {name: [], label: [], permissions: []},
            initialValues: {name: '', label: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                label: Yup.string().required("Veuillez entrer la description"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    permissions: permissions
                };
            },
            formatResponse: data => {
                return {
                    ...data,
                    permissions: permissions
                };
            },
            onSuccess: (data, type) => {
                setPermissions([]);
            },
            onFail: (response, type) => {},
        };
    }, [permissions]);

    const onUpdateEntity = useCallback((editData) => {
        console.log("editData:", editData);
        if (editData.id) {
            setLoadEdit(true);
            axios.get(`/api/roles/${editData.id}/edit`)
                .then(response => {
                    if (response.data.role?.permissions) {
                        setPermissions(response.data.role.permissions.map(item => item.name));
                    }
                })
                .catch(error => {
                    console.log("error:", error.response.data);
                    console.log("type:", typeof error.response.data.error);
                    if (typeof error.response.data.error === "string") {
                        toast.error(error.response.data.error);
                    } else {
                        toast.error(GLOBAL_ERROR_MESSAGE);
                    }
                })
                .finally(() => setLoadEdit(false))
            ;
        }
    }, []);

    const onAddEntity = useCallback(() => {
        setPermissions([]);
    }, []);

    console.log("permissions:", permissions);

    return (
        <PageContent title={"Rôles"} pageTitle={"Configuration"}>
            <DatatablePageProvider
                title={""}
                endpoint={"/api/roles"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-role"}
                updatePermission={"edit-role"}
                deletePermission={"destroy-role"}
                actionIf={{
                    update: {
                        key: "is_editable",
                        value: 1
                    },
                    delete: {
                        key: "is_editable",
                        value: 1
                    }
                }}
            >
                <Datatable
                    permissions={permissions}
                    setPermissions={setPermissions}
                    allPermissions={list}
                    loading={loading}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Roles;
import {Col, Form, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";

const UpdatePassword = () => {
    return (
        <Form>
            <Row className="g-2">
                <Col lg={4}>
                    <div>
                        <Label htmlFor="oldpasswordInput" className="form-label">Ancien mot de passe*</Label>
                        <Input
                            type="password"
                            className="form-control"
                            id="oldpasswordInput"
                            placeholder="Entrer le mot de passe actuel"
                        />
                    </div>
                </Col>

                <Col lg={4}>
                    <div>
                        <Label htmlFor="newpasswordInput" className="form-label">Nouveau mot de passe*</Label>
                        <Input
                            type="password"
                            className="form-control"
                            id="newpasswordInput"
                            placeholder="Entrez un nouveau mot de passe"
                        />
                    </div>
                </Col>

                <Col lg={4}>
                    <div>
                        <Label htmlFor="confirmpasswordInput" className="form-label">Nouveau mot de passe*</Label>
                        <Input
                            type="password"
                            className="form-control"
                            id="confirmpasswordInput"
                            placeholder="Confirmez le mot de passe"
                        />
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="mb-3">
                        <Link to="#" className="link-primary text-decoration-underline">Mot de passe oublié ?</Link>
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="text-end">
                        <button type="button" className="btn btn-success">
                            Changer le mot de passe
                        </button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

export default UpdatePassword;
import React from "react";
import {Col, FormFeedback, Input, InputGroup, InputGroupText, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({direction, setDirection, loading, directions, relationLabel = "", relationIsRequired = true, propertyRelation = [], otherPropertyPlaceholder = "", update = false}) => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={6}>
                {
                    relationLabel === 'Genre' ?

                        <>
                            <div id="modal-id">
                                <Label htmlFor="code" className="form-label">Code <InputRequire /> </Label>
                                <InputGroup>
                                    <InputGroupText>
                                        {direction?.code}
                                    </InputGroupText>
                                    <Input
                                        id="code"
                                        name="code"
                                        className="form-control"
                                        placeholder="Veuillez entrer le code"
                                        type="text"
                                        validate={{
                                            required: { value: true },
                                        }}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={ validation.values.code || ""}
                                        invalid={
                                            !!((validation.touched.code && validation.errors.code) || formError.code.length)
                                        }
                                    />
                                    {(validation.touched.code && validation.errors.code) || formError.code.length ? (
                                        <FormFeedback type="invalid">
                                            {formError.code.length ? formError.code[0] : validation.errors.code}
                                        </FormFeedback>
                                    ) : null}
                                </InputGroup>
                            </div>
                        </>
                        :
                        <>
                            <div id="modal-id">
                                <Label htmlFor="code" className="form-label">Code <InputRequire /> </Label>
                                <Input
                                    id="code"
                                    name="code"
                                    className="form-control"
                                    placeholder="Veuillez entrer le code"
                                    type="text"
                                    validate={{
                                        required: { value: true },
                                    }}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.code || ""}
                                    invalid={
                                        !!((validation.touched.code && validation.errors.code) || formError.code.length)
                                    }
                                />
                                {(validation.touched.code && validation.errors.code) || formError.code.length ? (
                                    <FormFeedback type="invalid">
                                        {formError.code.length ? formError.code[0] : validation.errors.code}
                                    </FormFeedback>
                                ) : null}
                            </div></>
                }

            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Dénomination <InputRequire /> </Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer la dénomination"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="relation" className="form-label">{relationLabel} { relationIsRequired && <InputRequire/> }</Label>
                    <ReactSelect
                        id="relation"
                        name="relation"
                        placeholder={otherPropertyPlaceholder}
                        value={direction}
                        onChange={value => setDirection(value)}
                        options={directions}
                        isLoading={loading}
                        error={formError[propertyRelation]}
                    />
                </div>
            </Col>

        </DatatablePage>
    );
};

export default Datatable;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";

const Directions = ({endpoint = "", title = "", tableTitle = "", form = null, otherPropertyTitle = "", otherPropertyPlaceholder = "", pageTitle = "", storePermission = "", updatePermission = "", deletePermission = "", relationIsRequired = true}) => {
    document.title = title+" | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Code",
                accessor: "accounting_account",
                filterable: false,
                Cell: (cellProps) => {
                    return <span>{cellProps.row.original.accounting_account || cellProps.row.original.code}</span>;
                },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: otherPropertyTitle,
                accessor: `${form.otherProperty.replace('_id', '')}.name`,
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, [form.otherProperty, otherPropertyTitle]);

    const [direction, setDirection] = useState(null);
    const [update, setUpdate] = useState(false);
    const {list, setList, loading} = useFetchDataList(`${endpoint}/create`, (data) => {
        return data.map(item => {
            return {
                label: item.name,
                value: item.id,
                code : item?.code
            }
        });
    });

    const entityForm = useMemo(() => {
        return {
            title: form.title,
            formDefaultError: {name: [], code: [], [form.otherProperty]: []},
            initialValues: {name: '', code: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    [form.otherProperty]: direction ? direction.value : ''
                };
            },
            formatResponse: data => {
                let result = list.filter(item => item.value === data[form.otherProperty]);
                result = result.length ? result[0] : null;
                return {
                    ...data,
                    [form.otherProperty.replace('_id', '')]: {
                        name: result ? result.label : ''
                    }
                };
            },
            onSuccess: (data, type) => {
                setDirection(null);
                if (endpoint === "/api/directions") {
                    setList([
                        ...list,
                        {
                            value: data.id,
                            label: data.name
                        }
                    ]);
                }
            },
            onFail: (response, type) => {},
        };
    }, [direction, endpoint, form.otherProperty, form.title, list, setList]);

    const onUpdateEntity = useCallback((editData) => {
        let result = list.filter(item => item.value === editData[form.otherProperty]);
        result = result.length ? result[0] : null;
        if (result) {
            setDirection(result);
        } else {
            setDirection(null);
        }
        setUpdate(true)
    }, [form.otherProperty, list]);

    const onAddEntity = useCallback(() => {
        setDirection(null);
    }, []);

    return (
        <PageContent title={title} pageTitle={pageTitle}>
            <DatatablePageProvider
                title={tableTitle}
                endpoint={endpoint}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={storePermission}
                updatePermission={updatePermission}
                deletePermission={deletePermission}
            >
                <Datatable
                    direction={direction}
                    setDirection={setDirection}
                    update={update}
                    loading={loading}
                    directions={list}
                    relationLabel={otherPropertyTitle}
                    relationIsRequired={relationIsRequired}
                    otherPropertyPlaceholder={otherPropertyPlaceholder}
                    propertyRelation={form.otherProperty}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Directions;
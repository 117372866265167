import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../providers/AuthProvider";
import {AxiosContext} from "../providers/AxiosProvider";
import {DatatablePageContext} from "../providers/DatatablePageProvider";
import {toast} from "react-toastify";

const useAuth = () => {
    return useContext(AuthContext)
};

const useAxios = () => {
    return useContext(AxiosContext);
};

const useDatatablePage = () => {
    return useContext(DatatablePageContext);
}

const useFetchDataList = (endpoint = "", formatResponseCallback = null) => {
    const {axios} = useAxios();
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        setLoading(true);
        axios.get(endpoint)
            .then(response => {
                setList(formatResponseCallback ? formatResponseCallback(response.data) : response.data);
            })
            .catch(error => {
                const errorMessage = error?.response?.data?.error;
                const condition = errorMessage && typeof errorMessage === "string";
                toast.error(condition ? errorMessage : "Something is wrong");
            })
            .finally(() => setLoading(false))
        ;
    }, [axios, endpoint]);

    return {list, loading, setList};
}

export {useAuth, useAxios, useDatatablePage, useFetchDataList}
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import React, {useEffect, useState} from "react";
import {useAxios, useFetchDataList} from "../../hooks";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import ReactSelect from "../../Components/Common/ReactSelect";
import {InputRequire} from "../../Components/personal/utils";
import {formatDatInput, removeNullValue} from "../../helpers";
import PropertySearch from "../../Components/personal/PropertySearch";

const TransfertForm = ({show, toggle, close}) => {
    const {axios} = useAxios();
    const {id} = useParams();

    const [submitting, setSubmitting] = useState(false);
    const defaultError = {
        assignment_date: [],
        managementable: [],
        service_id: [],
        staff_id: [],
        local_id: [],
        active: [],
    };
    const [submitError, setSubmitError] = useState(defaultError);
    const [local, setLocal] = useState(null);
    const [building, setBuilding] = useState(null);
    const [level, setLevel] = useState(null);
    const [property, setProperty] = useState(null);
    const [staff, setStaff] = useState(null);
    const [managementable, setManagementable] = useState("Service");
    const [manage, setManage] = useState(null);
    const [assignmentDate, setAssignmentDate] = useState("");
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState({
        locals: [],
        levels: [],
        batiments: [],
        directions: [],
        services: [],
        staffs: []
    });

    const submit = () => {
        const sendData = {
            local_id: local ? local.value : "",
            assignment_date: assignmentDate,
            managementable: managementable.toLowerCase(),
            service_id: manage ? manage.value : manage,
            staff_id: staff ? staff.value : staff,
        };

        if (!sendData.local_id) {
            delete sendData.local_id;
        }

        if (property) {
            setSubmitting(true);
            axios.post(`/api/properties/${property.value}/transferts`, removeNullValue(sendData))
                .then(response => {
                    toast.success("Succès de l'opération");
                    setSubmitError(defaultError);
                    close()

                })
                .catch(error => {
                    toast.error("Echec de l'opération")
                    if (error.response.status === 422) {
                        setSubmitError({...defaultError, ...error.response.data?.errors});
                    }
                })
                .finally(() => setSubmitting(false))
            ;
        } else {
            toast.error("Veuillez sélectionner un bien");
        }
    };

    const handlePropertyChange = (value) => {
        setProperty(value);
        setLocal(null);
        setStaff(null);
        setManage(null);
        if (value) {
            setLoading(true);
            axios.get(`/api/properties/${value.value}/transferts/create`)
                .then(({data}) => {
                    const formatList = (array) => {
                        return array.map(item => {
                            return {
                                value: item.id,
                                label: item.name
                            };
                        });
                    };

                    setList({
                        buildings: formatList(data.batiments),
                        locals:  data.locals.map(item => {
                            return {
                                value: item.id,
                                label: item.name,
                                batiment_id : item.batiment_id,
                                level_id : item.level_id,
                            }}),
                        levels: formatList(data.levels),
                        directions: formatList(data.directions),
                        services: formatList(data.services),
                        staffs: data.staff.map(item => {
                            return {
                                value: item.id,
                                label: item.first_name+" "+item.last_name
                            }
                        })
                    });
                })
                .catch(() => {})
                .finally(() => {setLoading(false)})
            ;
        }
    };

    return (
        <Modal
            size={"lg"}
            id="transfer-form"
            isOpen={show}
            toggle={toggle}
            backdrop={false}
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                Transférer le bien
            </ModalHeader>

            <ModalBody>
                <Row className={"g-2"}>
                    <Col lg={12} className="mb-3">
                        <Label htmlFor="manage" className="form-label">Bien <InputRequire/></Label>

                        <PropertySearch
                            type="present"
                            value={property}
                            onChange={handlePropertyChange}
                            error={[]}
                        />
                    </Col>
                    <Col sm={12} className="mb-3">
                        <Label htmlFor="date_affectation" className="form-label">Date de transfert <InputRequire/></Label>
                        <Input
                            type="date"
                            className="form-control"
                            id="date_affectation"
                            placeholder="Veuillez entrer la date d'affectation du bien"
                            validate={{
                                required: { value: submitError.assignment_date.length > 0 },
                            }}
                            value={assignmentDate}
                            onChange={e => setAssignmentDate(e.target.value)}
                            invalid={submitError.assignment_date.length > 0}
                        />
                        {submitError.assignment_date.length > 0 && (
                            <FormFeedback type="invalid">
                                {submitError.assignment_date[0]}
                            </FormFeedback>
                        ) }
                    </Col>
                    <Col md={12}>
                        <Label htmlFor="manage" className="form-label">Direction/Service <InputRequire/></Label>
                        <ReactSelect
                            id="manage"
                            name="manage"
                            placeholder={`Veuillez selectionner ${managementable === "Service" ? ' le service' : " la direction"}`}
                            value={manage}
                            onChange={value => setManage(value)}
                            options={(managementable && `${managementable}s`.toLowerCase() in list) ? list[`${managementable}s`.toLowerCase()] : []}
                            isLoading={loading}
                            error={[submitError.service_id]}
                        />
                    </Col>
                    <Col md={6}>
                        <Label htmlFor="staff_id" className="form-label">Le nouvel agent</Label>
                        <ReactSelect
                            id="staff_id"
                            name="staff_id"
                            placeholder="Veuillez sélectionner le nouvel agent"
                            value={staff}
                            onChange={value => setStaff(value)}
                            options={list.staffs}
                            isLoading={loading}
                            error={submitError.staff_id}
                        />
                    </Col>
                    <Col sm={6}>
                        <Label htmlFor="building_id" className="form-label">Bâtiment</Label>
                        <ReactSelect
                            id="building_id"
                            name="building_id"
                            placeholder="Veuillez sélectionner le batiment"
                            value={building}
                            onChange={value => { setBuilding(value); setLocal(null)}}
                            options={list.buildings}
                            isLoading={loading}
                        />
                    </Col>
                    <Col sm={6}>
                        <Label htmlFor="level_id" className="form-label">Niveaux</Label>
                        <ReactSelect
                            id="level_id"
                            name="level_id"
                            placeholder="Veuillez sélectionner le niveau"
                            value={level}
                            onChange={value => { setLevel(value); setLocal(null)}}
                            options={list.levels}
                            isLoading={loading}
                        />
                    </Col>
                    <Col md={6}>
                        <Label htmlFor="local_id" className="form-label">Nouveau local <InputRequire/></Label>
                        <ReactSelect
                            id="local_id"
                            name="local_id"
                            placeholder="Veuillez sélectionner le nouveau local"
                            value={local}
                            onChange={value => setLocal(value)}
                            options={list.locals?.filter((item) => item.batiment_id === building?.value && item.level_id === level?.value)}
                            isLoading={loading}
                            error={submitError.local_id}
                        />
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button type="button" color="light" onClick={close}>Annuler</Button>
                <Button type="button" color="primary" onClick={submit}>
                    {submitting && (
                        <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                    )}
                    {" "}
                    Transférer
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default TransfertForm;
import React from "react";
import {
    Badge
} from "reactstrap";
import {formatDate} from "../../helpers";

export const Text = (cell) => {
    return (
        <>
            {
                cell.row.original.code_format ? cell.row.original.code_format:cell.value || "-"
            }
        </>
    );
};

export const Ecart = (cell) => {
    return (
        <>
            {cell.value ?
                <>
                    {cell.row.original.status === "validated" ?
                        <Badge  color="primary">écart validé</Badge>
                        :
                        <Badge color={'danger'}>Oui</Badge>
                    }
                </>
                :
                <Badge color="primary"> Non </Badge>
            }
        </>
    )
}

export const StaffCol = (cell) => {
    return (
        <>
            {cell.value && cell.value.first_name && cell.value.last_name ? cell.value.first_name+" "+cell.value.last_name : "-"}
        </>
    );
};

export const CodeQR = (cell) => {
    return (
        <>
            {cell.value ? (
                <img src={cell.value} alt="" width={50} height={50} className="img-fluid rounded" />
            ) : "-"}
        </>
    );
};

export const FullName = (cell) => {
    return (
        <>
            {cell.value !== null ? `${cell.value.last_name} ${cell.value.first_name}` : '-'}
        </>
    );
};

export const Roles = (cell) => {
    return (
        <>
            {(cell.value !== undefined && cell.value !== null && cell.value instanceof Array) && cell.value.map((item, index) => `${item.name}${index + 1 !== cell.value.length ? ', ' : ''}`)}
        </>
    );
};

export const Date = (cell) => {
    return (
        <>
            {cell.value ? formatDate(cell.value) : "-"}
        </>
    );
};

export const Status = (cell) => {
    const printStatus = () => {
        switch (cell.value) {
            case "re_open":
                return (
                    <Badge color="warning"> Réouvert </Badge>
                );
                case "pending":
                return (
                    <Badge color="warning"> En cours </Badge>
                );
            case "closed":
                return (
                    <Badge color="success"> Clôturé </Badge>
                );
            case "not_started":
                return (
                    <Badge color="secondary"> En attente </Badge>
                );
            default:
                return "-";
        }
    }


    return printStatus();
};
import React, {useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";

const Config = () => {
    document.title = "Paramétrages | "+env('NAME');

    const [charLimit, setCharLimit] = useState("");
    const [codeFormat, setCodeFormat] = useState("");

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Nom",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);

    const entityForm = useMemo(() => {
        return {
            formDefaultError: {char_limit: [], code_format: []},
            initialValues: {char_limit: '', code_format: ''},
            validationSchema: Yup.object({}),
            formatFormData: () => {
                return {
                    data: {
                        char_limit: charLimit,
                        code_format: codeFormat
                    }
                }
            },
            onSuccess: (response, type) => {},
            onFail: (response, type) => {},
        };
    }, [charLimit, codeFormat]);

    const onUpdateEntity = (editEntity) => {
        setCharLimit(editEntity.data.char_limit);
        setCodeFormat(editEntity.data.code_format);
    };

    return (
        <PageContent title={"Configuration"} pageTitle={"Configuration"}>
            <DatatablePageProvider
                title={"Configuration"}
                endpoint={"/api/configs"}
                onUpdateEntity={onUpdateEntity}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                updatePermission={"edit-config"}
            >
                <Datatable
                    codeFormat={codeFormat}
                    setCodeFormat={setCodeFormat}
                    charLimit={charLimit}
                    setCharLimit={setCharLimit}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Config;
import React, {useEffect, useState} from "react";
import {
    Alert,
    Button,
    Col,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
    Table
} from "reactstrap";
import {InputRequire} from "../../Components/personal/utils";
import ReactSelect from "../../Components/Common/ReactSelect";
import {useAxios} from "../../hooks";
import {toast} from "react-toastify";

const InventoristForm = ({toggle, open, inventory, loading, inventoristData, inventorist,refresh}) => {
    const {axios} = useAxios();
    const defaultError = {
        user_id: [],
        local_id: [],
        inventory_id: [],
        site_id: []
    };
    const [site, setSite] = useState(null);
    const [local, setLocal] = useState([]);
    const [locals, setLocals] = useState([]);
    const [batiments, setBatiments] = useState([]);
    const [building, setBuilding] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const [user, setUser] = useState(null);
    const [sending, setSending] = useState(false);
    const [formError, setFormError] = useState({
        user_id : [],
        site_id : [],
        batiment_id : [],
        locals : [],
        inventory_id : [],
    });

    useEffect(() => {
        if (inventorist){
            setUser({label : inventorist?.user.name,value : inventorist?.user.id})
            let site = inventoristData?.sites?.find((sit) => sit.id === inventorist?.site_id);
            setSite({value: site?.id, label : site?.name})
            setBuildings(inventoristData?.batiments.filter((b) => b.site_id === site.id));

            setBatiments(inventorist?.batiments.map((item) => item.id))
            setLocals(inventorist?.locals.map((item) => item.id))
        }

    }, [inventory]);

    const submitForm = () => {
        const sendingData = {
            user_id: user ? user.value : null,
            locals: locals,
            site_id: site ? site.value : null,
            batiments: batiments,
            inventory_id: inventory?.id,
        };

        setSending(true);

        axios.post(`api/inventorist-locals`, sendingData)
            .then(() => {
                toast.success("Inventoriste configuré avec succès");
                toggle()
                window.location.reload()
            })
            .catch((error) => {
                if (error?.response?.status === 422){
                    setFormError(error?.response.data.errors)
                }
            })
            .finally(() => {
                setSending(false);
            })
        ;
    }
    const handleSiteChange = (newSite) => {
        if (building?.value) {
            setBuilding(null);
        }
        setBuildings(inventoristData?.batiments.filter((b) => b.site_id === newSite.value));

        if (locals?.length) {
            setLocals([]);
        }

        setSite(newSite);

    };
    function formatData (data) {
        return data?.map(item => {
            return {
                ...item,
                label: item.name || item.fullName,
                value: item.id,
            }
        });
    }
    function handleBuildingChange (newBuilding) {
       let bats = batiments;
        if (batiments?.includes(newBuilding.target.value)){
            bats = batiments.filter((bat) => bat !== newBuilding.target.value)
        }else{
            bats = [...batiments, ...[newBuilding.target.value]]
        }
        setBatiments(bats)
    }
    function handleLocalChange (input) {
       let locs = [];
        if (locals?.includes(input.target.value)){
            locs = locals.filter((loc) => loc !== input.target.value)
        }else{
            locs = [...locals, ...[input.target.value]]
        }
        setLocals(locs)
    }
    const selectAllLocals = (batimentId) => {
    let batimentLocals = inventoristData?.locals?.filter((b) => b.batiment_id === batimentId)?.map((l) => l.id);
    let updateLocals = [];
        batimentLocals?.forEach((item) => {
           if (locals?.includes(item)){
               setLocals(locals?.filter((b) => b !== item))
           }else{
               updateLocals.push(item)
           }
       })

        setLocals([...locals,...updateLocals])
    }
    return (
        <Modal id="Inventorist" isOpen={open} centered toggle={toggle} fullscreen={true} className="border-0" modalClassName="zoomIn">
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                Nouvel inventoriste
            </ModalHeader>

            <ModalBody>
                <div className={'container'}>
                    <Row className={'g-3'}>
                        <Col lg={6}>
                            <div id="modal-id">
                                <Label htmlFor="inventoriste" className="form-label">Inventoriste</Label>
                                <ReactSelect
                                    id="inventoriste"
                                    name="inventoriste"
                                    placeholder="Veuillez sélectionner l'inventoriste"
                                    value={user}
                                    onChange={value => setUser(value)}
                                    options={formatData(inventoristData?.users) || []}
                                    isLoading={loading}
                                    error={formError?.user_id?.[0]}
                                />
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div id="modal-id">
                                <Label htmlFor="site" className="form-label">Site</Label>
                                <ReactSelect
                                    id="site"
                                    name="site"
                                    placeholder="Veuillez sélectionner le site"
                                    value={site}
                                    onChange={handleSiteChange}
                                    options={formatData(inventoristData?.sites) || []}
                                    isLoading={loading}
                                    error={formError?.site_id?.[0]}
                                />
                            </div>
                        </Col>

                        <Col lg={12}>
                            <div id="modal-id">

                                {
                                    buildings?.map((item) => (
                                        <Row key={item.id}>
                                            <Label
                                                className="form-check-label"
                                                htmlFor={item.id}>
                                                <Input
                                                    id={item.id}
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    value={item.id}
                                                    checked={batiments.includes(item.id)}
                                                    onChange={handleBuildingChange}/>&nbsp;
                                                <strong style={{fontSize : '1.2em'}}>{item.name}</strong>
                                            </Label>
                                            {
                                                batiments?.length > 0 &&
                                                <Row className={"text-center"}>
                                                    <Label
                                                        className="form-check-label"
                                                        htmlFor="select-all">
                                                        <Input
                                                            id="select-all"
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            value={"select-all"}
                                                            onChange={() => selectAllLocals(item.id)}/>&nbsp;
                                                        <span>Tout cocher/décocher</span>
                                                    </Label>
                                                </Row>


                                            }
                                            {
                                                batiments.includes(item.id) && inventoristData?.locals?.filter(loc => loc.batiment_id === item.id)?.length === 0 &&
                                                <Row className={'text-center text-muted'}>
                                                    Ce batiment ne dispose pas de bureaux ou tout les bureaux ont été déjà assignés.
                                                </Row>
                                            }
                                            <Row className={"m-4"}>
                                                {
                                                    batiments.includes(item.id) && inventoristData?.locals?.filter(loc => loc.batiment_id === item.id)?.map((item2,index) => (
                                                   <div key={item2.id}>
                                                       {
                                                           !inventorist ?
                                                               <>
                                                                   {
                                                                           !inventoristData.locals_assigneds?.map((lc) => lc.id).includes(item2.id)  &&
                                                                       <Col md={6} key={item2.id}>
                                                                           <Label
                                                                               className="form-check-label"
                                                                               htmlFor={item2.id}>
                                                                               <Input
                                                                                   id={item2.id}
                                                                                   type="checkbox"
                                                                                   className="form-check-input"
                                                                                   value={item2.id}
                                                                                   checked={locals?.includes(item2.id)}
                                                                                   onChange={handleLocalChange }
                                                                               />&nbsp;
                                                                               <span>{item2.name}</span>
                                                                           </Label>
                                                                       </Col>
                                                                   }
                                                               </> : <>
                                                                   <Col md={6} key={item2.id}>
                                                                       <Label
                                                                           className="form-check-label"
                                                                           htmlFor={item2.id}>
                                                                           <Input
                                                                               id={item2.id}
                                                                               type="checkbox"
                                                                               className="form-check-input"
                                                                               value={item2.id}
                                                                               checked={locals?.includes(item2.id)}
                                                                               onChange={handleLocalChange }/>&nbsp;
                                                                           <span>{item2.name}</span>
                                                                       </Label>
                                                                       <div className={'text-danger'}>{formError?.[`locals.${index}`]?.[0]}</div>
                                                                   </Col>
                                                               </>
                                                       }
                                                   </div>
                                                    ))
                                                }
                                            </Row>
                                        </Row>
                                    ))
                                }
                                <div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </ModalBody>

            <ModalFooter>


                <Button color="light" onClick={toggle}>
                    Annuler
                </Button>
                <button disabled={locals?.length === 0} type="submit" className="btn btn-success" id="add-btn" onClick={submitForm}>
                    <span className="d-flex align-items-center justify-content-center">
                        {sending && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                        )}
                        <span className="ms-2">{sending ? "Chargement" : "Enregistrer"}</span>
                    </span>
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default InventoristForm;
import React, { useState, useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Label,
    Input,
    FormFeedback,
    Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import avatar from "../../assets/images/users/user-dummy-img.jpg";
import {toast} from "react-toastify";
import {useAuth, useAxios} from "../../hooks";
// actions

const UserProfile = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [first_name, setFirstName] = useState('');
    const [last_name, setLastName] = useState('');
    const [address, setAddress] = useState('');
    const [telephone, setTelephone] = useState('');
    const [new_password, setNewPassword] = useState("********");
    const [current_password, setCurrentPassword] = useState("********");
    const defaultError = {
        current_password : '',
        new_password : '',
        first_name : '',
        last_name : '',
        email : '',
        telephone : ''}

        const [formError, setFormError] = useState(defaultError);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const {axios} = useAxios();
    const auth = useAuth();
    const [inputType, setInputType] = useState("password");


    useEffect(() => {
        if (localStorage.getItem("user")) {
            const user = JSON.parse(localStorage.getItem("user"));
            setUserData(user)

            setFirstName(user.staff?.first_name);
            setLastName(user.staff?.last_name);
            setEmail(user?.email);
            setTelephone(user.staff?.telephone);

        }
    }, []);

    const updateInfos = () => {
        let data = {
          last_name : last_name,
          first_name : first_name,
          email : email,
          telephone : telephone
        }
        setLoading(true)
        axios.put(`/api/profil/update-staff`,data)
            .then(({data}) => {
                toast.success("Profile mis à jour avec succès")
                setFormError(defaultError)

                axios.get('/api/login')
                    .then(response => {
                        const user = {
                           ...userData,
                            ...response.data.user,
                        };
                       localStorage.setItem('user',JSON.stringify(user));
                    })
                    .catch(error => {})
                    .finally(() => setLoading(false))
            })
            .catch((error) => {
                switch (error.response.status)
                {
                    case 422 :
                        setFormError({...formError,...error.response.data.error})
                        break;
                    case 500:
                        toast.error("Oups! Quelque chose s'est mal passée")
                        break;
                }
            })
            .finally(() => {setLoading(false)});
    }

    const updatePassword = () => {

        let data = {
            current_password : current_password,
            new_password: new_password,
            email : email,
        }
        setLoading(true)
        axios.put(`/api/profil/change-password`,data)
            .then(() => {
                toast.success("Mot de passe mis à jour avec succès")
                let newUser = JSON.parse(localStorage.getItem('user'));
                if (!newUser.has_changed_pass){
                    newUser.has_changed_pass = true;
                    localStorage.setItem('user',JSON.stringify(newUser))
                }
                window.location.reload()
                setFormError(defaultError)
            })
            .catch((error) => {
                console.log({error})
                switch (error.response.status)
                {
                    case 422 :
                        setFormError({...formError,...error.response.data.errors})
                        break;
                    case 500:
                        toast.error("Oups! Quelque chose s'est mal passée")
                        break;
                }
            })
            .finally(() => {setLoading(false)});    }

    document.title = "Profile | DigitImmo";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <div className="d-flex">
                                        <div className="mx-3">
                                            <img
                                                src={avatar}
                                                alt=""
                                                className="avatar-md rounded-circle img-thumbnail"
                                            />
                                        </div>
                                        <div className="flex-grow-1 align-self-center">
                                            <div className="text-muted">
                                                <h5>{name}</h5>
                                                <p className="mb-1">Email  : {email}</p>
                                                <p className="mb-1">Téléphone  : {telephone}</p>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {
                        !auth?.user?.has_changed_pass && (
                            <Row className={'alert alert-danger m-1'}>Vous devez réinitialiser votre mot de passe pour contunier</Row>
                        )
                    }

                    <h4 className="card-title mb-4">Informations</h4>

                    <Card>
                        <CardBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    updateInfos();
                                    return false;
                                }}
                            >

                                <Row>
                                    <Col lg={6}>
                                        <div className="form-group">
                                            <Label className="form-label">Nom</Label>
                                            <Input
                                                name="last_name"
                                                className="form-control"
                                                placeholder="Nom"
                                                type="text"
                                                onChange={(e) => setLastName(e.target.value)}
                                                invalid={formError.last_name.length > 0}
                                                value={last_name}
                                            />
                                            {formError && formError?.last_name?.length ? (
                                                <FormFeedback type="invalid">
                                                    {formError.last_name.length ? formError.last_name[0] : ''}
                                                </FormFeedback>
                                            ) : null}

                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="form-group">
                                            <Label className="form-label">Prénom (s)</Label>
                                            <Input
                                                name="first_name"
                                                className="form-control"
                                                placeholder="Nom"
                                                type="text"
                                                onChange={(e) => setFirstName(e.target.value)}
                                                value={first_name}
                                                invalid={formError.first_name.length > 0}

                                            />
                                            {formError && formError?.first_name?.length ? (
                                                <FormFeedback type="invalid">
                                                    {formError.first_name.length ? formError.first_name[0] : ''}
                                                </FormFeedback>
                                            ) : null}

                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="form-group">
                                            <Label className="form-label">Email</Label>
                                            <Input
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                type="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                value={email}
                                                invalid={formError.email.length > 0}

                                            />
                                            {formError && formError?.email?.length ? (
                                                <FormFeedback type="invalid">
                                                    {formError.email.length ? formError.email[0] : ''}
                                                </FormFeedback>
                                            ) : null}

                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="form-group">
                                            <Label className="form-label">Téléphone</Label>
                                            <Input
                                                name="name"
                                                className="form-control"
                                                placeholder="Téléphone"
                                                type="telephone"
                                                onChange={(e) => setTelephone(e.target.value)}
                                                value={telephone}
                                                invalid={formError.telephone.length > 0}
                                            />
                                            {formError && formError?.telephone?.length ? (
                                                <FormFeedback type="invalid">
                                                    {formError.telephone.length ? formError.telephone[0] : ''}
                                                </FormFeedback>
                                            ) : null}

                                        </div>
                                    </Col>
                                </Row>

                                <div className="text-end mt-4">
                                    <Button disabled={loading} type="submit" color="primary">
                                        {loading? 'Traitemen en cours...': 'Mettre à jour'}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    updatePassword()
                                    return false;
                                }}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <Label htmlFor="password" className="form-label">Ancien mot de passe</Label>
                                        <div id="modal-id" className="position-relative auth-pass-inputgroup mb-3">
                                            <Input
                                                type={inputType}
                                                className="form-control pe-5"
                                                name="current_password"
                                                placeholder="Ancien mot de passe"
                                                onChange={(e) => setCurrentPassword(e.target.value) }
                                                invalid={formError.current_password.length > 0}
                                            />
                                            {formError && formError?.current_password?.length ? (
                                                <FormFeedback type="invalid">
                                                    {formError.current_password.length ? formError.current_password[0] : ''}
                                                </FormFeedback>
                                            ) : null}
                                            <button onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                        </div>
                                    </Col>
                                    <Col lg={6}>

                                        <Label htmlFor="password" className="form-label">Nouveau mot de passe</Label>
                                        <div id="modal-id" className="position-relative auth-pass-inputgroup mb-3">
                                            <Input
                                                type={inputType}
                                                className="form-control pe-5"
                                                name="new_password"
                                                placeholder="Nouveau mot de passe"
                                                onChange={(e) => setNewPassword(e.target.value) }
                                                invalid={formError.new_password.length > 0}
                                            />
                                            {formError && formError?.new_password?.length ? (
                                                <FormFeedback type="invalid">
                                                    {formError.new_password.length ? formError.new_password[0] : ''}
                                                </FormFeedback>
                                            ) : null}
                                            <button onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-end mt-4">
                                    <Button disabled={loading} type="submit" color="primary">
                                        {loading? 'Traitemen en cours...': 'Mettre à jour'}
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default UserProfile;

import React from "react";
import { Redirect } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

// Error
import Error404 from "../pages/error/Error404";
import Error401 from "../pages/error/Error401";
import Error403 from "../pages/error/Error403";
import Error500 from "../pages/error/Error500";

// Dashboard
import Dashboard from "../pages/dashboard";

// Cities
import Cities from "../pages/Cities";

// Sites
import Sites from "../pages/sites";

// Society
import Society from "../pages/society";

// Position, Property condition, Subvention and Gender Page
import Positions from "../pages/positions";

// Level Page
import Levels from "../pages/levels";

// Direction Page
import Direction from "../pages/directions";

// Locales Page
import Locales from "../pages/locales";

// Suppliers Page
import Suppliers from "../pages/suppliers";

// Standards Page
import Standards from "../pages/standards";

// Standards Page
import Staffs from "../pages/staffs";

// Users Pages
import Users from "../pages/users";

// Roles Pages
import Roles from "../pages/roles";

// Profile Pages
import Profile from "../pages/profile";
import Marks from "../pages/marks";
import AccountExercises from "../pages/AccountExercises";
import Config from "../pages/config";
import Properties from "../pages/properties";
import PropertiesForm from "../pages/properties/PropertiesForm";
import DetailProperty from "../pages/properties/DetailProperty";
import Inventories from "../pages/inventories";
import PropertiesInventory from "../pages/properties-inventory";
import Inventoriste from "../pages/inventoriste";
import ResetPassword from "../pages/Authentication/ResetPassword";
import Mouvements from "../pages/mouvements";
import Output from "../pages/outs";
import HistoryInventories from "../pages/history-inventories";
import Labeling from "../pages/labeling";
import LocalsLabeling from "../pages/locals-labeling";
import SubStandards from "../pages/sub-standards";
import Reporting from "../pages/reporting";
import InventoryConfig from "../pages/inventory-config";

const authProtectedRoutes = [
    //User Profile
    { path: "/profile", component: (<UserProfile/>) },

    // this route should be at the end of all other routes
    // Dashboard Page
    {path: "/dashboard", component: (<Dashboard/>)},
   //reporting
    {path: "/reporting", component: (<Reporting/>)},

    // Cities Page
    {path: "/cities", component: (<Cities/>)},

    // Suppliers Page
    {path: "/suppliers", component: (<Suppliers/>)},

    // Standards Page
    {path: "/standards", component: (<Standards/>)},
    {path: "/sub-standards", component: (<SubStandards/>)},

    // Staffs Page
    {path: "/staffs", component: (<Staffs/>)},

    // Users Page
    {path: "/users", component: (<Users/>)},

    // Inventoriste Page
    {path: "/inventoriste", component: (<Inventoriste/>)},

    // Roles Page
    {path: "/roles", component: (<Roles/>)},

    // Inventories Page
    {path: "/inventories/:id", component: (<PropertiesInventory/>)},
    {path: "/inventories", component: (<Inventories/>)},
    {path: "/inventory-config/:id/:userId?", component: (<InventoryConfig/>)},
    

    // History inventories
    {path: "/history-inventories", component: (<HistoryInventories/>)},

    // Mouvement Page
    {path: "/movements", component: (<Mouvements/>)},
   //inventory config

    // Directions Page
    /*{path: "/directions", component: (
        <Direction
            title={"Directions"}
            tableTitle={""}
            pageTitle={"Personnel"}
            endpoint={"/api/directions"}
            storePermission={"store-direction"}
            updatePermission={"edit-direction"}
            deletePermission={"destroy-direction"}
            otherPropertyTitle={"Direction parent"}
            otherPropertyPlaceholder={"Veuillez sélectionner la direction parent"}
            form={{
                title: {
                    store: "Ajouter une direction",
                    update: "Modifier une direction"
                },
                otherProperty: 'parent_id',
            }}
        />
    )},*/

    // Services Page
    {path: "/services", component: (
        <Direction
            title={"Organigramme"}
            tableTitle={""}
            pageTitle={"Personnel"}
            endpoint={"/api/services"}
            storePermission={"store-service"}
            updatePermission={"edit-service"}
            deletePermission={"destroy-service"}
            otherPropertyTitle={"Sous tutelle"}
            otherPropertyPlaceholder={"Veuillez sélectionner l'entité parente"}
            relationIsRequired={false}
            form={{
                title: {
                    store: "Ajouter un service/direction",
                    update: "Modifier un service/direction"
                },
                otherProperty: 'parent_id',
            }}
        />
    )},

    // Building Page
    {path: "/buildings", component: (
        <Direction
            title={"Batiments"}
            tableTitle={""}
            pageTitle={"Localisation"}
            endpoint={"/api/batiments"}
            storePermission={"store-batiment"}
            updatePermission={"edit-batiment"}
            deletePermission={"destroy-batiment"}
            otherPropertyTitle={"Site"}
            otherPropertyPlaceholder={"Veuillez sélectionner le site"}
            form={{
                title: {
                    store: "Ajouter un batiment",
                    update: "Modifier un batiment"
                },
                otherProperty: 'site_id',
            }}
        />
    )},

    // Building Page
    {path: "/families", component: (
        <Direction
            title={"Familles"}
            tableTitle={""}
            pageTitle={"Biens"}
            endpoint={"/api/families"}
            otherPropertyTitle={"Genre"}
            otherPropertyPlaceholder={"Veuillez sélectionner le genre"}
            storePermission={"store-family"}
            updatePermission={"edit-family"}
            deletePermission={"destroy-family"}
            form={{
                title: {
                    store: "Ajouter une famille",
                    update: "Modifier une famille"
                },
                otherProperty: 'genre_id',
            }}
        />
    )},

    // Label Page
    {path: "/properties-labeling", component: (<Labeling/>)},
    {path: "/locals-labeling", component: (<LocalsLabeling/>)},

    // Sites Page
    {path: "/sites", component: (<Sites/>)},

    // Sites Page
    {path: "/levels", component: (<Levels/>)},

    // Society Page
    {path: "/societies", component: (<Society/>)},

    // Society Page
    {path: "/profile-settings", component: (<Profile/>)},

    // Accounting exercises Page
    {path: "/accounting-exercises", component: (<AccountExercises/>)},

    // /outputs
    {path: "/outputs", component: (<Output/>)},

    // Properties Page
    {path: "/inputs/create", component: (<PropertiesForm/>)},
    {path: "/inputs/:id/edit", component: (<PropertiesForm/>)},
    {path: "/inputs/:id", component: (<DetailProperty/>)},
    {path: "/inputs", component: (<Properties/>)},

    // Marks Page
    {path: "/marks", component: (<Marks/>)},

    // Config Page
    {path: "/config", component: (<Config/>)},

    // Position Page
    {path: "/positions", component: (
        <Positions
            pageTitle={"Personnel"}
            tableTitle={""}
            endpoint={"/api/positions"}
            storePermission={"store-position"}
            updatePermission={"edit-position"}
            deletePermission={"destroy-position"}
            title={"Fonctions"}
            form={{
                store: 'Ajouter une Fonction',
                update: 'Modifier une Fonction'
            }}
        />
    )},

    // Property condition Page
    {path: "/property-condition", component: (
        <Positions
            pageTitle={"Biens"}
            tableTitle={""}
            endpoint={"/api/etats"}
            title={"Etats des biens"}
            storePermission={"store-etat"}
            updatePermission={"edit-etat"}
            deletePermission={"destroy-etat"}
            form={{
                store: 'Ajouter un état de bien',
                update: 'Modifier un état de bien'
            }}
        />
    )},

    // Gender Page
    {path: "/genders", component: (
        <Positions
            pageTitle={"Biens"}
            tableTitle={""}
            endpoint={"/api/genres"}
            title={"Genres"}
            storePermission={"store-genre"}
            updatePermission={"edit-genre"}
            deletePermission={"destroy-genre"}
            form={{
                store: 'Ajouter un genre',
                update: 'Modifier un genre'
            }}
        />
    )},

    // Subsidies Page
  /*   {path: "/subventions", component: (
        <Positions
            pageTitle={"Configuration"}
            tableTitle={""}
            endpoint={"/api/subsidies"}
            title={"Origine"}
            storePermission={"store-subsidie"}
            updatePermission={"edit-subsidie"}
            deletePermission={"destroy-subsidie"}
            form={{
                store: 'Ajouter une origine',
                update: 'Modifier une origine'
            }}
        />
    )}, */

    // Locales Page
    {path: '/locales', component: (<Locales/>)}
];

const publicRoutes = [
    // Authentication Page
    { path: "/login", component: (<Login/>) },
    { path: "/forgot-password", component: (<ForgetPasswordPage/>) },
    { path: "/reset-password", component: (<ResetPassword/>) },

];

const errorRoutes = [
    // Error Page
    { path: "/401", component: (<Error401/>) },
    { path: "/403", component: (<Error403/>) },
    { path: "/404", component: (<Error404/>) },
    { path: "/500", component: (<Error500/>) },
    { path: "/", component: () => <Redirect to="/dashboard" /> },
    { path: "*", component: (<Error404/>) },
];

export { authProtectedRoutes, publicRoutes, errorRoutes };
import {useAuth} from "../hooks";
import {Redirect, Route} from "react-router-dom";
import React from "react";

const Guest = ({children, rest}) => {
    const auth = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                !auth.user ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/dashboard",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

const Auth = ({children, rest}) => {
    const auth = useAuth();

    return (
        <Route
            {...rest}
            render={({ location }) =>
                auth.user ? (
                    !auth.user.has_changed_pass && location.pathname !== '/profile'
                        ?
                        <Redirect
                        to={{
                            pathname: "/profile",
                            state: { from: location }
                        }}
                        /> :
                        children) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
};

const GroupAuth = ({children}) => {
    const auth = useAuth();

    if (auth.user)
        return children;
    return  <Redirect to="/login" />
}

export {Guest, Auth, GroupAuth}

import env from "../../helpers/env";

import PageContent from "../../Layouts/PageContent";
import {useAxios, useFetchDataList} from "../../hooks";
//import Components
import ExportFilters from "../../Components/Exports/ExportFilters";
import React from "react";

const Reporting = () => {
    document.title ="Rapports | "+env('NAME');
    const {axios} = useAxios();


    return (
        <PageContent title={"Rapport"} pageTitle={"Tableau de bord"}>

            <ExportFilters
                fileName={'biens-presents'}
                exportApi={'/api/export/properties-present'}
                exportFilterApi={'/api/export/properties-present/create'} />
        </PageContent>
    );
};

export default Reporting;
import React from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";

const Datatable = ({charLimit, setCharLimit, codeFormat, setCodeFormat}) => {
    const {formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={12}>
                <div className="alert alert-secondary alert-dismissible alert-outline fade show" role="alert">
                    <strong> Information! </strong> - Liste des <b>paramètres</b> de configuration
                </div>
                <ol className="list-group">
                    <li className="list-group-item"><strong>{"{nature}"}:</strong> La nature du bien.</li>
                    <li className="list-group-item"><strong>{"{annee}"}:</strong> L'année d'acquisition.</li>
                    <li className="list-group-item"><strong>{"{numero}"}:</strong> Le numéro d'ordre.</li>
                    <li className="list-group-item"><strong>{"{site}"}:</strong> Le lieu géographique d'affectation.</li>
                    <li className="list-group-item"><strong>{"{structure}"}:</strong> La structure d'affectation.</li>
                    <li className="list-group-item"><strong>{"{source_financement}"}:</strong> La source de financement.</li>
                </ol>
            </Col>

            <Col lg={2}>
                <div id="modal-id">
                    <Label htmlFor="char_limit" className="form-label">Char limit</Label>
                    <Input
                        id="char_limit"
                        name="char_limit"
                        className="form-control"
                        placeholder="Veuillez entrer le char limit"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={e => setCharLimit(e.target.value)}
                        value={charLimit || ""}
                        invalid={
                            !!(formError.char_limit.length)
                        }
                    />
                    {formError.char_limit.length ? (
                        <FormFeedback type="invalid">
                            {formError.char_limit[0]}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={10}>
                <div id="modal-id">
                    <Label htmlFor="code_format" className="form-label">Code format</Label>
                    <Input
                        id="code_format"
                        name="code_format"
                        className="form-control"
                        placeholder="Veuillez entrer le code format"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={e => setCodeFormat(e.target.value)}
                        value={codeFormat || ""}
                        invalid={
                            !!(formError.code_format.length)
                        }
                    />
                    {formError.code_format.length ? (
                        <FormFeedback type="invalid">
                            {formError.code_format[0]}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
import React from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";

const Datatable = ({role, setRole, loading, roles, staff, setStaff, staffs}) => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Nom</Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="email" className="form-label">Email</Label>
                    <Input
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Veuillez entrer l'email"
                        type="email"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.email || ""}
                        invalid={
                            !!((validation.touched.email && validation.errors.email) || formError.email.length)
                        }
                    />
                    {(validation.touched.email && validation.errors.email) || formError.email.length ? (
                        <FormFeedback type="invalid">
                            {formError.email.length ? formError.email[0] : validation.errors.email}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            {(staffs !== null && staffs.length > 0) && (
                <Col lg={12}>
                    <div id="modal-id">
                        <Label htmlFor="staff_id" className="form-label">Agents</Label>
                        <ReactSelect
                            id="staff_id"
                            name="staff_id"
                            placeholder={`Veuillez sélectionner l'agent`}
                            value={staff}
                            onChange={value => setStaff(value)}
                            options={staffs}
                            isLoading={loading}
                            error={formError['staff_id']}
                        />
                    </div>
                </Col>
            )}

            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="password" className="form-label">Mot de passe</Label>
                    <Input
                        id="password"
                        name="password"
                        className="form-control"
                        placeholder="Veuillez entrer le mot de passe"
                        type="password"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        value={validation.values.password || ""}
                        invalid={
                            !!(formError.password.length)
                        }
                    />

                    {formError.password.length ? (
                        <FormFeedback type="invalid">
                            {formError.password.length ? formError.password[0] : ''}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";

const Suppliers = () => {
    document.title = "Fournisseur | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code",
                accessor: "code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Ifu",
                accessor: "ifu",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Adresse",
                accessor: "address",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Téléphone",
                accessor: "telephone",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Secteur",
                accessor: "sector",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);

    const entityForm = useMemo(() => {
        return {
            title: {
                store: "Ajouter un fournisseur",
                update: "Modifier un fournisseur",
            },
            formDefaultError: {name: [], code: [], address: [], telephone: [], sector: [],ifu : []},
            initialValues: {name: '', code: '', address: '', telephone: '', sector: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
                ifu: Yup.number('Seulement les chiffres sont autorisés'),
                address: Yup.string().required("Veuillez entrer l'adresse"),
                telephone: Yup.string().required("Veuillez entrer le téléphone"),
                sector: Yup.string().required("Veuillez entrer le secteur"),
            }),
            formatFormData: (values) => values,
            onSuccess: (response, type) => {},
            onFail: (response, type) => {},
        };
    }, []);

    return (
        <PageContent title={"Fournisseur"} pageTitle={"Biens"}>
            <DatatablePageProvider
                title={"Fournisseur"}
                endpoint={"/api/suppliers"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                storePermission={"store-supplier"}
                updatePermission={"edit-supplier"}
                deletePermission={"destroy-supplier"}
            >
                <Datatable/>
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Suppliers;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";

const Positions = ({endpoint = "", title = "", form = null, pageTitle = "", tableTitle = "", storePermission = null, updatePermission = null, deletePermission = null}) => {
    document.title = pageTitle+" | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Code",
                accessor: "code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);

    const entityForm = useMemo(() => {
        return {
            title: form,
            formDefaultError: {name: [], code: []},
            initialValues: {name: '', code: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
            }),
            formatFormData: (values) => values,
            onSuccess: (response, type) => {},
            onFail: (response, type) => {},
        };
    }, [form]);

    return (
        <PageContent title={title} pageTitle={pageTitle}>
            <DatatablePageProvider
                title={tableTitle}
                endpoint={endpoint}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                storePermission={storePermission}
                updatePermission={updatePermission}
                deletePermission={deletePermission}
            >
                <Datatable/>
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Positions;
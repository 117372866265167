import React, {useCallback, useEffect, useState} from "react";
import {Accordion, AccordionItem, Col, Collapse, FormFeedback, Input, Label} from "reactstrap";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import classnames from "classnames";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({permissions = [], setPermissions, loading, allPermissions}) => {
    const {validation, formError} = useDatatablePage();
    const [formatPermissions, setFormatPermissions] = useState([]);

    useEffect(() => {
        setFormatPermissions(allPermissions.map(item => {
            return {
                ...item,
                col: false,
                checked: false,
                permissions: item.permissions.map(item => {
                    return {
                        ...item,
                        checked: false
                    };
                }),
            };
        }));
    }, [allPermissions]);

    useEffect(() => {
        const newFormatPermissions = [...formatPermissions].map(item => {
            const module = item;
            let checkAll = true;
            module.permissions = module.permissions.map(item => {
                const checkItem = permissions.includes(item.name);
                checkAll = checkAll && checkItem;
               return {
                   ...item,
                   checked: checkItem
               }
            });
            module.checked = checkAll;
            return module;
        });
        setFormatPermissions(newFormatPermissions);
    }, [permissions]);

    const t_col1 = useCallback((index) => {
        setFormatPermissions(formatPermissions.map((item, itemIndex) => {
            if (index === itemIndex) {
                return {
                    ...item,
                    col: !item.col
                };
            }
            return {
                ...item,
                col: false
            };
        }));
    }, [formatPermissions]);

    const handleModuleCheck = (e, moduleIndex) => {
        let newPermissions = [...permissions];
        formatPermissions[moduleIndex].permissions.forEach(item => {
            if (e.target.checked) {
                // Add
                if (!newPermissions.includes(item.name)) {
                    newPermissions = [...newPermissions, item.name];
                }
            } else {
                // Remove
                if (newPermissions.includes(item.name)) {
                    newPermissions = newPermissions.filter(i => i !== item.name);
                }
            }
        });
        console.log("newPermission:", newPermissions);
        setPermissions(newPermissions);
    };

    const handleCheck = (e, path) => {
        const permission = formatPermissions[path.moduleIndex].permissions[path.index].name;
        if (e.target.checked) {
            setPermissions([...permissions, permission]);
        } else {
            setPermissions(permissions.filter(item => item !== permission));
        }
    };


    return (
        <DatatablePage formWidth={"xl"}>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Libellé <InputRequire/></Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer le libellé"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="label" className="form-label">Description <InputRequire/></Label>
                    <Input
                        id="label"
                        name="label"
                        className="form-control"
                        placeholder="Veuillez entrer la description"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.label || ""}
                        invalid={
                            !!((validation.touched.label && validation.errors.label) || formError.label.length)
                        }
                    />
                    {(validation.touched.label && validation.errors.label) || formError.label.length ? (
                        <FormFeedback type="invalid">
                            {formError.label.length ? formError.label[0] : validation.errors.label}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={12}>
                <div id="modal-id">
                    <Label htmlFor="permissions" className="form-label">Permissions</Label>

                    {formatPermissions.map((item, moduleIndex) => (
                        <Accordion key={moduleIndex} id="default-accordion-example">
                            <AccordionItem>
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className={classnames("accordion-button", { collapsed: !item.col })} type="button" onClick={() => t_col1(moduleIndex)} style={{ cursor: "pointer" }} >
                                        {item.name}

                                        <Input
                                            style={{marginLeft: "20px", marginBottom: "2px"}}
                                            onClick={e => e.stopPropagation()}
                                            className="form-check-input cursor-pointer"
                                            type="checkbox"
                                            role="switch"
                                            id={`SwitchCheck1`}
                                            checked={item.checked}
                                            onChange={e => handleModuleCheck(e, moduleIndex)}
                                        />
                                    </button>
                                </h2>

                                <Collapse isOpen={item.col} className="accordion-collapse" id="collapseOne" >
                                    <div className="accordion-body row">
                                        {item.permissions.map((item, index) => {
                                            const id = Math.random().toString(36).substring(2, 9);
                                            return (
                                                <Col key={index} lg={3}>
                                                    <div className="form-check form-switch form-switch-custom form-switch-success mb-3">
                                                        <Input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            role="switch"
                                                            id={`SwitchCheck-${id}`}
                                                            onChange={e => handleCheck(e, {moduleIndex, index})}
                                                            checked={item.checked}
                                                        />
                                                        <Label className="form-check-label" htmlFor={`SwitchCheck-${id}`}>{item.label}</Label>
                                                    </div>
                                                </Col>
                                            )
                                        })}
                                    </div>
                                </Collapse>
                            </AccordionItem>
                        </Accordion>
                    ))}

                    {formError['permissions'].length > 0 && (
                        <p style={{color: "#f06548", fontSize: "0.875em", marginTop: "0.25rem"}}>{formError['permissions']}</p>
                    )}
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
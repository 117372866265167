import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import React, {useState} from "react";
import {useAxios, useFetchDataList} from "../../hooks";
import {toast} from "react-toastify";
import ReactSelect from "../../Components/Common/ReactSelect";
import {InputRequire} from "../../Components/personal/utils";
import {removeNullValue} from "../../helpers";
import PropertySearch from "../../Components/personal/PropertySearch";

const OutputForm = ({show, toggle, close}) => {
    const {axios} = useAxios();

    const [submitting, setSubmitting] = useState(false);
    const defaultError = {
        property_id : [],
        motif : [],
        output_date : [],
        tva : [],
        transaction_amount: [],
        buyer_name: []
    };
    const [errorMessage, setErrorMessage] = useState(defaultError);
    const [motif, setMotif] = useState(null);
    const [outputDate, setOutputDate] = useState("");
    const [transactionAmount, setTransactionAmount] = useState("");
    const [tva, setTva] = useState("");
    const [buyerName, setBuyerName] = useState("");
    const [property, setProperty] = useState(null);

    const {list, loading} = useFetchDataList(`/api/properties/outputs/create`, data => {
        return {
            motifs: data.motifs.map(item => {
                return {
                    value: item,
                    label: item
                };
            }),
        };
    });

    const submit = () => {
        if (property) {
            const sendData = {
                property_id : property.value,
                motif : motif ? motif.value : "",
                output_date : outputDate,
                transaction_amount : transactionAmount,
                tva : tva,
                buyer_name: buyerName
            };

            if (motif && (motif.value !== "Vente" || motif.value !== "Réforme")) {
                delete sendData.buyer_name;
                delete sendData.transaction_amount;
                delete sendData.tva;
            }

            setSubmitting(true);
            axios.post(`/api/properties/${property.value}/outputs`, removeNullValue(sendData))
                .then(response => {
                    setErrorMessage(defaultError);
                    toast.success("Succès de l'opération");
                    window.location.reload();
                })
                .catch(error => {
                    toast.error("Echec de l'opération")
                    if (error.response.status === 422) {
                        setErrorMessage({...defaultError, ...error.response.data.errors});
                    }
                })
                .finally(() => setSubmitting(false))
            ;
        } else {
            toast.error("Veuillez sélectionner un bien");
        }
    };

    return (
        <Modal
            size={"md"}
            id="transfer-form"
            isOpen={show}
            backdrop={false}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                Sortie un bien
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col lg={12} className="mb-3">
                        <Label htmlFor="manage" className="form-label">Bien <InputRequire/></Label>

                        <PropertySearch
                            type="present"
                            value={property}
                            onChange={value => setProperty(value)}
                            error={[]}
                        />
                    </Col>

                    <Col lg={12} className="mb-3">
                        <div id="modal-id">
                            <Label htmlFor="motif" className="form-label">Motif <InputRequire/></Label>
                            <ReactSelect
                                id="motif"
                                name="motif"
                                placeholder="Veuillez sélectionner le motif"
                                value={motif}
                                onChange={value => setMotif(value)}
                                options={list ? list.motifs : []}
                                isLoading={loading}
                                error={errorMessage.motif}
                            />
                        </div>
                    </Col>

                    <Col lg={12} className="mb-3">
                        <div id="modal-id">
                            <Label htmlFor="output_date" className="form-label">Date de sortie <InputRequire/></Label>
                            <Input
                                type="date"
                                className="form-control"
                                id="output_date"
                                placeholder="Veuillez entrer la date de sortie"
                                value={outputDate}
                                validate={{
                                    required: { value: errorMessage.output_date.length > 0 },
                                }}
                                onChange={e => setOutputDate(e.target.value)}
                                invalid={errorMessage.output_date.length > 0}
                            />
                            {errorMessage.output_date.length > 0 && (
                                <FormFeedback type="invalid">
                                    {errorMessage.output_date[0]}
                                </FormFeedback>
                            ) }
                        </div>
                    </Col>

                    {motif !== null && (motif.value === "Vente" || motif.value === "Réforme") && (
                        <>
                            <Col lg={12} className="mb-3">
                                <div id="modal-id">
                                    <Label htmlFor="buyerName" className="form-label">Nom de l'écheteur</Label>

                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="buyerName"
                                        placeholder="Veuillez entrer le nom de l'acheteur"
                                        value={buyerName}
                                        validate={{
                                            required: { value: errorMessage.buyer_name.length > 0 },
                                        }}
                                        onChange={e => setBuyerName(e.target.value)}
                                        invalid={errorMessage.buyer_name.length > 0}
                                    />

                                    {errorMessage.buyer_name.length > 0 && (
                                        <FormFeedback type="invalid">
                                            {errorMessage.buyer_name[0]}
                                        </FormFeedback>
                                    ) }
                                </div>
                            </Col>

                            <Col lg={12} className="mb-3">
                                <div id="modal-id">
                                    <Label htmlFor="amount_transaction" className="form-label">Montant de transation</Label>

                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="transaction_amount"
                                        placeholder="Veuillez entrer le montant de la transation"
                                        value={transactionAmount}
                                        min={0}
                                        validate={{
                                            required: { value: errorMessage.transaction_amount.length > 0 },
                                        }}
                                        onChange={e => setTransactionAmount(e.target.value)}
                                        invalid={errorMessage.transaction_amount.length > 0}
                                    />

                                    {errorMessage.transaction_amount.length > 0 && (
                                        <FormFeedback type="invalid">
                                            {errorMessage.transaction_amount[0]}
                                        </FormFeedback>
                                    ) }
                                </div>
                            </Col>

                            <Col lg={12}>
                                <div id="modal-id">
                                    <Label htmlFor="tva" className="form-label">TVA</Label>

                                    <Input
                                        type="number"
                                        className="form-control"
                                        id="tva"
                                        placeholder="Veuillez entrer la TVA"
                                        min={0}
                                        value={tva}
                                        validate={{
                                            required: { value: errorMessage.tva.length > 0 },
                                        }}
                                        onChange={e => setTva(e.target.value)}
                                        invalid={errorMessage.tva.length > 0}
                                    />

                                    {errorMessage.tva.length > 0 && (
                                        <FormFeedback type="invalid">
                                            {errorMessage.tva[0]}
                                        </FormFeedback>
                                    ) }
                                </div>
                            </Col>
                        </>
                    )}
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button type="button" color="light" onClick={close}>Annuler</Button>
                <Button type="button" color="primary" onClick={submit}>
                    {submitting && (
                        <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                    )}
                    {" "}
                    Sortir
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default OutputForm;
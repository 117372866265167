import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {Col, NavLink, Row} from 'reactstrap';
import ArrowGoBackLineIcon from "remixicon-react/ArrowGoBackLineIcon";

const BreadCrumb = ({ title, pageTitle, secondTitle }) => {
  const history = useHistory()
    var canGoBack = (history.length > 1);



    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">

                        <h4 className="mb-sm-0 d-flex align-items-center">
                            {canGoBack &&
                                <Link to={"#"} onClick={() => history.goBack()} className={'ml-4'}><span style={{fontSize : '15pt',marginRight : '10px'}} className={"mdi mdi-arrow-left"}></span></Link>
                            }
                            <span className={"text-black"}>{title}</span>
                        </h4>
                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                {pageTitle !== null && (
                                    <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                )}
                                {secondTitle !== null && (
                                    <li className="breadcrumb-item"><Link to="#">{pageTitle}</Link></li>
                                )}
                                <li className="breadcrumb-item active">{title}</li>
                            </ol>
                        </div>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Spinner} from "reactstrap";

import { withRouter, Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {useAxios} from "../../hooks";
import {removeNullValue} from "../../helpers";
import {toast} from "react-toastify";

const ForgetPasswordPage = props => {
    const history = useHistory();

    const {axios} = useAxios();
    const [loading, setLoading] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez entrer votre email"),
        }),
        onSubmit: (values) => {
            setLoading(true);
            axios.post(`/api/forgot/password/email`, removeNullValue({email: values.email}))
                .then(({data}) => {
                    history.push("/reset-password");
                    toast.success("Veuillez consulter votre mail");
                })
                .catch(({error}) => {
                    toast.error("Le champ email sélectionné est invalide.");
                })
                .finally(() => {setLoading(false)})
            ;
        }
    });

    document.title ="Réinitialisation mot de passe | "+process.env.REACT_APP_NAME;
    return (
        <ParticlesAuth>
            <div className="auth-page-content">

                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/" className="d-inline-block auth-logo">
                                        <img src={"/imgs/logo.png"}  alt="" height="90" />
                                    </Link>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Mot de passe oublié?</h5>
                                        <p className="text-muted">Réinitialiser le mot de passe avec {process.env.REACT_APP_NAME}</p>

                                        <lord-icon
                                            src="https://cdn.lordicon.com/rhvddzym.json"
                                            trigger="loop"
                                            colors="primary:#073878"
                                            className="avatar-xl"
                                            style={{ width: "120px", height: "120px" }}
                                        >
                                        </lord-icon>

                                    </div>

                                    <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                                        Veuillez saisir votre adresse email, un code de réinitialisation  vous sera envoyé !
                                    </Alert>
                                    <div className="p-2">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                        >
                                            <div className="mb-4">
                                                <Label className="form-label">Email</Label>
                                                <Input
                                                    name="email"
                                                    className="form-control"
                                                    placeholder="Entrez l'email"
                                                    type="email"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.email || ""}
                                                    invalid={
                                                        validation.touched.email && validation.errors.email ? true : false
                                                    }
                                                />
                                                {validation.touched.email && validation.errors.email ? (
                                                    <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="text-center mt-4">
                                                <button className="btn btn-primary w-100" type="submit">
                                                    <span className="d-flex align-items-center justify-content-center">
                                                            {loading && (
                                                                <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                                                            )}
                                                        <span className="ms-2">{loading ? "Chargement" : "Envoyer le lien de réinitialisation"}</span>
                                                    </span>
                                                </button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-4 text-center">
                                <p className="mb-0">Attendez, je me souviens de mon mot de passe...  <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Cliquez ici </Link> </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default withRouter(ForgetPasswordPage);

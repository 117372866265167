import React from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Form,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import DeleteModal from "../../Components/Common/DeleteModal";
import DbDatatable from "../../Components/Common/DbDatatable";
import {useDatatablePage} from "../../hooks";
import {userHavePermissions} from "../../helpers";
import {Link} from "react-router-dom";

const DatatablePage = ({children, formWidth = "lg", addText = "Ajouter"}) => {
    const {deleteModal, handleDeleteEntity, setDeleteModal,isEdit, formTitle, onAddEntity,title,
        setFormError, defaultFormError, deleting, setIsEdit, toggle, columns, entities, setEntity,
        entitiesLoading, fetchData, modal, setModal, validation, sending, storePermission, actionLink
    } = useDatatablePage();

    const onClickAddButton = () => {
        setIsEdit(false);
        toggle();
        if (onAddEntity) {
            onAddEntity();
        }
    }
    return (
        <>
            <Row>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteEntity}
                    onCloseClick={() => {
                        setEntity(null);
                        setDeleteModal(false);
                        setFormError(defaultFormError);
                    }}
                    loading={deleting}
                />
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">{title}</h5>
                                {userHavePermissions([storePermission]) && (
                                    <div className="flex-shrink-0">
                                        {(!actionLink || !actionLink.store) ? (
                                            <button
                                                className="btn btn-primary add-btn"
                                                onClick={onClickAddButton}
                                            >
                                                <i className="ri-add-line align-bottom"></i> {addText}
                                            </button>
                                        ) : (
                                            <Link
                                                to={actionLink.store ? actionLink.store : "/#"}
                                                className="btn btn-primary add-btn"
                                            >
                                                <i className="ri-add-line align-bottom"></i> {addText}
                                            </Link>
                                        )}
                                    </div>
                                )}
                            </div>
                        </CardHeader>

                        <CardBody className='pt-0'>
                            <DbDatatable
                                columns={columns}
                                data={entities ? entities.data : []}
                                className="custom-header-css"
                                loading={entitiesLoading}
                                fetchData={fetchData}
                                perPage={entities ? entities['per_page'] : undefined}
                                totalPage={entities ? entities['last_page'] : undefined}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={toggle} centered size={formWidth} className="border-0" modalClassName="zoomIn" backdrop={false}>
                <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                    {!!isEdit ? formTitle.update : formTitle.store}
                </ModalHeader>
                <Form onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <ModalBody>
                        <Row className="g-3">
                            {children}
                        </Row>

                    </ModalBody>
                    <div className="modal-footer">
                        <div className="hstack gap-2 justify-content-end">
                            <button onClick={toggle} type="button" className="btn btn-light" data-bs-dismiss="modal">Annuler</button>
                            <button type="submit" className="btn btn-success" id="add-btn">
                                <span className="d-flex align-items-center justify-content-center">
                                    {sending && (
                                        <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                                    )}
                                    <span className="ms-2">{sending ? "Chargement" : (!!isEdit ? "Modifier" : "Valider")}</span>
                                </span>
                            </button>
                        </div>
                    </div>
                </Form>
            </Modal>
        </>
    );
};

export default DatatablePage;
import {createContext, useCallback, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import {useAxios} from "../hooks";
import {getConfig} from "../helpers";
import {toast} from "react-toastify";

export const AuthContext = createContext({
    user: null,
    login: (user) => {},
    logout: () => {},
    update: (user) => {}
});

export const AuthProvider = ({children}) => {
    const driver = getConfig('AUTH.DRIVER');
    const history = useHistory();
    const request = useAxios();
    const [user, setUser] = useState(driver.getItem('user') ? JSON.parse(driver.getItem('user')) : null);

    const login = useCallback((user, redirect = true) => {
        driver.setItem('user', JSON.stringify(user));
        setUser(user);
        if (redirect){
            toast("Succès de la connexion", {type: 'success'});
            history.push(getConfig("AUTH.REDIRECT_LOGIN"));
        }
    }, [driver]);

    const logout = useCallback((e = null) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }
        driver.clear();
        request.removeToken();
        setUser(null);
        toast("Succès de la déconnexion", {type: 'success'});
        history.push(getConfig("AUTH.REDIRECT_LOGOUT"));
    }, [driver, request]);

    const update = useCallback((user) => {
        driver.setItem('user', JSON.stringify(user));
        setUser(user);
    }, [driver]);

    const value = useMemo(() => {
        return {
            user,
            login,
            logout,
            update
        };
    }, [user, login, logout, update]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
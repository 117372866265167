import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {useAxios} from "../../hooks";
import {Card, CardBody, CardHeader, Col, Input, Row, Spinner} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import {LabelingContainer} from "./LabelingCardsContainer";
import { useReactToPrint } from 'react-to-print';
import {toast} from "react-toastify";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";

const Labeling = () => {
    const {axios} = useAxios();
    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [standard, setStandard] = useState(null);
    const [labelingsLoading, setLabelingsLoading] = useState(false);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [printing, setPrinting] = useState(false);
    const [labelings, setLabelings] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(50);
    const [key, setSearch] = useState('');
    const componentRef = useRef();
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: [],
        standards: [],
    });
    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }));
    }, []);

    useEffect(() => {
        setLoadFilterData(true);
        axios.get(`/api/labeling-filters`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    standards: formatData(data.standards),
                    buildings: [],
                    locals: []
                })
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
        ;
    }, [formatData]);

    document.title ="Etiquette | "+env('NAME');

    useEffect(() => {
         fetchData()
    }, [page, perPage]);


    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        setLabelingsLoading(true);
            const newSite = site ? site.value : '';
            const newLocal = local ? local.value : '';
            const newStandard = standard ? standard.value : '';
            axios.get(`/api/labeling?site_id=${newSite}&search=${key}&local_id=${newLocal}&standard_id=${newStandard}&page=${page}&per_page=${perPage}`)
                .then(({data}) => {
                    setLabelings(data)
                })
                .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
                .finally(() => setLabelingsLoading(false))
            ;

    }, [building, local, site, standard, perPage, page, key]);

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            standards: filterData.standards,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: formatData(value.batiments),
                locals: [],
                standards: filterData.standards,
            })
        }
    }

    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            locals: [],
            standards: filterData.standards,
        });
        setBuilding(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                standards: filterData.standards,
                buildings: filterData.buildings,
                locals: formatData(value.locals)
            })
        }
    }

    const handlePrint = () => {
        setPrinting(true)
        const newSite = site ? site.value : '';
        const newLocal = local ? local.value : '';
        const newStandard = standard ? standard.value : '';
        axios.get(`/api/export-labeling?site_id=${newSite}&local_id=${newLocal}&standard_id=${newStandard}&page=${page}&per_page=${perPage}&search=${key}`,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then((data) => {
                let blob = new Blob([data.data], { type: 'application/pdf' });
                let url = window.URL.createObjectURL(blob, { oneTimeOnly: true });
                let anchor = document.createElement('a');
                anchor.href = url;
                anchor.target = '_blank';
                anchor.click();
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});
            })
            .finally(() => setPrinting(false));
    }

    return (
        <PageContent title={"Etiquettes Biens"} pageTitle={"Tableau de bord"}>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="border-0">
                            <div className="d-flex align-items-center">
                                <h5 className="card-title mb-0 flex-grow-1">Filtre</h5>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button disabled={labelingsLoading} onClick={fetchData}
                                    className="btn btn-primary" style={{marginRight : "5px"}}>
                                    <i className="ri-filter-2-line align-bottom"></i> Filtrer
                                </button>
                                <button disabled={labelingsLoading || printing || labelings?.data?.length === 0} onClick={handlePrint}
                                    className="btn btn-primary">
                                    <i className="ri-printer-line align-bottom"></i> Imprimer
                                </button>
                            </div>
                        </CardHeader>

                        <CardBody>
                            <Row className="mb-3 g-3">
                                <Col sm={4}>
                                    <ReactSelect
                                        id="site"
                                        name="site"
                                        placeholder="Veuillez sélectionner le site"
                                        value={site}
                                        onChange={handleSiteChange}
                                        options={filterData.sites}
                                        isLoading={loadFilterData || labelingsLoading}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <ReactSelect
                                        id="building"
                                        name="building"
                                        placeholder="Veuillez sélectionner le batiment"
                                        value={building}
                                        onChange={handleBuildingChange}
                                        options={filterData.buildings}
                                        isLoading={loadFilterData}
                                    />
                                </Col>

                                <Col sm={4}>
                                    <ReactSelect
                                        id="local"
                                        name="local"
                                        placeholder="Veuillez sélectionner le local"
                                        value={local}
                                        onChange={value => {setLocal(value)}}
                                        options={filterData.locals}
                                        isLoading={loadFilterData}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <ReactSelect
                                        id="standard"
                                        name="standard"
                                        placeholder="Veuillez sélectionner un standard"
                                        value={standard}
                                        onChange={value => {setStandard(value)}}
                                        options={filterData.standards}
                                        isLoading={loadFilterData}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Input
                                        disabled={labelingsLoading}
                                        placeholder={'Code ERP, Code immo..'}
                                        value={key}
                                        onChange={(e) => {
                                            setSearch(e.target.value)
                                        }}
                                    />
                                </Col>
                               {/* <Col sm={4}>
                                    <Input
                                        disabled={labelingsLoading}
                                        placeholder={'Étiquettes par page'}
                                        value={perPage}
                                        onChange={(e) => {
                                            setPage(1)
                                            setPerPage(e.target.value)
                                        }}
                                    />
                                </Col>*/}
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Card>
                {
                    <Row className={'d-flex justify-content-end m-2'}>

                        <Col md={3}>
                            {
                                labelings.current_page > 1 &&
                                <button
                                    disabled={labelingsLoading}
                                    className="btn btn-sm btn-primary"
                                    onClick={() => setPage(page - 1)}>
                                    Précédent
                                </button>
                            }
                            <span className={'m-2'}>Page {`${labelings.current_page || '-'}/${labelings.last_page || '-'}`}</span>
                            {
                                labelings.current_page < labelings.last_page &&
                                <button
                                    disabled={labelingsLoading}
                                    className="btn btn-sm btn-primary"
                                    onClick={() => {setPage(page + 1);}}>
                                    Suivant
                                </button>
                            }


                        </Col>
                    </Row>
                }

                {labelingsLoading ?
                    <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center" style={{top: "0"}}>
                        <Spinner color={"info"} size="lg" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                    </div>
                :
                    <CardBody>
                        <LabelingContainer labelingCards={labelings} ref={componentRef}/>
                    </CardBody>
                }

            </Card>


        </PageContent>
    );
};

export default Labeling;
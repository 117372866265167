import {createContext, useCallback, useLayoutEffect, useMemo, useState} from "react";
import {getAxiosInstance, getConfig, isLogin} from "../helpers";

export const AxiosContext = createContext({
    axios: null,
    pushToken: (token) => {},
    removeToken: () => {}
});

export const AxiosProvider = ({children}) => {
    const driver = getConfig('AUTH.DRIVER');
    const [axios, setAxios] = useState(getAxiosInstance);

    const pushToken = useCallback((token) => {
        setAxios(a => {
            a.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            return a;
        })
    }, []);

    useLayoutEffect(() => {
        const user = JSON.parse(driver.getItem('user'));

        if (isLogin()) {
            pushToken(user['token_data']['access_token']);
        }
    }, [driver, pushToken]);


    const removeToken = useCallback(() => {
        setAxios(a => {
            delete a.defaults.headers.common["Authorization"];
            return a;
        });
    }, []);

    const value = useMemo(() => {
        return {
            axios: axios,
            pushToken,
            removeToken
        }
    }, [axios, pushToken, removeToken]);

    return (
        <AxiosContext.Provider value={value}>
            {children}
        </AxiosContext.Provider>
    )
};
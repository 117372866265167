import React, {useEffect, useState} from "react";
import {Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table} from "reactstrap";
import {InputRequire} from "../../Components/personal/utils";
import ReactSelect from "../../Components/Common/ReactSelect";
import {useAxios} from "../../hooks";
import {toast} from "react-toastify";

const CloseInventoryForm = ({toggle, open, properties,proceedClosing}) => {
    const {axios} = useAxios();

    const [desktop, setDesktop] = useState(null);
    const [state, setState] = useState(null);
    const [user, setUser] = useState(null);
    const [sending, setSending] = useState(false);

    useEffect(() => {

    }, [properties]);

    const submitForm = () => {
        setSending(true);
        toggle()
        proceedClosing()
    }



    return (
        <Modal id="CloseInventory" isOpen={open}  toggle={toggle} size="lg" className="border-0" modalClassName="zoomIn">
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                Confirmation de clôture
            </ModalHeader>
            <ModalBody>
                <Row className="g-3 text-center mt-4 text-danger">
                    Oups! Des bien non inventoriés  ou des écarts non validés ont été retrouvés dans la base.
                    Veuillez confirmer si vous voulez continuer quand même.
                </Row>
                <table className={'table table-stripped'}>
                    <thead>
                    <tr>
                        <th>Ecarts non validés</th>
                        <th>Bien non inventoriés</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{properties?.properties}</td>
                        <td>{properties?.properties_not_inventoried}</td>
                    </tr>
                    </tbody>
                </table>
            </ModalBody>

            <ModalFooter>
                <Button color="light" onClick={toggle}>
                    Annuler
                </Button>

                <button type="submit" className="btn btn-success" id="add-btn" onClick={submitForm}>
                    <span className="d-flex align-items-center justify-content-center">
                        {sending && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <span className="ms-2">{sending ? "Chargement" : "Continuer"}</span>
                    </span>
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default CloseInventoryForm;
import React, {useCallback, useEffect, useMemo, useState} from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {downloadDataUrlFromJavascript} from "../../helpers";

const SubStandards = () => {
    let history = useHistory();
    document.title ="Sous Standards | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [

            {
                Header: "Compte comptable",
                accessor: "displayed_code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard parent",
                accessor: "parent.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Famille",
                accessor: "family.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },

            {
                Header: "Durée d'amortissement",
                accessor: "depreciation_period",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);
    const [family, setFamily] = useState(null);
    const [parent, setParent] = useState(null);
    const [active, setActive] = useState(false);
    const [isDepreciable, setIsDepreciable] = useState(false);
    const {list, loading} = useFetchDataList('/api/children-standards/create', (data) => {
        return {
            families: data['families'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }),
            standards: data['standards'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                    code : item.code
                }
            }),
        };
    });
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportModalErrors, setShowImportModalErrors] = useState(false);
    const [importErrors, setImportErrors] = useState(null);
    const [submitError, setSubmitError] = useState();
    const [file, setFile] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter un sous standard',
                update: 'Modifier un sous standard'
            },
            formDefaultError: {name: [], code: [], accounting_account: [], family_id: [],  depreciation_period: []},
            initialValues: {name: '', code: '', accounting_account: '', depreciation_period: '',},
            validationSchema: Yup.object({
                //parent_id: Yup.string().required("Veuillez choisir le standard parent"),
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
                //depreciation_period: Yup.string().required("Veuillez entrer la durée d’amortissement"),
            }),
            formatFormData: (values) => {
                const sendData = {
                    ...values,
                    accounting_account : values?.code,
                    family_id: family ? family.value : '',
                    standard_id: parent ? parent.value : '',
                    sticker_is_requis: active,
                };

                if (!sendData.standard_id)
                    delete sendData.standard_id;

                return sendData;
            },
            formatResponse: data => {
                history.replace('/');
                setTimeout(() => {
                    history.replace('/sub-standards');
                }, 0);
                return data;
            },
            onSuccess: () => {
                setFamily(null);
            },
            onFail: () => {},
        };
    }, [active, family, history, parent]);

    const onUpdateEntity = useCallback((editData) => {
        console.log("editData:", editData);
        let result = list['families'].filter(item => item.value === editData['family_id']);
        result = result.length ? result[0] : null;
        if (result) {
            setFamily(result);
        } else {
            setFamily(null);
        }
        if (editData['parent']) {
            setParent({
                value: editData.parent.id,
                label: editData.parent.name,
                code: editData.parent.code,
                update : true,
            });
        }
        setActive(editData.sticker_is_requis === null ? false : editData.sticker_is_requis)

    }, [list]);

    const onAddEntity = useCallback(() => {
        setFamily(null);
    }, []);

    const handleFileChange = (e) => {
        setFile(Object.values(e.target.files));
    }
    const submitImport = () => {
        setSubmitting(true)
        let data = new FormData();
        data.append('file',file[0])
        axios.post("/api/import-standards",data)
            .then(({data}) => {
                if (data?.errors?.length){
                    toast("Des erreurs ont été rencontrées lors de l'imporation", {type: 'error'});
                    setImportErrors(data.errors)
                    setShowImportModalErrors(true)
                }else{
                    setShowImportModal(false)
                    toast("Importation effectuée avec succès", {type: 'success'});
                    window.location.reload();
                }
            })
            .catch(error => {
                toast( "Echec de l'enregistrement", {type: 'error'});
                if (error.response?.status === 422) {
                    setSubmitError({...error.response.data.errors});
                }
            })
            .finally(() => setSubmitting(false))
        ;
    }

    return (
        <PageContent title={"Sous standards"} pageTitle={"Biens"}>
            <div className={'mb-4 text-end'}>
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowImportModal(true)}>
                    <i className="ri-add-line align-bottom"></i> Importer
                </button>
            </div>

            <DatatablePageProvider
                title={"Sous standards"}
                endpoint={"/api/children-standards"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-standard"}
                updatePermission={"edit-standard"}
                deletePermission={"destroy-standard"}
            >
                <Datatable
                    family={family}
                    setFamily={setFamily}
                    families={list["families"]}
                    standards={list["standards"]}
                    parent={parent}
                    setParent={setParent}
                    loading={loading}
                    active={active}
                    setActive={setActive}
                    isDepreciable={isDepreciable}
                    setIsDepreciable={setIsDepreciable}
                />
            </DatatablePageProvider>


            <Modal
                id={"import-modal"}
                isOpen={showImportModal}>
                <ModalHeader className="p-3 bg-soft-info">
                    Importer les sous standards
                </ModalHeader>

                <ModalBody>
                    <div className="table-responsive">
                        {errorMessage.length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                    <form>
                        <Col sm={12}>
                            <Label htmlFor="file" className="form-label">Fichier</Label>
                            <Input
                                type="file"
                                className="form-control"
                                id="file"
                                validate={{
                                    required: {value: submitError && submitError.file.length > 0},
                                }}
                                multiple={false}
                                placeholder="Veuillez selectionner le fichier"
                                onChange={e => handleFileChange(e)}
                                invalid={submitError && submitError.file?.length > 0}
                            />
                            {submitError && submitError.file?.length > 0 && (
                                <FormFeedback type="invalid">
                                    {submitError.file[0]}
                                </FormFeedback>
                            )}
                        </Col>

                        <div className={"mt-2"}>
                            Télécharger <a href="#"
                                           onClick={() => downloadDataUrlFromJavascript('format_import_standards.xlsx', `${process.env.REACT_APP_API_HOST}/storage/model-files/format_import_standards.xlsx`)}
                        className={"fw-bold"}>le format d'importation <i className={"ri ri-download-2-line"}></i></a>
                        </div>
                    </form>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModal(false)}>
                        Annuler
                    </Button>

                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab"
                        onClick={submitImport}
                        disabled={submitting || !file}>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement... </Spinner>
                        )}
                        {"Importer"}
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                id={"import-modal-errors"}
                size={"lg"}
                isOpen={showImportModalErrors}>
                <ModalHeader className="p-3 bg-soft-info">
                    Erreur d'importation
                </ModalHeader>

                <ModalBody>

                    <div className={"p-4"}>
                        <div className={"text-danger text-center"}>
                            Veuillez corriger ces lignes et réessayer la réimportation
                        </div>
                        {importErrors?.map((error, index) =>
                            (
                                <Row key={`row-${index}`}>
                                    <h5>Sur la ligne N° {error.line}</h5>
                                    {<ul>
                                        {Object.values(error?.messages)?.map((msg, index) =>
                                            (<li key={`list-${index}`} className="text-danger">{msg[0]}</li>)
                                        )}

                                    </ul>}
                                </Row>
                            ))}

                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModalErrors(false)}>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </PageContent>
    );
};

export default SubStandards;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {CodeQR, Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";
import axios from "axios";
import {toast} from "react-toastify";
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from "reactstrap";
import {downloadDataUrlFromJavascript, downloadReport} from "../../helpers";

const Locales = () => {
    document.title ="Locaux | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Code QR",
                accessor: "qr_code.link",
                filterable: false,
                Cell: (cellProps) => {
                    return <CodeQR {...cellProps} />;
                },
            },
            {
                Header: "Code ",
                accessor: "code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Niveau",
                accessor: "level.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Batiment",
                accessor: "batiment.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);
    const [level, setLevel] = useState(null);
    const [building, setBuilding] = useState(null);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportModalErrors, setShowImportModalErrors] = useState(false);
    const [importErrors, setImportErrors] = useState(null);
    const [submitError, setSubmitError] = useState();
    const [file, setFile] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const {list, loading} = useFetchDataList('/api/locals/create', (data) => {

        return {
            batiments: data['batiments'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }),
            levels: data['levels'].map(item => {
                return {
                    label: item.name,
                    value: item.id,
                }
            }),
        };
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter un local',
                update: 'Modifier un local'
            },
            formDefaultError: {name: [], code: [], level_id: [], batiment_id: []},
            initialValues: {name: '', code: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    level_id: level ? level.value : '',
                    batiment_id: building ? building.value : '',
                };
            },
            formatResponse: data => {
                const newData = {...data};
                let result = list['levels'].filter(item => item.value === data.level_id);
                result = result.length ? result[0] : null;
                newData['level'] = {name: result.label};
                result = list['batiments'].filter(item => item.value === data.batiment_id);
                result = result.length ? result[0] : null;
                newData['batiment'] = {name: result.label};
                return newData;
            },
            onSuccess: (response, type) => {
                setBuilding(null);
                setLevel(null)
            },
            onFail: (response, type) => {},
        };
    }, [building, level, list]);

    const onUpdateEntity = useCallback((editData) => {
        let result = list['levels'].filter(item => item.value === editData.level_id);
        result = result.length ? result[0] : null;
        if (result) {
            setLevel(result);
        } else {
            setLevel(null);
        }
        result = list['batiments'].filter(item => item.value === editData.batiment_id);
        result = result.length ? result[0] : null;
        if (result) {
            setBuilding(result);
        } else {
            setBuilding(null);
        }
    }, [list]);

    const onAddEntity = useCallback(() => {
        setLevel(null);
        setBuilding(null);
    }, []);

    const handleFileChange = (e) => {
        setFile(Object.values(e.target.files));
    }
    const submitImport = () => {
        setSubmitting(true)
        let data = new FormData();
        data.append('file',file[0])
        axios.post("/api/import-localization",data)
            .then(({data}) => {
                console.log({data})
                if (data?.errors?.length){
                    toast("Des erreurs ont été rencontrées lors de l'imporation", {type: 'error'});
                    setImportErrors(data.errors)
                    setShowImportModalErrors(true)
                }else{
                    setShowImportModal(false)
                    toast("Importation effectuée avec succès", {type: 'success'});
                    window.location.reload();
                }
            })
            .catch(error => {
                toast( "Echec de l'enregistrement", {type: 'error'});
                if (error.response?.status === 422) {
                    setSubmitError({...error.response.data.errors});
                }
            })
            .finally(() => setSubmitting(false))
        ;
    }
    const exportLocals = (format) => {
        setSubmitting(true)
        axios.get('api/export/locals',
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data,'bureaux',format)
                toast("Exportation effectuée avec succès", {type: 'success'});
            }).finally(() => setSubmitting(false));
    }

    return (
        <PageContent title={"Locaux"} pageTitle={"Localisation"}>
            <div className={'mb-4 text-end'}>
                <button
                    className="btn btn-sm btn-primary"
                    disabled={ submitting }
                    onClick={() => exportLocals('xlsx')}>
                    <i className="ri-download-2-line align-bottom"></i> { !submitting ? ' Exporter' : '...'}
                </button>&nbsp;
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => setShowImportModal(true)}>
                    <i className="ri-add-line align-bottom"></i> Importer
                </button>
            </div>

            <DatatablePageProvider
                title={""}
                endpoint={"/api/locals"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-local"}
                updatePermission={"edit-local"}
                deletePermission={"destroy-local"}
            >
                <Datatable
                    level={level}
                    setLevel={setLevel}
                    levels={list['levels']}
                    building={building}
                    setBuilding={setBuilding}
                    buildings={list['batiments']}
                    loading={loading}
                />
            </DatatablePageProvider>

            <Modal
                id={"import-modal"}
                isOpen={showImportModal}>
                <ModalHeader  className="p-3 bg-soft-info">
                    Importer les locaux
                </ModalHeader>

                <ModalBody>
                    <div className="table-responsive">
                        {errorMessage.length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                    <form>
                        <Col sm={12}>
                            <Label htmlFor="file" className="form-label">Fichier</Label>
                            <Input
                                type="file"
                                className="form-control"
                                id="file"
                                validate={{
                                    required: {value: submitError && submitError.file.length > 0},
                                }}
                                multiple={false}
                                placeholder="Veuillez selectionner le fichier"
                                onChange={e => handleFileChange(e)}
                                invalid={submitError && submitError.file?.length > 0}
                            />
                            {submitError && submitError.file?.length > 0 && (
                                <FormFeedback type="invalid">
                                    {submitError.file[0]}
                                </FormFeedback>
                            )}
                        </Col>
                        <div className={"mt-2"}>
                            Télécharger <a href="#"
                                           onClick={() => downloadDataUrlFromJavascript('format_import_locals.xlsx', `${process.env.REACT_APP_API_HOST}/storage/model-files/format_import_locals.xlsx`)}
                                           className={"fw-bold"}>le format d'importation <i
                            className={"ri ri-download-2-line"}></i></a>
                        </div>
                    </form>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModal(false)}>
                        Annuler
                    </Button>

                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab"
                        onClick={submitImport}
                        disabled={submitting || !file}>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement... </Spinner>
                        )}
                        {"Importer"}
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                id={"import-modal-errors"}
                size={"lg"}
                isOpen={showImportModalErrors}>
                <ModalHeader  className="p-3 bg-soft-info">
                    Erreur d'importation
                </ModalHeader>

                <ModalBody>

                    <div className={"p-4"}>
                        <div className={"text-danger text-center"}>
                            Veuillez corriger ces lignes et réessayer la réimportation
                        </div>
                        { importErrors?.map((error,index) =>
                            (
                                <Row key={`row-${index}`}>
                                    <h5>Sur la ligne N° {error.line}</h5>
                                    {<ul>
                                        {Object.values(error?.messages)?.map((msg,index) =>
                                            (<li key={`list-${index}`} className="text-danger">{msg[0]}</li>)
                                        )}

                                    </ul>}
                                </Row>
                            ))}

                    </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModalErrors(false) }>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </PageContent>
    );
};

export default Locales;
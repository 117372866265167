import React, {useMemo} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";

const Marks = () => {
    document.title = "Marques | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Nom",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter une marque',
                update: 'Modifier une marque'
            },
            formDefaultError: {name: []},
            initialValues: {name: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
            }),
            formatFormData: (values) => values,
            onSuccess: (response, type) => {},
            onFail: (response, type) => {},
        };
    }, []);

    return (
        <PageContent title={"Marques"} pageTitle={"Configuration"}>
            <DatatablePageProvider
                title={""}
                endpoint={"/api/marks"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                storePermission={"store-mark"}
                updatePermission={"edit-mark"}
                deletePermission={"destroy-mark"}
            >
                <Datatable/>
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Marks;
import React from "react";
import {Col, FormFeedback, Input, InputGroup, InputGroupText, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({
                       standards, parent, setParent, loading, active,
                       setActive,
                   isDepreciable,
                   setIsDepreciable}) => {
    const {validation, formError} = useDatatablePage();

    isDepreciable = isDepreciable || validation.values.depreciation_period > 0;

    return (
        <DatatablePage>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="standard_id" className="form-label">Standard parent <InputRequire/></Label>
                    <ReactSelect
                        id="standard_id"
                        name="standard_id"
                        placeholder="Veuillez sélectionner la standard parent"
                        value={parent}
                        onChange={value => setParent(value)}
                        options={standards}
                        isLoading={loading}
                        error={formError['parent_id']}
                    />
                    {/*{(validation.touched.parent_id && validation.errors.parent_id) || formError.parent_id.length ? (
                        <FormFeedback type="invalid">
                            {formError.parent_id.length ? formError.parent_id[0] : validation.errors.parent_id}
                        </FormFeedback>
                    ) : null}*/}
                </div>
            </Col>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="code" className="form-label">Code <InputRequire/></Label>
                    <InputGroup>
                        <InputGroupText>
                            {parent?.code}
                        </InputGroupText>
                    <Input
                        id="code"
                        name="code"
                        className="form-control"
                        placeholder="Veuillez entrer le nom"
                        type="text"
                        validate={{
                            required: { value: true, message : "Code requis" },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.code || ""}
                        invalid={
                            !!((validation.touched.code && validation.errors.code) || formError.code.length)
                        }
                    />
                        {(validation.touched.code && validation.errors.code) || formError.code.length ? (
                            <FormFeedback type="invalid">
                                {formError.code.length ? formError.code[0] : validation.errors.code}
                            </FormFeedback>
                        ) : null}
                    </InputGroup>
                </div>
            </Col>
            <Col lg={12} className="mb-3">
                <div className="form-check form-switch form-switch-custom form-switch-primary">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="active"
                        checked={isDepreciable || validation.values.depreciation_period > 0}
                        onChange={e => setIsDepreciable(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="active">Bien amortissable ?</label>
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Dénomination <InputRequire/></Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer la dénomination"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            {
                (isDepreciable) &&
                <Col lg={6}>
                    <div id="modal-id">
                        <Label htmlFor="depreciation_period" className="form-label">La durée d’amortissement <InputRequire/></Label>
                        <Input
                            id="depreciation_period"
                            name="depreciation_period"
                            className="form-control"
                            placeholder="Veuillez entrer la durée"
                            type="number"
                            min={0}
                            validate={{
                                required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            value={validation.values.depreciation_period || ""}
                            invalid={
                                !!((validation.touched.depreciation_period && validation.errors.depreciation_period) || formError.depreciation_period.length)
                            }
                        />
                        {(validation.touched.depreciation_period && validation.errors.depreciation_period) || formError.depreciation_period.length ? (
                            <FormFeedback type="invalid">
                                {formError.depreciation_period.length ? formError.depreciation_period[0] : validation.errors.depreciation_period}
                            </FormFeedback>
                        ) : null}
                    </div>
                </Col>

            }

            <Col lg={12} className="mb-3">
                <div className="form-check form-switch form-switch-custom form-switch-primary">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="labelling"
                        checked={active}
                        onChange={e => setActive(e.target.checked)}
                    />
                    <label className="form-check-label" htmlFor="labelling">Etiquette réquise ?</label>
                </div>
            </Col>

        </DatatablePage>
    );
};

export default Datatable;
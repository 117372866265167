import React, {useEffect, useState} from "react";
import {Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table} from "reactstrap";
import {InputRequire} from "../../Components/personal/utils";
import ReactSelect from "../../Components/Common/ReactSelect";
import {useAxios} from "../../hooks";
import {toast} from "react-toastify";

const CloseFiscalYearForm = ({toggle, open, inventories,proceedClosing}) => {
    const {axios} = useAxios();


    useEffect(() => {

    }, [inventories]);



    return (
        <Modal id="CloseInventory" isOpen={open} toggle={toggle} size="lg" className="border-0" modalClassName="zoomIn">
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                Inventaires non clôturés
            </ModalHeader>
            <ModalBody>
                <Row className="d-flex justify-content-center">
                    Oups! Il y a des invetaires non clôturé, veuillez les clôturé et réssayer.
                </Row>

                {
                    inventories && inventories.map((inventory) => {
                        return (
                                <Row key={inventory.id}> => {inventory.name}</Row>
                            )

                    })
                }

            </ModalBody>

            <ModalFooter>
                <Button color="light" onClick={toggle}>
                    D'accord
                </Button>

                {/*<button type="submit" className="btn btn-success" id="add-btn" onClick={submitForm}>
                    <span className="d-flex align-items-center justify-content-center">
                        {sending && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement en cours... </Spinner>
                        )}
                        <span className="ms-2">{sending ? "Chargement" : "Continuer"}</span>
                    </span>
                </button>*/}
            </ModalFooter>
        </Modal>
    );
};

export default CloseFiscalYearForm;
import React from "react";
import {Col, FormFeedback, Input, Label} from "reactstrap";
import ReactSelect from "../../Components/Common/ReactSelect";
import DatatablePage from "../DatatablePage";
import {useDatatablePage} from "../../hooks";
import {InputRequire} from "../../Components/personal/utils";

const Datatable = ({country, setCountry, loading, countries}) => {
    const {validation, formError} = useDatatablePage();

    return (
        <DatatablePage>
            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="name" className="form-label">Dénomination <InputRequire /> </Label>
                    <Input
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Veuillez entrer la dénomination"
                        type="text"
                        validate={{
                            required: { value: true },
                        }}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.name || ""}
                        invalid={
                            !!((validation.touched.name && validation.errors.name) || formError.name.length)
                        }
                    />
                    {(validation.touched.name && validation.errors.name) || formError.name.length ? (
                        <FormFeedback type="invalid">
                            {formError.name.length ? formError.name[0] : validation.errors.name}
                        </FormFeedback>
                    ) : null}
                </div>
            </Col>

            <Col lg={6}>
                <div id="modal-id">
                    <Label htmlFor="country_id" className="form-label">Pays <InputRequire /> </Label>
                    <ReactSelect
                        id="country_id"
                        name="country_id"
                        placeholder="Veuillez sélectionner le pays"
                        value={country}
                        onChange={value => setCountry(value)}
                        options={countries}
                        isLoading={loading}
                        error={formError.country_id}
                    />
                </div>
            </Col>
        </DatatablePage>
    );
};

export default Datatable;
import React, {useCallback, useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {CodeQR, Date, Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {
    Button,
    Col,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner,
} from "reactstrap";
import {downloadDataUrlFromJavascript, downloadReport} from "../../helpers";
import {toast} from "react-toastify";
import axios from "axios";


const Properties = () => {
    document.title ="Biens | "+env('NAME');

    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [loadFilterData, setLoadFilterData] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [standard, setStandard] = useState(null);
    const [standards, setStandards] = useState([]);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [states, setStates] = useState([]);
    const [date, setDate] = useState("");
    const [importRows, setImportRows] = useState(500);
    const [showTransferForm, setShowTransferForm] = useState(false);
    const [maxDate, setMaxDate] = useState({
        start: "",
        end: ""
    });
    const [staff, setStaff] = useState(null);
    const [users, setUsers] = useState([]);
    const [showImportModal, setShowImportModal] = useState(false);
    const [showImportModalErrors, setShowImportModalErrors] = useState(false);
    const [importErrors, setImportErrors] = useState(null);
    const [submitError, setSubmitError] = useState();
    const [importTarget, setImportTarget] = useState('properties');
    const [file, setFile] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleSiteChange = (value) => {
        setBuilding(null);
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: [],
            locals: []
        });
        setSite(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: formatData(value.batiments),
                locals: []
            })
        }
    };
    const handleBuildingChange = (value) => {
        setLocal(null);
        setFilterData({
            sites: filterData.sites,
            buildings: filterData.buildings,
            locals: []
        });
        setBuilding(value);
        if (value) {
            setFilterData({
                sites: filterData.sites,
                buildings: filterData.buildings,
                locals: formatData(value.locals)
            })
        }
    }
    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Code QR",
                accessor: "qr_code.link",
                filterable: false,
                Cell: (cellProps) => {
                    return <CodeQR {...cellProps} />;
                },
            },
            {
                Header: "Codification",
                accessor: "codification",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code immo",
                accessor: "code_immo",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code ERP (compta)",
                accessor: "reference",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Sous Standard",
                accessor: "standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Date de mise en service",
                accessor: "date_of_use",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Etat du bien",
                accessor: "etat.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);
    const entityForm = useMemo(() => {
        return {
            title: null,
            formDefaultError: {},
            initialValues: {},
            validationSchema: Yup.object({}),
            formatFormData: () => {},
            formatResponse: () => {},
            onSuccess: () => {},
            onFail: () => {},
        };
    }, []);
    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id,
            label: item.name
        }));
    }, []);
    const handleDateChange = (value) => {
        console.log({value})
    }
    const handleStaffChange = (value) => {
        setStaff(value);
    }
    const handleFileChange = (e) => {
        setFile(Object.values(e.target.files));
    }
    const submitImport = () => {
        setSubmitting(true)
        let data = new FormData();
        data.append('file',file[0])
        data.append('limit',`${importRows}`)
        let url = importTarget === 'properties' ? "/api/import-properties" : "/api/affect-properties";

        axios.post(url,data)
            .then(({data}) => {
                if (data?.error){
                    toast("Des erreurs ont été rencontrées lors de l'imporation", {type: 'error'});
                    setImportErrors(data.errors)
                    setShowImportModalErrors(true)
                }else{
                    setShowImportModal(false)
                    toast("Importaion effectuée avec succès", {type: 'success'});
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log({error})
                toast( "Echec de l'enregistrement", {type: 'error'});
                if (error.response?.status === 422) {
                    setSubmitError({...error.response.data.errors});
                }
            })
            .finally(() => setSubmitting(false))
        ;
    }
    const showImportationModal = (target) => {
        setImportTarget(target)
        setShowImportModal(true)
    }
    const exportPropertiesToAffExel = (format) => {
        setSubmitting(true)
        axios.get('api/export/properties-to-affect',
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data,'bien-a-affecte',format)
                toast("Exportation effectuée avec succès", {type: 'success'});
            }).finally(() => setSubmitting(false));
    }

    const deleteDuplicates = () => {
        setSubmitting(true)
        axios.delete("/api/delete-duplicates")
            .then(({data}) => {
                setShowImportModal(false)
                toast("Supression éffectuée avec succès", {type: 'success'});
                window.location.reload();
            })
            .catch(error => {
            })
            .finally(() => setSubmitting(false))
        ;
    }
    const generateQrCodes = () => {
        setSubmitting(true)
        axios.get("api/generate-qr-codes")
            .then(({data}) => {
                toast("Génération de QR code initialisé avec succès", {type: 'success'});
            })
            .catch(error => {
                toast("Oups! Une erreur est survenue, veuillez réessayer", {type: 'error'});
            })
            .finally(() => setSubmitting(false))
        ;
    }
    useEffect(() => {
        setLoadFilterData(true)
        axios.get(`/api/properties/create`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    etats: formatData(data.etats),
                    levels: formatData(data.levels),
                    origins : formatData(data.origins),
                    buildings: formatData(data.batiments),
                    locals: formatData(data.locals),
                    staffs: [],
                })
                setStandards(formatData(data.standards));
                setUsers((data?.staff || []).map(item => ({
                    value: item.id,
                    label: `${item?.first_name || '-'} ${item?.last_name || '-'}`
                })));
            })
            .catch(() => {
                console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)});

        axios.get(`/api/import-errors`)
            .then(({data}) => {
                setImportErrors(data)
            })
            .catch(() => {
                console.error("Something is wrong")})
            .finally(() => {setLoadFilterData(false)})
    }, []);

    return (
        <PageContent title={"Entrées"} pageTitle={"Gestions des biens"}>
            <div className={'mb-4 text-end'}>
                <button
                    className="btn btn-sm btn-primary"
                    disabled={ submitting }
                    onClick={() => exportPropertiesToAffExel('xlsx')}>
                    <i className="ri-download-2-line align-bottom"></i> { !submitting ? ' Exporter pour affecter' : '...'}
                </button>&nbsp;
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => showImportationModal('properties')}>
                    <i className="ri-add-line align-bottom"></i> Importer
                </button>&nbsp;
                <button
                    className="btn btn-sm btn-primary"
                    onClick={() => showImportationModal('affectation')}>
                    <i className="ri-user-add-line align-bottom"></i> Affectation
                </button>&nbsp;
                <button
                    className="btn btn-sm btn-primary"
                    disabled={ submitting }
                    onClick={() => generateQrCodes()}>
                    <i className="ri-qr-code-line"></i> Générer les QR Code
                </button>&nbsp;
                {
                    process.env.REACT_APP_ENV === 'local' && (
                        <>
                            <button
                                className="btn btn-sm btn-primary"
                                disabled={ submitting }
                                onClick={() => deleteDuplicates()}>
                                <i className="ri-recycle-line"></i> Supprimer les doublons
                            </button>&nbsp;
                        </>
                    )
                }

                {
                    importErrors?.length > 0 &&
                    <button
                        className="btn btn-sm btn-danger"
                        onClick={() => setShowImportModalErrors(true)}>
                        <i className="ri-error-warning-line align-bottom"></i> Erreur d'importation
                    </button>
                }
            </div>

            {/* <Card>
                <CardBody>
                    <Row className="mb-3">
                        <Col sm={12} lg={6} xl={4} className="mb-3">
                            <Label htmlFor="site" className="form-label">Site{filterData?.sites?.length}</Label>
                            <ReactSelect
                                id="site"
                                name="site"
                                placeholder="Veuillez sélectionner le site"
                                value={site}
                                onChange={handleSiteChange}
                                options={filterData?.sites}
                                isLoading={loadFilterData}
                            />
                        </Col>

                        <Col sm={12} lg={6} xl={4} className="mb-3">
                            <Label htmlFor="building" className="form-label">Batiment</Label>
                            <ReactSelect
                                id="building"
                                name="building"
                                placeholder="Veuillez sélectionner le batiment"
                                value={building}
                                onChange={handleBuildingChange}
                                options={filterData.buildings}
                                isLoading={loadFilterData}
                            />
                        </Col>

                        <Col sm={12} lg={6} xl={4} className="mb-3">
                            <Label htmlFor="local" className="form-label">Local</Label>
                            <ReactSelect
                                id="local"
                                name="local"
                                placeholder="Veuillez sélectionner le local"
                                value={local}
                                onChange={value => {setLocal(value)}}
                                options={filterData.locals}
                                isLoading={loadFilterData}
                            />
                        </Col>

                        <Col sm={12} lg={6} xl={4} className="mb-3">
                            <Label htmlFor="standard" className="form-label">Standard</Label>
                            <ReactSelect
                                id="standard"
                                name="standard"
                                placeholder="Veuillez sélectionner le standard"
                                value={standard}
                                onChange={value => {setStandard(value)}}
                                options={standards}
                                isLoading={loadFilterData}
                            />
                        </Col>
                        <Col sm={12} lg={6} xl={4}>
                            <Label htmlFor="staff" className="form-label">Utilisateurs</Label>
                            <ReactSelect
                                id="staff"
                                name="staff"
                                placeholder={'Tous les utilisateurs'}
                                value={staff}
                                onChange={handleStaffChange}
                                options={users}
                                isLoading={loadingCreate}
                            />
                        </Col>
                        <Col sm={12} lg={6} xl={4}>
                            <Label htmlFor="standard" className="form-label">Période d'enrégistrement</Label>
                            <Flatpickr
                                placeholder={"Période"}
                                className="form-control"
                                options={
                                    {
                                        //wrap: true, // set wrap to true only when using 'input-group'
                                        //altFormat: "M j, Y",
                                        //altInput: true,
                                        dateFormat: "Y-m-d",
                                        mode: "range",
                                        locale:French
                                    }
                                }
                                value={date}
                                onChange={handleDateChange}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>*/}


            <DatatablePageProvider
                title={""}
                endpoint={"/api/properties"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                actionLink={{
                    store: "/inputs/create",
                    edit: "/inputs/:id/edit",
                    detail: "/inputs/:id",
                }}
                storePermission={"store-property"}
                detailPermission={"show-property"}
                updatePermission={"edit-property"}
                deletePermission={"destroy-property"}
            >
                <Datatable/>
            </DatatablePageProvider>

            <Modal
                id={"import-modal"}
                isOpen={showImportModal}>
                <ModalHeader  className="p-3 bg-soft-info">
                   {importTarget === 'affectation' ? "Affecter les biens": " Importer les biens"}
                </ModalHeader>

                <ModalBody>
                    <div className="table-responsive">
                        {errorMessage.length > 0 && (
                            <div className="alert alert-danger" role="alert">
                                {errorMessage}
                            </div>
                        )}
                    </div>
                    <form>
                        <Row className={'g-3'}>
                            {
                                process.env.REACT_APP_ENV === 'local' || importTarget !== 'properties' && (
                                    <Col sm={12}>
                                        <Label htmlFor="file" className="form-label">Nombre de ligne</Label>
                                        <Input
                                            type="number"
                                            className="form-control"
                                            id="limit"
                                            validate={{
                                                required: {value: submitError && submitError.limit.length > 0},
                                            }}
                                            multiple={false}
                                            value={importRows}
                                            placeholder="Veuillez selectionner le fichier"
                                            onChange={(e) => setImportRows(e.target.value)}
                                            invalid={submitError && submitError.limit?.length > 0}
                                        />
                                        {submitError && submitError.limit?.length > 0 && (
                                            <FormFeedback type="invalid">
                                                {submitError.limit[0]}
                                            </FormFeedback>
                                        )}
                                    </Col>
                                )
                            }

                            <Col sm={12}>
                                <Label htmlFor="file" className="form-label">Fichier</Label>
                                <Input
                                    type="file"
                                    className="form-control"
                                    id="file"
                                    validate={{
                                        required: {value: submitError && submitError.file.length > 0},
                                    }}
                                    multiple={false}
                                    placeholder="Veuillez selectionner le fichier"
                                    onChange={e => handleFileChange(e)}
                                    invalid={submitError && submitError.file?.length > 0}
                                />
                                {submitError && submitError.file?.length > 0 && (
                                    <FormFeedback type="invalid">
                                        {submitError.file[0]}
                                    </FormFeedback>
                                )}
                            </Col>
                        </Row>
                        {
                            importTarget !== 'affectation' && 
                            <div className={"mt-2"}>
                            Télécharger <a href="#"
                                           onClick={() => downloadDataUrlFromJavascript('format_import_biens.xlsx', `${process.env.REACT_APP_API_HOST}/storage/model-files/format_import_biens.xlsx`)}
                                           className={"fw-bold"}>le format d'importation <i
                            className={"ri ri-download-2-line"}></i></a>
                        </div>
                        }
                       
                    </form>

                </ModalBody>
                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModal(false)}>
                        Annuler
                    </Button>

                    <button
                        type="button"
                        className="btn btn-primary btn-label right nexttab"
                        onClick={submitImport}
                        disabled={submitting || !file || !importRows}>
                        {submitting && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Traitement... </Spinner>
                        )}
                        {"Importer"}
                    </button>
                </ModalFooter>
            </Modal>
            <Modal
                id={"import-modal-errors"}
                size={"lg"}
                isOpen={showImportModalErrors}>
                <ModalHeader  className="p-3 bg-soft-info">
                    Erreur d'importation
                </ModalHeader>

                <ModalBody>

                 <div className={"p-4"}>
                     <div className={"text-danger text-center"}>
                         Veuillez corriger ces lignes et réessayer la réimportation
                     </div>
                     { importErrors?.map((error,index) =>
                         (
                             <Row key={`row-${index}`}>
                                 <h5>Sur la ligne N° {error.line}</h5>
                                 {<ul>
                                     {Object.values(error?.messages)?.map((msg,index) =>
                                         (<li key={`list-${index}`} className="text-danger">{msg[0]}</li>)
                                     )}

                                 </ul>}
                             </Row>
                         ))}

                 </div>
                </ModalBody>

                <ModalFooter>
                    <Button
                        color="light"
                        onClick={() => setShowImportModalErrors(false) }>
                        Annuler
                    </Button>
                </ModalFooter>
            </Modal>
        </PageContent>
    );
};

export default Properties;
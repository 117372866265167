import React, {useEffect, useState} from "react";
import ReactApexChart from "react-apexcharts";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Row, Spinner,
} from "reactstrap";
import {toast} from "react-toastify";
import env from "../../helpers/env";

import PageContent from "../../Layouts/PageContent";
import {useAxios, useFetchDataList} from "../../hooks";
//import Components
import TileBoxs from "./TileBoxs";

const Dashboard = () => {
    document.title ="Tableau de bord | "+env('NAME');
    const {axios} = useAxios();
    const {list} = useFetchDataList(`/api/dashboard/create`, data => {
        return {
            etats: data.etats.map(item => ({value: item.id, label: item.name}))
        }
    });

    const topPages = [
        {
            id: 1,
            page: "/themesbrand/skote-25867",
            active: "99",
            user: "25.3%"
        },
        {
            id: 2,
            page: "/dashonic/chat-24518",
            active: "86",
            user: "22.7%"
        },
        {
            id: 3,
            page: "/skote/timeline-27391",
            active: "64",
            user: "18.7%"
        },
        {
            id: 4,
            page: "/themesbrand/minia-26441",
            active: "53",
            user: "14.2%"
        },
        {
            id: 5,
            page: "/dashon/dashboard-29873",
            active: "33",
            user: "12.6%"
        },
        {
            id: 6,
            page: "/doot/chats-29964",
            active: "20",
            user: "10.9%"
        },
        {
            id: 7,
            page: "/minton/pages-29739",
            active: "10",
            user: "07.3%"
        },
    ];

    const [isTopPageDropdown, setTopPageDropdown] = useState(false);
    const [top, setTop] = useState("Top 5");
    const [serviceStatsLoading, setServiceStatsLoading] = useState(true);
    const toggleDropdown = () => { setTopPageDropdown(!isTopPageDropdown); };
    const [firstStat, setFirstStat] = useState({
        keys: [],
        values: []
    });

    const getEndNumber = () => {
        return firstStat.values.length;
    };

    const series = [
        {
            name: "Nombre de biens",
            data: firstStat.values.slice(0, getEndNumber()),
        },
    ];

    const options = {
        chart: {
            toolbar: {
                show: !1,
            },
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: !0,
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#adb5bd"],
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: firstStat.keys.slice(0, getEndNumber()),
            crosshairs: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            tooltip: {
                enabled: !0,
                offsetY: -35,
            },
        },
        fill: {
            gradient: {
                shade: "light",
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: !0,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100],
            },
        },
        title: {
            floating: !0,
            offsetY: 320,
            align: "center",
            style: {
                color: "#444",
                fontWeight: 500,
            },
        },
    };

    useEffect(() => {
        axios.get("/api/dashboard/service-ranger-stats")
            .then(({data}) => {
                setFirstStat({
                    keys: Object.keys(data),
                    values: Object.values(data)
                })
            })
            .catch(() => {console.log("response")})
            .finally(() => setServiceStatsLoading(false))
        ;
    }, []);

    return (
        <PageContent title={"Tableau de bord"} pageTitle={"Tableau de bord"}>
            <TileBoxs options={list.etats ? list.etats : []}/>
            <Row>
                <Col xl={12} md={12}>
                    <Card className="card-height-100">
                        <CardHeader className="border-bottom-dashed align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Répartition des biens par direction / service</h4>
                            <div>

                               {/* <UncontrolledDropdown className="card-header-dropdown" direction='left'>
                                   <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm" >
                                        <span className="text-uppercase">{top}<i className="mdi mdi-chevron-down align-middle ms-1"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem onClick={() => setTop("Top 5")}>Top 5</DropdownItem>
                                        <DropdownItem onClick={() => setTop("Top 10")}>Top 10</DropdownItem>
                                        <DropdownItem onClick={() => setTop("Top 20")}>Top 20</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>*/}

                            </div>
                        </CardHeader>

                        <CardBody>
                            {
                                serviceStatsLoading ?
                                    <Row className={"d-flex justify-content-center mb-2"}>
                                    <Spinner content={"Chargement..."} color={"gray"} />

                                    </Row> :
                                    <ReactApexChart
                                        options={options}
                                        series={series}
                                        type="bar"
                                        height={350}
                                    />
                            }
                        </CardBody>
                    </Card>
                </Col>

               {/* <Col xl={6} md={12} >
                    <Card className="card-height-100">
                        <CardHeader className="border-bottom-dashed align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">My Portfolio</h4>
                            <div>

                                <UncontrolledDropdown className="card-header-dropdown" direction='left'>
                                    <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm" >
                                        <span className="text-uppercase">Btc<i className="mdi mdi-chevron-down align-middle ms-1"></i></span>
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem>BTC</DropdownItem>
                                        <DropdownItem>USD</DropdownItem>
                                        <DropdownItem>Euro</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </div>
                        </CardHeader>

                        <CardBody>
                            <ReactApexChart
                                options={optionsTwo}
                                series={seriesTwo}
                                type="area"
                                height="350"
                                className="apex-charts"
                            />
                        </CardBody>
                    </Card>
                </Col>*/}
            </Row>
        </PageContent>
    );
};

export default Dashboard;
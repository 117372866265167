import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Date, Status, Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";
import {Card, Col, Row} from "reactstrap";

const Inventories = () => {
    document.title ="Inventaires | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Date",
                accessor: "date",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Exercice comptable",
                accessor: "fiscal_year.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Statut",
                accessor: "status",
                filterable: false,
                Cell: (cellProps) => {
                    return <Status  {...cellProps} />;
                },
            },
        ]
    }, []);
    const [fiscalYears, setFiscalYears] = useState(null);
    const [inventoriste, setInventoriste] = useState(null);
    const [buildings, setBuildings] = useState([]);
    const [locals, setLocals] = useState([]);
    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [otherData, setOtherData] = useState([]);
    const [local, setLocal] = useState(null);
    const {list, loading} = useFetchDataList('/api/inventories/create', (data) => {
        function formatData (data) {
            return data.map(item => {
                return {
                    ...item,
                    label: item.name,
                    value: item.id,
                }
            });
        }

        return {
            inventorists: formatData(data.inventorists),
            sites: formatData(data.sites),
            fiscalYears: formatData(data.fiscalYears),
        };
    });
    const remainingLocals = useFetchDataList('/api/inventories/configs/', (data) => {
        return {
            remainingLocals: data.remainingLocals,
            inventory : data.inventory
        };
    });
    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter un inventaire',
                update: 'Modifier un inventaire'
            },
            formDefaultError: {name: [], date: [], fiscal_year_id: []},
            initialValues: {name: '', date: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                date: Yup.string().required("Veuillez entrer la date"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    fiscal_year_id: fiscalYears ? fiscalYears.value : '',
                    configs: otherData.map(item => ({
                        user_id: item.inventorists.id,
                        locals: item.locals.map(item => item.id),
                    }))
                };
            },
            formatResponse: data => {
                const newData = {...data};
                newData['fiscal_year'] = fiscalYears;
                return newData;
            },
            onSuccess: (response, type) => {
                setFiscalYears(null);
            },
            onFail: (response, type) => {},
        };
    }, [fiscalYears, otherData]);

    const onUpdateEntity = useCallback((editData) => {
        let result = (list?.fiscalYears || []).filter(item => item.value === editData.fiscal_year_id);
        result = result.length ? result[0] : null;
        if (result) {
            setFiscalYears(result);
        } else {
            setFiscalYears(null);
        }
    }, [list]);

    const onAddEntity = useCallback(() => {
        setFiscalYears(null);
    }, []);

    return (
        <PageContent title={"Inventaires"} pageTitle={"Inventaires"}>
            {/*{
                remainingLocals?.list?.inventory?.id && remainingLocals?.list?.remainingLocals?.length ? (
                    <>
                        <Card className={"p-2"}>
                            <div className={'text-danger mb-4 fw-bold'}>
                                Certains locaux ne sont pas encore assignés pour l'inventaire en cours
                            </div>
                            <Row >
                                {
                                    remainingLocals.list?.remainingLocals.map((local) => (
                                        <>
                                            <Col md={4} className={'text-danger'}>{local?.name}</Col>
                                        </>
                                    ))
                                }
                            </Row>
                        </Card>
                    </>
                ) : ''
            }*/}

            <DatatablePageProvider
                title={""}
                endpoint={"/api/inventories"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                detailPermission={"list-property-inventory"}
                actionLink={
                {detail: "/inventories/:id",inventorist : "#"}
                }
                storePermission={"store-inventory"}
                updatePermission={"edit-inventory"}
                deletePermission={"destroy-inventory"}
                activeExercise={
                    {
                        text: "Activer",
                        endpoint: "/api/active-inventory/:id/enable",
                        permissions: ["activate-inventory"],
                        actionIf: [
                            {
                                key: "is_active",
                                value: 0,
                            },
                            {
                                key: "status",
                                value: "not_started"
                            }
                        ]
                    }
                }
            >
                <Datatable
                    addText={"Créer un nouvel inventaire"}
                    fiscalYear={fiscalYears}
                    setFiscalYears={setFiscalYears}
                    list={list}
                    site={site}
                    setSite={setSite}
                    building={building}
                    setBuilding={setBuilding}
                    local={local}
                    setLocal={setLocal}
                    inventoriste={inventoriste}
                    setInventoriste={setInventoriste}
                    loading={loading}
                    buildings={buildings}
                    setBuildings={setBuildings}
                    locals={locals}
                    setLocals={setLocals}
                    otherData={otherData}
                    setOtherData={setOtherData}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Inventories;
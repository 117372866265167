import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import { configureStore } from "./store";
import {AxiosProvider} from "./providers/AxiosProvider";
import {AuthProvider} from "./providers/AuthProvider";
import {ToastContainer} from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={configureStore({})}>
        <React.Fragment>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <AxiosProvider>
                    <AuthProvider>
                        <App />

                        <ToastContainer />
                    </AuthProvider>
                </AxiosProvider>
            </BrowserRouter>
        </React.Fragment>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
import React, {useCallback, useEffect, useState} from "react";
import ReactSelect from "../Common/ReactSelect";
import {useAxios} from "../../hooks";
import {toast} from "react-toastify";
import {DropdownItem, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from "reactstrap";

const PropertySearch = ({value = null, onChange, placeholder = "", error = [], type = null}) => {
    const {axios} = useAxios();
    const [array, setArray] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState()

    const fetchDataBySearchValue = useCallback((searchValue = "") => {
        if (type && typeof type === "string") {
            setLoading(true);
            axios.get(`api/search/${type}/properties?search=${searchValue}`)
                .then(({data}) => {
                    setArray(data.map(item => ({
                        value: item.id,
                        label: `${item.standard?.name} (${item?.codification})/${item.code_immo} /${item.reference}`
                    })))
                })
                .catch(() => toast.error("Something went wrong"))
                .finally(() => {setLoading(false)})
            ;
        }
    }, [type]);

    useEffect(() => {
        if (value) {
            setInputValue(value.label)
        }
    }, [value]);

    useEffect(() => {
        fetchDataBySearchValue("", () => {});
    }, [fetchDataBySearchValue]);

    const searchValue = useCallback(() => {
        if (inputValue) {
            fetchDataBySearchValue(inputValue)
        }
    }, [fetchDataBySearchValue, inputValue, value])

    return (
        <div>
            <div className="input-group">
                <Input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder="Code Immo, Code ERP (compta), Standard"
                    value={inputValue}
                    onChange={e => setInputValue(e.target.value)}
                />
                <UncontrolledDropdown>
                    <DropdownToggle onClick={searchValue} tag="button" className="btn btn-primary" id="dropdownMenuButton">
                        Rechercher
                    </DropdownToggle>
                    <DropdownMenu style={{minWidth: "600px"}}>
                        {loading ? (
                            <button className="dropdown-item">Recherche en cours...</button>
                        ) : (
                            <>
                                {!array.length ? (
                                    <button className="dropdown-item">Pas de résultat pour ${inputValue}</button>
                                ) : (
                                    array.map((item ,index) => (
                                        <DropdownItem onClick={() => {onChange(item)}} key={index}>{item.label}</DropdownItem>
                                    ))
                                )}
                            </>
                        )}


                    </DropdownMenu>
                </UncontrolledDropdown>
            </div>
        </div>
    );
};

export default PropertySearch;
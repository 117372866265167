import React, {Fragment, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import { useTable, usePagination } from 'react-table'
import {Table, Row, Col, Button, Input, CardBody, Spinner} from "reactstrap";

const DbDatatable = ({columns, data, totalPage = 1, perPage = 10, loading = false, fetchData}) => {
    const {
        getTableProps, getTableBodyProps, headerGroups,
        prepareRow, page, canPreviousPage, canNextPage, pageOptions,
        pageCount, gotoPage, nextPage, previousPage, setPageSize,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data: data,
        initialState: {
            pageIndex: 0,
            pageSize: perPage,
        },
        pageCount: totalPage,
        manualPagination: true,
    }, usePagination);

    const [value, setValue] = useState("");

    useEffect(() => {
        fetchData(pageIndex + 1, pageSize, value);
    }, [fetchData, pageIndex, pageSize, value]);

    const handleValueChange = (e) => {
        setValue(e.target.value);
    };

    const generateSortingIndicator = (column) => {
        return column.isSorted ? (column.isSortedDesc ? " " : "") : "";
    };

    const onChangeInSelect = (event) => {
        setPageSize(Number(event.target.value));
    };
    const onChangeInInput = (event) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        gotoPage(page);
    };

    return (
        <Fragment>
            <Row className="mb-3">
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <form>
                        <Row>
                            <Col sm={3}>
                                <div className={"search-box me-2 mb-2 d-inline-block col-12"}>
                                    <input
                                        onChange={handleValueChange}
                                        id="search-bar-0"
                                        type="text"
                                        className="form-control search /"
                                        placeholder={`Rechercher...`}
                                        value={value || ""}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </CardBody>
            </Row>


            <div className={`table-responsive table-card mb-3 relative`}>
                {loading && (
                    <div className="position-absolute h-100 w-100 d-flex justify-content-center align-items-center" style={{top: "0"}}>
                        <Spinner color={"info"} size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                    </div>
                )}

                <Table {...getTableProps()} className="align-middle table-nowrap table-striped mb-0">
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th key={column.id} {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                    <span>
                                        {column.isSorted
                                            ? column.isSortedDesc
                                                ? ' 🔽'
                                                : ' 🔼'
                                            : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <Fragment key={row.getRowProps().key}>
                                <tr>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td key={cell.id} {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </Fragment>
                        );
                    })}
                    </tbody>
                </Table>
            </div>

            <div className="d-flex align-items-center justify-content-between">
                <Row>
                    <select
                        className="form-select"
                        value={pageSize}
                        onChange={onChangeInSelect}
                    >
                        {[10, 20, 30, 40, 50].map((pageSize) => (
                            <option key={pageSize} value={pageSize}>
                                Afficher {pageSize}
                            </option>
                        ))}
                    </select>
                </Row>

                <Row className="justify-content-md-end justify-content-center align-items-center p-2">
                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button
                                color="primary"
                                onClick={previousPage}
                                disabled={!canPreviousPage}
                            >
                                {"<"}
                            </Button>
                        </div>
                    </Col>
                    <Col className="col-md-auto d-none d-md-block">
                        Page{" "}
                        <strong>
                            {pageIndex + 1} sur {pageOptions.length}
                        </strong>
                    </Col>
                    <Col className="col-md-auto">
                        <Input
                            type="number"
                            min={1}
                            style={{ width: 70 }}
                            max={pageOptions.length}
                            defaultValue={pageIndex + 1}
                            onChange={onChangeInInput}
                        />
                    </Col>

                    <Col className="col-md-auto">
                        <div className="d-flex gap-1">
                            <Button color="primary" onClick={nextPage} disabled={!canNextPage}>
                                {">"}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
};

DbDatatable.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default DbDatatable;
import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {FullName, Roles, Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";
import {Badge} from "reactstrap";

const Users = () => {
    document.title = "Utilisateurs | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Nom",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Email",
                accessor: "email",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
          /*  {
                Header: "Agent",
                accessor: "staff",
                filterable: false,
                Cell: (cellProps) => {
                    return <FullName {...cellProps} />;
                },
            },*/
            {
                Header: "Role(s)",
                accessor: "roles",
                filterable: false,
                Cell: (cellProps) => {
                    return <Roles {...cellProps} />;
                },
            },
           /* {
                Header: "Statut",
                accessor: "is_disable",
                filterable: false,
                Cell: (cellProps) => {
                    return (
                        <> {cellProps.row.original.is_disable ? <Badge className={"rounded-pill"}>désactivé</Badge> : <Badge className={"rounded-pill"} color={"primary"}>actif</Badge>} </>
                    );
                },
            },*/
        ]
    }, []);

    const [staff, setStaff] = useState(null);
    const [role, setRole] = useState(null);
    const {list, setList, loading} = useFetchDataList(`/api/users/create`, (data) => {
        return {
            staffs: data['staffs'].map(item => {
                return {
                    label: `${item.last_name} ${item.first_name}`,
                    value: item.id,
                };
            }),
            roles: data['roles'].map(item => {
                return {
                    label: item.name,
                    value: item.name,
                };
            }),
        };
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: "Ajouter d'un utilisateur",
                update: "Modification d'un utilisateur",
            },
            formDefaultError: {name: [], email: [], password: [], roles : []},
            initialValues: {name: '', email: '', password: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                email: Yup.string().required("Veuillez entrer l'email"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    roles: role ? role.map(item => item.value) : [],
                    staff_id: staff ? staff.value : ''
                };
            },
            formatResponse: data => {
                return {
                    ...data,
                    staff: {
                        id: staff?.value,
                        last_name: staff?.label,
                        first_name: "",
                    }
                };
            },
            onSuccess: (response, type) => {
                setList({
                    ...list,
                    staffs: list['staffs'].filter(item => item.value !== staff?.value)
                });
                setRole(null);
                setStaff(null);
            },
            onFail: (response, type) => {},
        };
    }, [list, role, setList, staff]);

    const onUpdateEntity = useCallback((editData) => {
        let result = editData['roles'] ? editData['roles'].map(item => {
            return {
                value: item.name,
                label: item.name
            };
        }) : [];
        if (result) {
            setRole(result);
        } else {
            setRole(null);
        }
        if (editData.staff) {
            setStaff({
                value: editData.staff.id,
                label: `${editData.staff.last_name} ${editData.staff.first_name}`
            });
        } else {
            setStaff(null);
        }
    }, []);

    const onAddEntity = useCallback(() => {
        setRole(null);
        setStaff(null);
    }, []);

    return (
        <PageContent title={"Utilisateurs"} pageTitle={"Configuration"}>
            <DatatablePageProvider
                title={""}
                endpoint={"/api/users"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-user"}
                updatePermission={"edit-user"}
                deletePermission={"destroy-user"}
                actionIf={{
                    delete: {
                        key: 'is_deletable',
                        value: 1,
                    }
                }}
            >
                <Datatable
                    role={role}
                    setRole={setRole}
                    loading={loading}
                    roles={list['roles'] ? list['roles'] : []}
                    staff={staff}
                    setStaff={setStaff}
                    staffs={list['staffs'] ? list['staffs'] : []}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Users;
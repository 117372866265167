import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table} from "reactstrap";
import {InputRequire} from "../../Components/personal/utils";
import ReactSelect from "../../Components/Common/ReactSelect";
import {useAxios} from "../../hooks";
import {toast} from "react-toastify";

const DifferenceForm = ({toggle, open, property, loading, users, locals, states}) => {
    const {axios} = useAxios();
    const defaultError = {
        local_id: [],
        etat_id: [],
        user_id: []
    };
    const [submitError, setSubmitError] = useState(defaultError);
    const [desktop, setDesktop] = useState(null);
    const [state, setState] = useState(null);
    const [user, setUser] = useState(null);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        setDesktop(property ? {
            value: property?.current_local?.id,
            label: property?.current_local?.name
        } : null);

        setState(property ? {
            value: property?.current_etat?.id,
            label: property?.current_etat?.name
        } : null);

        setUser(property ? {
            value: property?.current_user?.id,
            label: `${property?.current_user?.last_name} ${property?.current_user?.first_name}`
        } : null);
    }, [property]);

    const submitForm = () => {
        const sendingData = {
            user_id: user ? user.value : null,
            local_id: desktop ? desktop.value : null,
            etat_id: state ? state.value : null,
            property_id: property ? property?.property_id : null,
            id:  property?.id,
        };

        setSending(true);
        axios.post(`api/properties-inventories-validate`, sendingData)
            .then(({data}) => {
                toast.success("Ecart validé avec succès.");
                toggle()
                window.location.reload()
            })
            .catch(({response}) => {
                console.log("error:", response.error);
            })
            .finally(() => {
                setSending(false);
            })
        ;
    }

    return (
        <Modal id="Difference" isOpen={open} centered toggle={toggle} size="md" className="border-0" modalClassName="zoomIn">
            <ModalHeader toggle={toggle} className="p-3 bg-soft-info">
                Ecart
            </ModalHeader>

            <ModalBody>
                <Row className="g-3">
                    <Col sm={12}>
                        <div className="table-responsive">
                            <h5 className="text-center">Ancien statut</h5>

                            <Table className="align-middle table-nowrap mb-0">
                                <tbody>
                                <tr>

                                    <th>Bureau</th>
                                    <td>{property?.old_local?.name || '--'}</td>
                                </tr>
                                <tr>
                                    <th>Etat du bien</th>
                                    <td>{property?.old_etat?.name || "--"}</td>
                                </tr>
                                <tr>
                                    <th>Utilisateur</th>
                                    <td>{`${property?.old_user?.last_name || '-'} ${property?.old_user?.first_name || '-'}`}</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </Col>

                    <Col sm={12}>
                    <h5 className="text-center">Constat</h5>
                    </Col>

                    {
                        property?.current_local  && property?.current_local_id !== property?.old_local_id? (
                            <Col md={12} className={'bg-soft-danger p-2'}>
                                <Label htmlFor="local_id" className="form-label text-danger">Bureau<InputRequire/></Label>
                                <ReactSelect
                                    id="local_id"
                                    name="local_id"
                                    placeholder="Veuillez sélectionner le bureau"
                                    value={desktop}
                                    onChange={value => setDesktop(value)}
                                    options={locals}
                                    isLoading={loading}
                                    error={submitError.local_id}
                                />
                            </Col>
                        ) : <Col md={12}></Col>
                    }
                    {
                        (property?.current_etat?.id  !==null && property?.current_etat?.id !==  property?.old_etat?.id && property?.current_etat) &&(
                            <Col md={12} className={'bg-soft-danger p-2'}>
                                <Label htmlFor="local_id" className="form-label text-danger">Etat du bien <InputRequire/></Label>
                                <ReactSelect
                                    id="local_id"
                                    name="local_id"
                                    placeholder="Veuillez sélectionner l'état du bien"
                                    value={state}
                                    onChange={value => setState(value)}
                                    options={states}
                                    isLoading={loading}
                                    error={submitError.etat_id}
                                />
                            </Col>
                        )
                    }
                    {
                        (property?.current_user_id !== null && property?.current_user?.id !== property?.old_user?.id) && (

                            <Col md={12}  className={'bg-soft-danger p-2'}>
                                <Label  htmlFor="local_id" className="form-label text-danger">Utilisateur <InputRequire/></Label>
                                <ReactSelect
                                    id="user_id"
                                    name="user_id"
                                    placeholder="Veuillez sélectionner l'utilisateur"
                                    value={user}
                                    onChange={(value) => setUser(value)}
                                    options={users}
                                    isLoading={loading}
                                    error={submitError.etat_id}
                                />
                            </Col>
                        )
                    }
                </Row>
                { property?.observations && (
                    <>
                        <div className={"text-center text-decoration-underline m-2"}>
                            Observation relevée par l'inventoriste
                        </div>
                        <Row className={"justify-content-center bg-soft-dark p-2"}>
                            {property?.observations}
                        </Row>
                    </>
                    )

                }

                <Row  className={"m-4"}>
                    {
                        property && property.status === 'validated' && (
                            <div className={" text-info text-center"}>Cet écart est déjà validé</div>
                        )
                    }
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="light" onClick={toggle}>
                    Annuler
                </Button>
                <button type="submit" className="btn btn-success" id="add-btn" onClick={submitForm}>
                    <span className="d-flex align-items-center justify-content-center">
                        {sending && (
                            <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                        )}
                        <span className="ms-2">{sending ? "Chargement" : "Valider"}</span>
                    </span>
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default DifferenceForm;
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

// Import Images
import error from "../../assets/images/error.svg";
import env from "../../helpers/env";
import {useAuth} from "../../hooks";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

const Error404 = () => {
    document.title ="404 Error | "+env('NAME');

    const auth = useAuth();
    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <ParticlesAuth>
                    <div className="auth-page-content">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center pt-4">
                                        <div className="">
                                            <img src={error} alt="" className="error-basic-img move-animation" />
                                        </div>
                                        <div className="mt-n4">
                                            <h1 className="display-1 fw-medium">404</h1>
                                            <h3 className="text-uppercase">Désolé, page non trouvée 😭</h3>
                                            <p className="text-muted mb-4">La page que vous recherchez n'est pas disponible!</p>
                                            <Link to={auth.user ? '/dashboard' : '/login'} className="btn btn-success"><i className="mdi mdi-home me-1"></i>Retour à l'accueil</Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </ParticlesAuth>
            </div>
        </React.Fragment>
    );
};

export default Error404;
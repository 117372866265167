import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";

const Sites = () => {
    document.title ="Sites | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Code",
                accessor: "code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Dénomination",
                accessor: "name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Ville",
                accessor: "city.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);
    const [city, setCity] = useState(null);
    const {list, loading} = useFetchDataList('/api/sites/create', (data) => {
        return data.map(item => {
            return {
                label: item.name,
                value: item.id,
            }
        });
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter un site',
                update: 'Modifier un site'
            },
            formDefaultError: {name: [], code: [], city_id: []},
            initialValues: {name: '', code: ''},
            validationSchema: Yup.object({
                name: Yup.string().required("Veuillez entrer le nom"),
                code: Yup.string().required("Veuillez entrer le code"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    city_id: city ? city.value : ''
                };
            },
            formatResponse: data => {
                let result = list.filter(item => item.value === data.city_id);
                result = result.length ? result[0] : null;
                return {
                    ...data,
                    city: {
                        name: result ? result.label : ''
                    }
                };
            },
            onSuccess: (response, type) => {
                setCity(null);
            },
            onFail: (response, type) => {},
        };
    }, [city, list]);

    const onUpdateEntity = useCallback((editData) => {
        let result = list.filter(item => item.value === editData.city_id);
        result = result.length ? result[0] : null;
        if (result) {
            setCity(result);
        } else {
            setCity(null);
        }
    }, [list]);

    const onAddEntity = useCallback(() => {
        setCity(null);
    }, []);

    return (
        <PageContent title={"Sites"} pageTitle={"Localisation"}>
            <DatatablePageProvider
                title={"Sites"}
                endpoint={"/api/sites"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                onUpdateEntity={onUpdateEntity}
                onAddEntity={onAddEntity}
                storePermission={"store-site"}
                updatePermission={"edit-site"}
                deletePermission={"destroy-site"}
            >
                <Datatable
                    city={city}
                    setCity={setCity}
                    loading={loading}
                    cities={list}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Sites;
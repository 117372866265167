import React, {createContext, useCallback, useMemo, useState} from "react";
import {toast} from "react-toastify";
import {useFormik} from "formik";
import {useAxios} from "../hooks";
import {GLOBAL_ERROR_MESSAGE} from "../constants";
import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {userHavePermissions} from "../helpers";
import {Link} from "react-router-dom";

export const DatatablePageContext = createContext({});

export const DatatablePageProvider = ({children,
                                          entityForm = null,
                                          descriptionRowTable = [],
                                          endpoint = "",
                                          onUpdateEntity = null,
                                          onAddEntity = null,
                                          title = "",
                                          storePermission = "",
                                          detailPermission = "",
                                          updatePermission = "",
                                          reopenPermission = "",
                                          closePermission = "",
                                          deletePermission = "",
                                          closeOrOpenFiscalYear,
                                          actionIf = null, formatList = null, actionLink = null , activeExercise = null, queryString = null}) => {
    // Load Axios
    const request = useAxios();

    // Entities List
    const [entities, setEntities] = useState(null);
    const [entitiesLoading, setEntitiesLoading] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [entity, setEntity] = useState(null);

    // Delete
    const [deleteModal, setDeleteModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [deleting, setDeleting] = useState(false);

    // Useful data to form
    const formDefaultError = useMemo(() => {
        return (entityForm && entityForm['formDefaultError']) ? entityForm['formDefaultError'] : []
    }, [entityForm]);
    const [formError, setFormError] = useState(formDefaultError);
    const [sending, setSending] = useState(false);

    const toggle = useCallback(() => {
        if (entity) {
            setEntity(null);
        }
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [entity, modal]);

    // Fetch Data
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "") => {
        setEntitiesLoading(true);
        request.axios.get(`${endpoint}?page=${pageIndex}&per_page=${pageSize}&search=${search}${queryString ? "&"+queryString : ""}`)
            .then(response => {
                console.log({response})
                setEntities(formatList ? formatList(response.data) : response.data);
            })
            .catch(error => {
                const condition = error?.response?.data?.error && typeof error.response.data.error === "string";
                toast.error(condition ? error?.response?.data?.error : GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })
            })
            .finally(() => setEntitiesLoading(false))
        ;
    }, [endpoint, queryString, formatList, request.axios]);

    const generateInitialValues = useCallback(() => {
        let initialValues = (entityForm && entityForm['initialValues']) ? entityForm['initialValues'] : {};
        let newInitialValues = {};
        Object.keys(initialValues).forEach(item => {
            newInitialValues[item] = (entity && entity[item]) || ''
        });
        return newInitialValues;
    }, [entity, entityForm]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: generateInitialValues(),
        validationSchema: (entityForm && entityForm['validationSchema']) ? entityForm['validationSchema'] : {},
        onSubmit: (values) => {
            const formData = (entityForm && entityForm['formatFormData']) ? entityForm['formatFormData'](values) : {};
            const execRequest = (type, endpoint) => {
                setSending(true);
                const newFormData = new FormData();
                newFormData.append("_method", type === "store" ? "post" : "put");
                for (const key in formData) {
                    if (["company_logo"].includes(key)) {
                        newFormData.append(key, formData[key]);
                    } else {
                        newFormData.set(key, formData[key]);
                    }
                }

                request.axios[type === "store" ? 'post' : 'put'](endpoint, Object.keys(formData).includes("company_logo") ? newFormData : formData)
                    .then(response => {
                        let data = response.data
                        if (entityForm && entityForm['formatResponse']) {
                            data = entityForm['formatResponse'](response.data);
                        }

                        if (entityForm && entityForm['onSuccess']) {
                            entityForm['onSuccess'](data, type);
                        }
                        updateEntities(data, type);
                        validation.resetForm();
                    })
                    .catch(error => {
                        if (entityForm && entityForm['onFail']) {
                            entityForm['onFail'](error, type);
                        }

                        if (error.response && error.response.status === 500) {
                            toast.error(`Echec de ${type === "store" ? "l'enregistrement" : "la modification"}`);
                        }
                        if (error.response && error.response.status === 422) {
                            toast.error(error.response.data.message);
                            setFormError({...formDefaultError, ...error.response.data.errors});
                        }
                    })
                    .finally(() => setSending(false))
                ;
            };

            if (isEdit) {
                // update entity
                execRequest("update", `${endpoint}/${entity.id}`);
            } else {
                // save new entity
                execRequest("store", endpoint);
            }
        },
    });

    // Refresh Entities
    const updateEntities = useCallback((newEntity, type = null) => {
        setFormError(formDefaultError);
        if (type === "update") {
            const newEntities = {...entities};
            newEntities.data = newEntities.data.map(item => item.id === newEntity.id ? newEntity : item);
            setTimeout(() => {
                setEntities(newEntities);
                toast("Succès de la modification", {type: 'success'});
            }, 300);
        } else {
            const newEntities = {...entities};
            newEntities.data = [newEntity, ...newEntities.data];
            setTimeout(() => {
                setEntities(newEntities);
                toast("Succès de l'enregistrement", {type: 'success'});
            }, 300);
        }
        toggle();
    }, [formDefaultError, entities, toggle]);

    // Delete Entity
    const onClickDelete = useCallback((entity) => {
        setEntity(entity);
        setDeleteModal(true);
    }, []);

    // Delete Entity
    const handleDeleteEntity = useCallback(() => {
        if (entity) {
            setDeleting(true);
            request.axios.delete(`${endpoint}/${entity.id}`)
                .then(() => {
                    const newEntities = {...entities};
                    newEntities.data = newEntities.data.filter(item => item.id !== entity.id);
                    setTimeout(() => {
                        setEntities(newEntities);
                        toast("Succès de la supression", {type: 'success'});
                        setDeleteModal(false);
                    }, 300);
                })
                .catch((error) => {
                    if ((error.response.status === 419) && (typeof error.response.data.error === "string")) {
                        toast.error(error.response.data.error);
                    } else {
                        console.log("Something is wrong")
                    }
                })
                .finally(() => setDeleting(false))
            ;
        }
    }, [endpoint, entities, entity, request.axios]);

    // Update Data
    const updateEntity = useCallback((editEntity) => {
        setEntity(editEntity);
        if (onUpdateEntity) {
            onUpdateEntity(editEntity, setEntity);
        }

        setIsEdit(true);
        toggle();
    }, [onUpdateEntity, toggle]);

    const toggleExercise = (row, e) => {
        e.preventDefault();
        request.axios.put(activeExercise.endpoint.replace(':id', row.id))
            .then(response => {
                window.location.reload();
            })
            .catch(({response}) => {
                toast.error(typeof response.data.error === "string" ? response.data.error : "Echec d'activation de l'exercice")
            })
        ;
    }

    const columns = useMemo(() => {

        if (userHavePermissions([updatePermission, deletePermission], "oneIn")) {
            return [
                ...descriptionRowTable,
                {
                    Header: "Actions",
                    accessor: "is_editable",
                    Cell: (cellProps) => {
                        const button = (
                            <DropdownToggle tag="a" className="btn btn-soft-secondary btn-sm">
                                <i className="ri-more-fill align-middle"></i>
                            </DropdownToggle>
                        );

                        const detail = (
                            <li>
                                {userHavePermissions([detailPermission]) && (actionLink !== null && "detail" in actionLink) ? (
                                    <Link
                                        to={actionLink.detail ? actionLink.detail.replace(":id", cellProps.row.original.id) : "/#"}
                                        className="edit-item-btn dropdown-item">
                                        <i className="ri-eye-2-line align-bottom me-2 text-muted"></i> Détail
                                    </Link>
                                ) : (
                                    <DropdownItem
                                        className="edit-item-btn"
                                        href="#showModal"
                                        data-bs-toggle="modal"
                                        onClick={() => {}}>
                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Détail
                                    </DropdownItem>
                                )}
                            </li>
                        );

                        const close = (
                            <li>
                                <DropdownItem
                                    className="remove-item-btn"
                                    data-bs-toggle="modal"
                                    href="#deleteOrder"
                                    onClick={() => {
                                        closeOrOpenFiscalYear(cellProps.row.original)
                                    }}>
                                    <i className="ri-lock-line align-bottom me-2 text-muted"/> {cellProps.row.original.closed_at != null? 'Réouvrir' :'Clôturer'}
                                </DropdownItem>
                            </li>
                        );

                        const exercise = (activeExercise ?
                            <li>

                                <a
                                    className="edit-item-btn dropdown-item"
                                    href="/#active"
                                    data-bs-toggle="modal"
                                    onClick={(e) => toggleExercise(cellProps.cell.row.original, e)}
                                    >
                                    <i className="ri-checkbox-line align-bottom me-2 text-muted"></i> {activeExercise?.text}
                                </a>
                            </li>
                        : null);

                        const update = (
                            <li>
                                {(actionLink !== null && "edit" in actionLink) ? (
                                    <Link
                                        to={actionLink.edit ? actionLink.edit.replace(":id", cellProps.row.original.id) : "/#"}
                                        className="edit-item-btn dropdown-item"
                                    >
                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Modifier
                                    </Link>
                                ) : (
                                    <DropdownItem
                                        className="edit-item-btn"
                                        href="#showModal"
                                        data-bs-toggle="modal"
                                        onClick={() => { const editEntity = cellProps.row.original; updateEntity(editEntity); }}>
                                        <i className="ri-pencil-fill align-bottom me-2 text-muted"></i> Modifier
                                    </DropdownItem>
                                )}
                            </li>
                        );

                        const destroy = (
                            <li>
                                <DropdownItem
                                    className="remove-item-btn"
                                    data-bs-toggle="modal"
                                    href="#deleteOrder"
                                    onClick={() => {
                                        const entityData = cellProps.row.original;
                                        onClickDelete(entityData);
                                    }}>
                                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"/> Supprimer
                                </DropdownItem>
                            </li>
                        );

                        const canShowExercise = (dataCondition) => {
                            let show = true;
                            const row = cellProps.cell.row.original;
                            dataCondition.forEach(item => {
                                show = show && row[item.key] === item.value;
                            });
                            return show;
                        }

                        return (
                            <UncontrolledDropdown    direction="end">
                                {
                                    actionIf ? (
                                        cellProps.cell.row.original[actionIf?.update?.key] === actionIf?.update?.value || cellProps.cell.row.original[actionIf?.delete?.key] === actionIf?.delete?.value ? button : null
                                    ) : button
                                }

                                <DropdownMenu className="dropdown-menu-end">
                                    {(activeExercise !== null && userHavePermissions(activeExercise.permissions, "oneIn")) && (
                                        <>
                                            {
                                                canShowExercise(activeExercise.actionIf) ? exercise : null
                                            }
                                        </>
                                    )}
                                    {userHavePermissions([detailPermission]) && (
                                        <>
                                            {actionIf ? (
                                                cellProps.cell.row.original[actionIf?.detail?.key] === actionIf?.detail?.value ? detail : null
                                            ) : detail}
                                        </>
                                    )}

                                    {userHavePermissions([updatePermission]) && (
                                        <>
                                            {actionIf ? (
                                                cellProps.cell.row.original[actionIf?.update?.key] === actionIf?.update?.value ? update : null
                                            ) : update}
                                        </>
                                    )}
                                    {closePermission.length > 1 && userHavePermissions([closePermission]) && (
                                        <>
                                            {closePermission.length ? (
                                                cellProps.cell.row.original[actionIf?.close?.key] === actionIf?.close?.value ? close : null
                                            ) : close}
                                        </>
                                    )}

                                    {userHavePermissions([deletePermission]) && (
                                        <>
                                            {actionIf ? (
                                                cellProps.cell.row.original[actionIf?.delete?.key] === actionIf?.delete?.value ? destroy : null
                                            ) : destroy}
                                        </>
                                    )}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        );
                    },
                },
            ];
        }
        return descriptionRowTable;
    }, [deletePermission, descriptionRowTable, onClickDelete, updateEntity, updatePermission]);

    const value = useMemo(() => {
        return {
            deleteModal,
            handleDeleteEntity,
            setDeleteModal,
            setFormError,
            defaultFormError: formDefaultError,
            deleting,
            setIsEdit,
            isEdit,
            toggle,
            columns: descriptionRowTable.length ? columns : [],
            entities,
            entitiesLoading,
            fetchData,
            modal,
            setModal,
            validation,
            formError,
            sending,
            setEntity,
            onAddEntity,
            title: title ? title : '',
            actionLink,
            formTitle: entityForm && entityForm['title'] ? entityForm['title'] : {
                store: 'Enregistrement',
                update: 'Modification'
            },
            storePermission, updatePermission, deletePermission
        }
    }, [columns, actionLink, storePermission, updatePermission, deletePermission, deleteModal, deleting, descriptionRowTable.length, onAddEntity, entities, entitiesLoading, entityForm, fetchData, formDefaultError, formError, handleDeleteEntity, isEdit, modal, sending, toggle, validation, title]);

    return (
        <DatatablePageContext.Provider value={value}>
            {children}
        </DatatablePageContext.Provider>
    );
};
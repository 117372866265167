import {Row} from "reactstrap";
import React, {useMemo} from "react";
import DataTable from 'react-data-table-component';
import {formatDate} from "../../../helpers";
import DbDatatable from "../../Common/DbDatatable";
import {CodeQR, Date, Text} from "../../Common/ColTableFormat";


const PresentPropertiesList = (({data, entitiesLoading, fetchData}) => {
    const columns = useMemo(() => {
        return [
            {
                Header: "Code ERP",
                accessor: "reference",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Codification",
                accessor: "codification",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard",
                accessor: "standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code immo",
                accessor: "code_immo",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Local",
                accessor: "assignment_active.local.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Date d'acquisition",
                accessor: "date_of_purchase",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
        ]
    }, []);

    return (
            <Row className={'p-4'}>
                {
                    <DbDatatable
                        columns={columns}
                        data={data ? data.data : []}
                        className="custom-header-css"
                        loading={entitiesLoading}
                        fetchData={fetchData}
                        perPage={data ? data['per_page'] : undefined}
                        totalPage={data ? data['last_page'] : undefined}
                    />
                }
            </Row>
    )
})

export default PresentPropertiesList;
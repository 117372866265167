import React, {useCallback, useMemo, useState} from "react";
import * as Yup from "yup";
import env from "../../helpers/env";
import PageContent from "../../Layouts/PageContent";
import {DatatablePageProvider} from "../../providers/DatatablePageProvider";
import {Text} from "../../Components/Common/ColTableFormat";
import Datatable from "./Datatable";
import {useFetchDataList} from "../../hooks";

const Society = () => {
    document.title ="Société | "+env('NAME');

    const descriptionRowTable = useMemo(() => {
        return [
            {
                Header: "Code",
                accessor: "company_code",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Dénomination",
                accessor: "company_name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
        ]
    }, []);

    const [file, setFile] = useState({
        name: "",
        file: null
    });

    const entityForm = useMemo(() => {
        return {
            title: {
                store: 'Ajouter une société',
                update: 'Modifier une société'
            },
            formDefaultError: {
                company_code: [], company_name: [], company_city: [],
                company_address: [], company_phone: [], company_sectors: [],
                company_description: [], company_logo: [], admin_name: [],
                admin_email: [], admin_address: [], admin_telephone: [],
                admin_first_name: [], admin_civility: []
            },
            initialValues: {
                company_code: '', company_name: '', company_city: '',
                company_address: '', company_phone: '', company_sectors: '',
                company_description: '', company_logo: '', admin_name: '',
                admin_email: '', admin_address: '', admin_telephone: '',
                admin_first_name: '', admin_civility: ''
            },
            validationSchema: Yup.object({
                company_code: Yup.string().required("Veuillez entrer le code"),
                company_name: Yup.string().required("Veuillez entrer le dénomination"),
                company_city: Yup.string().required("Veuillez entrer la ville"),
                company_address: Yup.string().required("Veuillez entrer l'adresse"),
                company_phone: Yup.string().required("Veuillez entrer le téléphone"),
                company_sectors: Yup.string().required("Veuillez entrer le secteur d'activité"),
                company_description: Yup.string().required("Veuillez entrer la description"),
                admin_name: Yup.string().required("Veuillez entrer le nom"),
                admin_email: Yup.string().required("Veuillez entrer l'email"),
                admin_address: Yup.string().required("Veuillez entrer l'adresse"),
                admin_telephone: Yup.string().required("Veuillez entrer le téléphone"),
                admin_first_name: Yup.string().required("Veuillez entrer le prénom"),
                admin_civility: Yup.string().required("Veuillez entrer la civilité"),
            }),
            formatFormData: (values) => {
                return {
                    ...values,
                    company_logo: file.file
                }
            },
            onSuccess: (response, type) => {},
            onFail: (response, type) => {},
        };
    }, [file]);

    return (
        <PageContent title={"Sociétés"} pageTitle={"Paramétrage"}>
            <DatatablePageProvider
                title={"Sociétés"}
                endpoint={"/api/companies"}
                descriptionRowTable={descriptionRowTable}
                entityForm={entityForm}
                storePermission={"store-company"}
                updatePermission={"edit-company"}
                deletePermission={"destroy-company "}
            >
                <Datatable
                    file={file}
                    setFile={setFile}
                />
            </DatatablePageProvider>
        </PageContent>
    );
};

export default Society;
import React from "react";

import {
    Card,
    Col,
    Row,
    UncontrolledCarousel,
} from "reactstrap";
import {Link} from "react-router-dom";
import {formatDate, serviceOrDirection} from "../../helpers";

// Using a class component, everything works without issue
export class PropertyDetailsPrint extends React.PureComponent {

    render() {
        let list = this.props.list;
        let attachments = this.props.attachments;
        let fileTemplate = this.props.fileTemplate;
        let planArray = this.props.planArray;

        return (
            <Row style={{padding : "25px"}}>
                <Col lg={3}>
                    <div className="sticky-side-div">
                        <Card className="ribbon-box border shadow-none right">
                            <img src={list?.qr_code?.link} alt="" className="img-fluid rounded" />
                        </Card>
                    </div>
                </Col>
                <Col lg={9}>
                    <div>
                        <h4>{list?.standard?.name}</h4>
                        <br/>
                        <h6>Utilisateur actuel</h6>

                        <div className="hstack gap-3 flex-wrap">
                            <div className="text-muted">Nom : <Link to="#" className="text-primary fw-medium">{list?.assignment_active?.staff?.last_name} {list?.assignment_active?.staff?.first_name}</Link></div>
                            <div className="vr"></div>
                            <div className="text-muted">{serviceOrDirection(list?.assignment_active?.managementable_type)} : <span className="text-body fw-medium">{list?.assignment_active?.staff?.staffable?.name}</span></div>
                            <div className="vr"></div>
                            <div className="text-muted">Téléphone : <span className="text-body fw-medium">{list?.assignment_active?.staff?.telephone}</span></div>
                        </div>

                        <div className="mt-4 text-muted">
                            <h5 className="fs-14">Description du bien :</h5>
                            <p>{list?.description}</p>
                        </div>
                        <div>
                            {typeof list === "object" && (
                                <>
                                    {(list.origin === "Acquisition" || list.origin === "Production / Livraison à soi-même") && (
                                        <>
                                            <div className="pt-3 border-top border-top-dashed mt-4">
                                                <Row>

                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Valeur Résiduel :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.residual_value}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Fournisseur :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.supplier?.name}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Numéro de facture :</p>
                                                            <div className="fs-15">{list.amortization_property?.invoice_number}</div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Numéro immobilier :</p>
                                                            <div className="fs-15">{list.amortization_property?.real_estate_number}</div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="pt-3 border-top border-top-dashed mt-4">
                                                <Row>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Montant d'acquisition :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.acquisition_amount}</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    )}

                                    {list.origin === "Location" && (
                                        <>
                                            <div className="pt-3 border-top border-top-dashed mt-4">
                                                <Row>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Valeur Résiduel :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.residual_value}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Numéro de contrat :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.contract_number}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Périodicité :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.period_of_rent}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Nombre de période :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.number_of_period}</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <div className="pt-3 border-top border-top-dashed mt-4">
                                                <Row>

                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Date de début du contrat :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.contract_begin_date}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Date de fin du contrat :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.contract_end_date}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Montant du loyer :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.amount_of_rent}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Status du contrat :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.contract_status}</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    )}

                                    {(list.origin === "Subvention 1" || list.orign === "Subvention 2" || list.origin === "Don") && (
                                        <>
                                            <div className="pt-3 border-top border-top-dashed mt-4">
                                                <Row>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Valeur Résiduel :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.residual_value}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Montant d'acquisition :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.acquisition_amount}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Taux de financement :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.rate_of_funding}</h5>
                                                        </div>
                                                    </Col>
                                                    <Col lg={3} sm={6}>
                                                        <div>
                                                            <p className="mb-2 text-uppercase fw-medium">Donateur :</p>
                                                            <h5 className="fs-15 mb-0">{list.amortization_property?.donor_name}</h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>

                        <UncontrolledCarousel className={'mt-4'}
                            interval={4000}
                            indicators={false}
                            items={list && list.uploads ? list.uploads.filter(item => item.type === "properties").map((item, index) => {
                                return {
                                    altText: " ",
                                    caption: " ",
                                    key: index + 1,
                                    src: item.link,
                                }
                            }) : []}
                        />
                    </div>
                </Col>

                <Row>
                    <h3 className={"text-center m-4"}>Rattachements</h3>
                    <div className="table-responsive">
                        <table className="table align-middle table-nowrap mb-0">
                            <thead>
                            <tr>
                                <th scope="col">Standard</th>
                                <th scope="col">Code immo</th>
                                <th scope="col">Référence</th>
                                <th scope="col">Etat</th>
                            </tr>
                            </thead>

                            <tbody>
                            {list && list.attachments ? (
                                list.attachments.map((item, index) => (
                                    <tr key={index}>
                                        <th scope="row">
                                            {item?.standard?.name}
                                        </th>
                                        <td>{item?.code_immo}</td>
                                        <td>{item?.reference}</td>
                                        <td>{item?.etat?.name}</td>
                                    </tr>
                                ))
                            ) : null}
                            </tbody>
                        </table>
                    </div>
                </Row>
                <Row>
                    <h3  className={"text-center m-4"}>Liste des transaferts</h3>
                    <div className="table-responsive">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                                <th scope="col">Date de transfert</th>
                                <th scope="col">Service ou Direction</th>
                                <th scope="col">Agent</th>
                                <th scope="col">Local</th>
                            </tr>
                            </thead>

                            <tbody>
                            {list && list.assignments ? (
                                list.assignments.map((item, index) => (
                                    <tr key={index}>
                                        <td>{formatDate(item.assignment_date)}</td>
                                        <td>{item?.managementable?.name}</td>
                                        <td>{item?.staff?.first_name} {item?.staff?.first_name}</td>
                                        <td>{item?.local?.name}</td>
                                    </tr>
                                ))
                            ) : null}
                            </tbody>
                        </table>
                    </div>
                </Row>
                <Row>
                    <h3  className={"text-center m-4"}>Amortissement</h3>
                    <div>
                        {typeof list === "object" && (
                            <>
                                {(list.origin === "Acquisition" || list.origin === "Production / Livraison à soi-même") && (
                                    <>
                                        {fileTemplate}

                                        {planArray}
                                    </>
                                )}

                                {list.origin === "Location" && (
                                    <>
                                        {fileTemplate}
                                    </>
                                )}

                                {(list.origin === "Subvention 1" || list.orign === "Subvention 2" || list.origin === "Don") && (
                                    <>
                                        {fileTemplate}

                                        {planArray}
                                    </>
                                )}
                            </>
                        )}
                    </div>

                </Row>

            </Row>
        );
    }
}

import React, {useEffect, useRef, useState} from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Label,
    Row,
    Button,
    Form,
    FormFeedback,
    Alert,
    Spinner
} from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import 'react-toastify/dist/ReactToastify.css';

//redux
import {withRouter, Link} from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import {useAuth, useAxios} from "../../hooks";
import {getConfig} from "../../helpers";

const Login = (props) => {
    const auth = useAuth();
    const request = useAxios();
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const [inputType, setInputType] = useState("password");

    useEffect(() => {
        const button = document.getElementById("password-addon");

        function clickHideButton () {
            setInputType(inputType === "text" ? "password" : "text");
        }

        if (button) {
            button.addEventListener("click", clickHideButton);
        }

        return () => {
            if (button) {
                button.removeEventListener("click", clickHideButton);
            }
        }
    }, [inputType]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Veuillez entrer votre Email"),
            password: Yup.string().required("Veuillez entrer votre Mot de passe"),
        }),
        onSubmit: (values) => {
            setLoading(true);
            const submitData = {
                grant_type: 'password',
                ...getConfig('AUTH.LARAVEL_PASSPORT'),
                username: values.email,
                password: values.password,

            }

            request.axios.post('/oauth/token', submitData)
                .then(response => {
                    if (error.length)
                        setError([]);
                    let currentDate = new Date();
                    let tokenData = response.data;
                    tokenData.auth_date = new Date(currentDate.getTime());
                    currentDate.setSeconds(currentDate.getSeconds() + response.data.expires_in - 900);
                    tokenData.expires_date = currentDate;
                    request.pushToken(tokenData.access_token);
                    request.axios.get('/api/login')
                        .then(response => {
                            const user = {
                                ...response.data.user,
                                token_data: tokenData,
                                zz_permissions: response.data['allPermissions'],
                                zz_company_with_type: response.data['companyWithType']
                            };
                            auth.login(user);
                        })
                        .catch(error => {})
                        .finally(() => setLoading(false))
                    ;
                })
                .catch(error => {
                    if (error.response.status === 422 || error.response.status === 400 ) {
                        setError(['Email ou Mot de passe incorrect'])
                    }
                    setLoading(false);
                })
            ;
        }
    });

    document.title = "Connexion | "+process.env.REACT_APP_NAME;
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={"/imgs/logo.png"} alt="" height="90" />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Content de te revoir !</h5>
                                            <p className="text-muted">Connectez-vous pour continuer vers {process.env.REACT_APP_NAME}.</p>
                                        </div>
                                        {error && error.length ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                action="#"
                                            >

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Entrez l'email"
                                                        type="email"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Mot de passe oublié?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Mot de passe</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            value={validation.values.password || ""}
                                                            type={inputType}
                                                            className="form-control pe-5"
                                                            placeholder="Entrez le mot de passe"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                {/*<div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Se souvenir de moi</Label>
                                                </div>*/}

                                                <div className="mt-4">
                                                    <Button color="primary" className="btn text-center btn-primary w-100" type="submit">
                                                        <span className="d-flex align-items-center justify-content-center">
                                                            {loading && (
                                                                <Spinner size="sm" className="flex-shrink-0 ml-2"> Loading... </Spinner>
                                                            )}
                                                            <span className="ms-2">{loading ? "Chargement" : "Connexion"}</span>
                                                        </span>
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);
import React, {useEffect} from 'react';
import { Switch, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
//routes
import {authProtectedRoutes, errorRoutes, publicRoutes} from "./allRoutes";
import {Auth, GroupAuth, Guest} from "../middlewares";
import {useAuth} from "../hooks";
import axios from "axios";

const Index = () => {
    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
    const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
    const availableErrorRoutesPath = errorRoutes.map((r) => r.path);
    const auth = useAuth();

    useEffect(() => {
        if (localStorage.getItem("user")){
            let data = JSON.parse(localStorage.getItem("user"))
            if (data.token_data && data.token_data?.access_token){
                 axios.get('/api/login', {
                    'Authorization' : `Bearer ${data.token_data.access_token}`
                })
                    .then(response => {
                        delete data.zz_permissions
                        const user = {
                            ...data,
                            zz_permissions: response.data['allPermissions'],
                        };
                        auth.login(user, false);
                    })
                    .catch(error => {})
                ;
            }
        }
    }, [])

    return (
        <React.Fragment>
            <Switch>
                <Route path={availablePublicRoutesPaths}>
                    <NonAuthLayout>
                        <Switch>
                            {publicRoutes.map((route, index) => (
                                <Guest key={index} path={route.path} exact={true}>
                                    {route.component}
                                </Guest>
                            ))}
                        </Switch>
                    </NonAuthLayout>
                </Route>

                <Route path={availableAuthRoutesPath}>
                    <GroupAuth>
                        <VerticalLayout>
                            <Switch>
                                {authProtectedRoutes.map((route, index) => (
                                    <Auth key={index} path={route.path} exact={true}>
                                        {route.component}
                                    </Auth>
                                ))}
                            </Switch>
                        </VerticalLayout>
                    </GroupAuth>
                </Route>

                <Route path={availableErrorRoutesPath}>
                    <NonAuthLayout>
                        <Switch>
                            {errorRoutes.map((route, index) => (
                                <Route key={index} path={route.path} exact={route.path !== "*"}>
                                    {route.component}
                                </Route>
                            ))}
                        </Switch>
                    </NonAuthLayout>
                </Route>
            </Switch>
        </React.Fragment>
    );
};

export default Index;
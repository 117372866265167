import moment from "moment";
import 'moment/locale/fr';
import config from "../config.js";
import {French} from "flatpickr/dist/l10n/fr";
import {toast} from "react-toastify";

export const getConfig = (key = "") => {
    let value = config;
    const keyList = key.split('.');
    for (let i = 0; i < keyList.length; i++) {
        if (keyList[i] in value) {
            value = value[keyList[i]];
        } else {
            return null;
        }
    }
    return value;
};

export const replaceNullToUndefined = (variable = undefined) => {
    if (variable === undefined)
        return null;
    return variable;
}

export const getStandardDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

export const isLogin = () => {
    const driver = getConfig('AUTH.DRIVER');
    const user = JSON.parse(driver.getItem('user'));

    if (user && user['token_data'] && user['token_data']['access_token']) {
        const expiresDate = new Date(user?.token_data?.expires_date);
        return new Date(replaceNullToUndefined(user?.token_data?.expires_date)) >= new Date();
    }
    return false;
}

export const user = () => {
    const driver = getConfig('AUTH.DRIVER');
    return JSON.parse(driver.getItem('user'));
}

export const userPermissions = () => {
    const driver = getConfig('AUTH.DRIVER');
    const user =  JSON.parse(driver.getItem('user'));
    if (user) {
        return user['zz_permissions'];
    }
    return [];
}

export const userHavePermissions = (permissions = [], verificationType = "allPermissions") => {
    if (!permissions.length)
        return true;
    let can = false;
    const allUserPermissions = userPermissions();
    if (verificationType === "oneIn") {
        permissions.forEach(item => {
            can = can || allUserPermissions.includes(item);
        });
        return can;
    }

    can = true;
    permissions.forEach(item => {
        can = can && allUserPermissions.includes(item);
    });
    return can;
};

export const getAxiosInstance = () => {
    const axios = require('axios');
    axios.defaults.baseURL = getConfig('AUTH.API_HOST')// the prefix of the URL
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.get['Accept'] = 'application/json';   // default header for all get request
    axios.defaults.headers.post['Accept'] = 'application/json';

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
        // Do something before request is sent

        return config;
    }, function (error) {
        // Do something with request error

        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if ([404, 401, 403, 500]?.includes(error.response.status)) {
            //window.location.pathname = `/${error.response.status}`;
            //toast.error("Oups ! ");
        }

        if ([422]?.includes(error.response.status)) {
           // toast.error("Oups! Votre formulaire contient des erreurs");
        }

        if ([403]?.includes(error.response.status)) {
            toast.error("Oups! Vous n'êtes pas autorisé à efectuer cette opérartion");
        }

         if ([500].includes(error.response.status)) {
            toast.error("Oups! une erreur est survenue.");
        }
        if ([409].includes(error.response.status)) {
            toast.error(error.response.data.message);
        }
        return Promise.reject(error);
    });
    return axios;
};

export const getMoment = () => {
    moment.locale("fr");
    return moment;
};

export const formatDate = (date = null) => {
    if (date)
    {
        return `${getMoment()(date).format("Do MMMM YYYY")}`
    }else{
        return null;
    }
    //return `${getMoment().format("Do MMMM YYYY")}`
};

export const formatDatInput = (date) => {
    if (!date)
        return null;
    if (date) {
        return `${getMoment()(date).format("y-MM-DD")}`
    }
    return `${getMoment().format("y-MM-DD")}`
};

export const formatDateAndHour = (date = null) => {
    if (date) {
        return `${getMoment(date).format("Do MMMM YYYY")} ${getMoment(date).format('h:mm')}`
    }
    return `${getMoment().format("Do MMMM YYYY")} ${getMoment().format('h:mm')}`
};

export const formatHour = (date = null) => {
    if (date) {
        return `${getMoment(date).format('h:mm')}`
    }
    return `${getMoment().format('h:mm')}`
};

export const removeNullValue = (obj) => {
    Object.keys(obj).forEach((key) => {
        if (obj[key] != 0 && !obj[key])
            delete obj[key];
    });
    return obj;
};

export const scrollTopPage = () => {
    document.getElementById("top-page").scrollIntoView(true);
}

export const serviceOrDirection = (data) => {
    if (!data) {
        return ""
    }
    const type = data.toString().split("\\")[2];
    return type ? type : "";
}

export function downloadReport(data,name = `export-${new Date()}`,format='xlsx') {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.${format}`);
    document.body.appendChild(link);
    link.click();
}

export function downloadDataUrlFromJavascript (filename, dataUrl) {
    // Construct the 'a' element
    const link = document.createElement("a");
    link.download = filename;
    link.target = "_blank";
    // Construct the URI
    link.href = dataUrl;
    document.body.appendChild(link);
    link.click();
    // Cleanup the DOM
    document.body.removeChild(link);
    //delete link;
}

export const dateRangeConfig = {
    wrap: true, // set wrap to true only when using 'input-group'
    altFormat: "M j, Y",
    altInput: true,
    dateFormat: "Y-m-d",
    mode: "range",
    locale:French
}
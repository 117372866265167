import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
    const history = useHistory();
    //state data
    const [isSetting, setIsSetting] = useState(false);
    const [isManageProperty, setIsManageProperty] = useState(false);
    const [isInventory, setIsInventory] = useState(false);
    const [isDashboard, setIsDashboard] = useState(false);
    const [isEtiquettage, setIsEtiquettage] = useState(false);
    const [isLocalisation, setIsLocalisation] = useState(false);
    const [isStaff, setIsStaff] = useState(false);
    const [isGood, setIsGood] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                const id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'setting') {
            setIsSetting(false);
        }

        if (iscurrentState !== 'dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'reporting') {
            setIsDashboard(false);
        }

        if (iscurrentState !== 'manage-property') {
            setIsManageProperty(false);
        }

        if (iscurrentState !== 'inventory') {
            setIsInventory(false);
        }

        if (iscurrentState !== 'localisation') {
            setIsLocalisation(false);
        }

        if (iscurrentState !== 'staff') {
            setIsStaff(false);
        }

        if (iscurrentState !== 'good') {
            setIsGood(false);
        }
        if (iscurrentState !== 'etiquettage') {
            setIsEtiquettage(false);
        }
    }, [
        history,
        iscurrentState,
        isSetting,
        isDashboard,
        isLocalisation,
        isStaff,
        isGood
    ]);

    const menuItems = [
        {
            label: "",
            isHeader: true,
        },
        {
            id: "dashboard",
            label: "Tableau de bord",
            icon: "ri-home-3-line",
            link: "/dashboard",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('dashboard');
            }
        },
        {
            id: "reporting",
            label: "Rapports",
            icon: "bx bx-chart",
            link: "/reporting",
            click: function (e) {
                e.preventDefault();
                setIscurrentState('reporting');
            }
        },
        {
            id: "manage-labeling",
            label: "Étiquettes",
            icon: "ri-article-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsEtiquettage(!isEtiquettage);
                setIscurrentState('manage-labeling');
                updateIconSidebar(e);
            },
            stateVariables: isEtiquettage,
            permissions: ["list-property", "list-property-out"],
            subItems: [
                { id: "inputs", label: "Biens", link: "/properties-labeling", parentId: "manage-labeling", permissions: ['list-property'] },
                { id: "movement", label: "Bureaux", link: "/locals-labeling", parentId: "manage-labeling", permissions: ['list-property'] },
            ],
        },

        {
            label: "Gestion des Immobilisations",
            isHeader: true,
        },
        {
            id: "manage-property",
            label: "Gestion des biens",
            icon: "ri-honour-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsManageProperty(!isManageProperty);
                setIscurrentState('manage-property');
                updateIconSidebar(e);
            },
            stateVariables: isManageProperty,
            permissions: ["list-property", "list-property-out"],
            subItems: [
                { id: "inputs", label: "Entrées", link: "/inputs", parentId: "manage-property", permissions: ['list-property'] },
                { id: "movement", label: "Transferts", link: "/movements", parentId: "manage-property", permissions: ['list-property'] },
                { id: "outputs", label: "Sorties", link: "/outputs", parentId: "manage-property", permissions: ['list-property-out'] },
            ],
        },
        {
            id: "inventory",
            label: "Inventaires",
            icon: "ri-dashboard-2-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsInventory(!isInventory);
                setIscurrentState('inventory');
                updateIconSidebar(e);
            },
            stateVariables: isInventory,
            permissions: ['list-inventory', 'history-inventories'],
            subItems: [
                { id: "inventories", label: "Créer inventaire", link: "/inventories", parentId: "inventory", permissions: ['store-inventory'] },
                { id: "inventory-history", label: "Historique inventaire", link: "/history-inventories", parentId: "inventory", permissions: ['history-inventories'] },
            ],
        },
        {
            label: "Parametrage",
            isHeader: true,
        },
        {
            id: "good",
            label: "Biens",
            icon: "ri-currency-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsGood(!isGood);
                setIscurrentState('good');
                updateIconSidebar(e);
            },
            stateVariables: isGood,
            permissions: ['list-etat', 'list-genre', 'list-family', 'list-supplier', 'list-standard', 'list-property'],
            subItems: [
                { id: "genders", label: "Genres", link: "/genders", parentId: "good", permissions: ['list-genre'] },
                { id: "family", label: "Familles", link: "/families", parentId: "good", permissions: ['list-family'] },
                { id: "standards", label: "Standards", link: "/standards", parentId: "good", permissions: ['list-standard'] },
                { id: "sub-standards", label: "Sous Standards", link: "/sub-standards", parentId: "good", permissions: ['list-standard'] },
                { id: "property-condition", label: "Etats des biens", link: "/property-condition", parentId: "good", permissions: ['list-etat'] },
            ],
        },
        {
            id: "staff",
            label: "Personnel",
            icon: "ri-team-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsStaff(!isStaff);
                setIscurrentState('staff');
                updateIconSidebar(e);
            },
            stateVariables: isStaff,
            permissions: ['list-staff', 'list-service', 'list-direction', 'list-position'],
            subItems: [
                { id: "staffs", label: "Agents", link: "/staffs", parentId: "staff", permissions: ['list-staff'] },
                { id: "inventoriste", label: "Inventoriste", link: "/inventoriste", parentId: "setting", permissions: ['list-user'] },
                { id: "users", label: "Comptes utilisateurs", link: "/users", parentId: "setting", permissions: ['list-user'] },
                { id: "services", label: "Organigramme", link: "/services", parentId: "staff", permissions: ['list-service'] },
                //{ id: "directions", label: "Directions", link: "/directions", parentId: "staff", permissions: ['list-direction'] },
                { id: "position", label: "Fonctions", link: "/positions", parentId: "staff", permissions: ['list-position'] },
            ],
        },
        {
            id: "localisation",
            label: "Localisation",
            icon: "ri-map-pin-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsLocalisation(!isLocalisation);
                setIscurrentState('localisation');
                updateIconSidebar(e);
            },
            stateVariables: isLocalisation,
            permissions: ['list-local', 'list-level', 'list-batiment', 'list-city', 'list-site'],
            subItems: [
                { id: "city", label: "Villes", link: "/cities", parentId: "localisation", permissions: ['list-city'] },
                { id: "site", label: "Sites", link: "/sites", parentId: "localisation", permissions: ['list-site'] },
                { id: "buildings", label: "Bâtiments", link: "/buildings", parentId: "localisation", permissions: ['list-batiment'] },
                { id: "level", label: "Niveaux", link: "/levels", parentId: "localisation", permissions: ['list-level'] },
                { id: "local", label: "Locaux", link: "/locales", parentId: "localisation", permissions: ['list-local'] },
            ],
        },
        {
            id: "setting",
            label: "Autres paramétrages",
            icon: "ri-settings-3-line",
            link: "/#",
            click: function (e) {
                e.preventDefault();
                setIsSetting(!isSetting);
                setIscurrentState('setting');
                updateIconSidebar(e);
            },
            stateVariables: isSetting,
            permissions: [
                'list-company', 'list-subsidie', 'list-user', 'list-role',
                'list-fiscal-year', 'list-mark', 'list-config'
            ],
            subItems: [
                { id: "society", label: "Sociétés", link: "/societies", parentId: "setting", permissions: ['list-company'] },
                //{ id: "subventions", label: "Origine", link: "/subventions", parentId: "setting", permissions: ['list-subsidie'] },
                { id: "roles", label: "Rôles", link: "/roles", parentId: "setting", permissions: ['list-role'] },
                { id: "accounting-exercises", label: "Exercices comptables", link: "/accounting-exercises", parentId: "setting", permissions: ['list-fiscal-year'] },
                { id: "marks", label: "Marques", link: "/marks", parentId: "setting", permissions: ['list-mark'] },
                { id: "supplier", label: "Fournisseurs", link: "/suppliers", parentId: "good", permissions: ['list-supplier'] },
                { id: "config", label: "Codification", link: "/config", parentId: "setting", permissions: ['list-config'] },
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
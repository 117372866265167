import Select from "react-select";

const ReactSelect = ({id, name, placeholder, value = null, onChange = () => {}, options, isLoading, isSearchable = true, isClearable = true, isMulti = false, error = [], disabled = false, onInputChange = null}) => {
    return (
        <>
            <Select
                id={id}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                options={options}
                isLoading={isLoading}
                onInputChange={onInputChange || (() => {})}
                isClearable={isClearable}
                isMulti={isMulti}
                isSearchable={isSearchable}
                isDisabled={disabled}
                noOptionsMessage={() => "Pas d'options disponible"}
            />

            {error.length > 0 && (
                <p style={{color: "#f06548", fontSize: "0.875em", marginTop: "0.25rem"}}>{error}</p>
            )}
        </>
    );
};

export default ReactSelect
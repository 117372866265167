import {Row} from "reactstrap";
import React, {useMemo} from "react";
import DataTable from 'react-data-table-component';
import {formatDate} from "../../../helpers";
import {Date, Text} from "../../Common/ColTableFormat";
import DbDatatable from "../../Common/DbDatatable";


const OutPropertiesList = (({data, entitiesLoading, fetchData}) => {

     const tableHeader = [

        {
            name: "Codification",
            selector: row => row.codification,
        },
        {
            name: "Standard",
            selector: row => row.standard.name,
        },
        {
            name: "Code immo",
            selector: row => row.code_immo,
        },
         {
             name: "Date d'acquisition",
             selector: row => row.date_of_purchase ? formatDate(row.date_of_purchase) : '--',
         },
        {
            name: "Date de mise en service",
            selector: row => row.date_of_use ? formatDate(row.date_of_use) : '--',
        },
        {
            name: "Etat du bien",
            selector:  row => row.etat.name,
        },
        {
            name: "Date de sorti",
            selector:  row => row.output_property.output_date ? formatDate(row.output_property.output_date) : '--'

        },
         {
             name: "Motif",
             selector:  row => row.output_property.motif,
         },
         {
             name: "Montant de la transaction",
             selector:  row => row.output_property.transaction_amount || '--',
         },
         {
             name: "Nom de l'acheteur",
             selector:  row => row.output_property.buyer_name || '--',
         },
    ]

    const columns = useMemo(() => {
        return [
            {
                Header: "Code ERP",
                accessor: "reference",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Codification",
                accessor: "codification",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Standard",
                accessor: "standard.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Code immo",
                accessor: "code_immo",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Local",
                accessor: "assignment_active.local.name",
                filterable: false,
                Cell: (cellProps) => {
                    return <Text {...cellProps} />;
                },
            },
            {
                Header: "Date d'acquisition",
                accessor: "date_of_purchase",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
            {
                Header: "Date de sorti",
                accessor: "output_property.output_date",
                filterable: false,
                Cell: (cellProps) => {
                    return <Date {...cellProps} />;
                },
            },
        ]
    }, []);


    return (
            <Row className={'p-4'}>
                {
                    <DbDatatable
                        columns={columns}
                        data={data ? data.data : []}
                        className="custom-header-css"
                        loading={entitiesLoading}
                        fetchData={fetchData}
                        perPage={data ? data['per_page'] : undefined}
                        totalPage={data ? data['last_page'] : undefined}
                    />
                }
            </Row>
    )
})

export default OutPropertiesList;
import {
    Card,
    Col,
    Row,
} from "reactstrap";
import ReactSelect from "../Common/ReactSelect";
import React, {useCallback, useEffect, useState} from "react";
import axios from "axios";
import {dateRangeConfig, downloadReport, getStandardDate, scrollTopPage} from "../../helpers";
import {toast} from "react-toastify";
import Filter2LineIcon from "remixicon-react/Filter2LineIcon";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/flatpickr.css";
import "./style.css";
import {French} from "flatpickr/dist/l10n/fr";
import PresentPropertiesList from "./partials/PresentPropertiesList";
import OutPropertiesList from "./partials/OutPropertiesList";
import InventoriedPropertiesList from "./partials/InventoriedPropertiesList";
import {GLOBAL_ERROR_MESSAGE} from "../../constants";

const ExportFilters = ((props) => {

    const [exportData, setExportData] = useState({
        link : 'api/export/properties-present',
        filter_link : 'api/export/filter/properties-present',
        file_name : 'rapport-biens-presents-'+new Date().getDate(),
        type: 'presents'
    });
    const [origin, setOrigin] = useState(null);
    const [etat, setEtat] = useState(null);
    const [staff, setStaff] = useState(null);
    const [inventorist, setInventorist] = useState(null);
    const [type_inventory_list, setTypeInventoryList] = useState(null);
    const [site, setSite] = useState(null);
    const [building, setBuilding] = useState(null);
    const [local, setLocal] = useState(null);
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();
    const [date, setDate] = useState();
    const [submitError, setSubmitError] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [data , setData] = useState(null)
    const [level, setLevel] = useState(null);
    const [standard, setStandard] = useState(null);
    const [standards, setStandards] = useState([]);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [users, setUsers] = useState([]);
    const [inventorists, setInventorists] = useState([]);
    const [inventory, setInventory] = useState([]);
    const [entities, setEntities] = useState(null);
    const [showFilters, setShowFilters] = useState(true);
    const [entitiesLoading, setEntitiesLoading] = useState(false);
    const [exporting, setExporting] = useState(false);
    const [begin_at, setBeginAt] = useState("");
    const [end_at, setEndAt] = useState("");
    const [filterData, setFilterData] = useState({
        sites: [],
        buildings: [],
        locals: []
    });
    const selectType = (data) => {
        setExportData(data)
    }
    const exportToExel = (format) => {
        let data = {
            format :format,
            staff_id : staff?.value,
            inventory_id : inventory?.value,
            type_inventory_list : type_inventory_list?.value,
            standard_id : standard?.map((item) => item.id),
            origin : origin?.map((item) => item.value),
            etat_id : etat?.map((item) => item.id),
            inventorist_id : inventorist?.map((item) => item.id),
            batiment_id : building?.map((item) => item.id),
            local_id : local?.map((item) => item.id),
            level_id : level?.map((item) => item.id),
            site_id : site?.map((item) => item.id),
            begin_at : begin_at,
            end_at : end_at,
        }
        setExporting(true)
        axios.post(exportData.link,data,
            {
                method: 'GET',
                responseType: 'blob'
            })
            .then(({data}) => {
                downloadReport(data, exportData.file_name, format)
                toast("Exportation effectuée avec succès", {type: 'success'});
            })
            .catch(error => {
                toast( "Echec d'exportation", {type: 'error'});
                if (error.response && error.response.status === 422) {
                    scrollTopPage();
                    setSubmitError({...error.response.data.error});
                }else{
                    console.error(error)
                }
            })
            .finally(() => setExporting(false));
    }
    const filterExportData = () => {
        fetchData()
    }
    const handleSiteChange = (value) => {
        setSite(value);
    };
    const handleBuildingChange = (value) => {
        setBuilding(value);
    }
    const handleLevelChange = (value) => {
        setLevel(value);
    }
    const handleEtatChange = (value) => {
        setEtat(value);
    }
    const handleOriginChange = (value) => {
        setOrigin(value);
    }
    const handleStaffChange = (value) => {
        setStaff(value);
    }
    const handleInventoristChange = (value) => {
        setInventorist(value);
    }
    const handleInventoryChange = (value) => {
        setInventory(value);
    }
    const handleTypeInventoristListChange = (value) => {
        setTypeInventoryList(value);
    }
    const handleDateChange = (value) => {
        let begin_at = value[0] ? getStandardDate(value[0]) : "";
        let end_at = value[1] ? getStandardDate(value[1]) : "";
        setBeginAt(begin_at)
        setEndAt(end_at)
    }

    useEffect(() => {
        axios.get(`${exportData.link}/create`)
            .then(({data}) => {
                setFilterData({
                    sites: formatData(data.sites),
                    etats: formatData(data.etats),
                    levels: formatData(data.levels),
                    inventories: formatData(data.inventories),
                    origins : formatData(data.origins),
                    buildings: formatData(data.batiments),
                    locals: formatData(data.locals),
                    staffs: [],
                    inventorists: [],
                })
                setStandards(formatData(data.standards));
                setUsers((data?.staff || []).map(item => ({
                    value: item.id,
                    label: `${item?.first_name || '-'} ${item?.last_name || '-'}`
                })));
                setInventorists((data?.inventoristes || []).map(item => ({
                    value: item.id,
                    label: `${item?.name}`
                })));
            })
            .catch(() => {console.error("Something is wrong")})
            .finally(() => {setLoadingCreate(false)});
    }, []);
    const formatData = useCallback((data) => {
        return data.map(item => ({
            ...item,
            value: item.id || item,
            label: item.name || item
        }));
    }, []);
    const fetchData = useCallback((pageIndex = 1, pageSize = 10, search = "", other = null) => {
        let data = {
            staff_id : staff?.value,
            inventory_id : inventory?.value,
            type_inventory_list : type_inventory_list?.value,
            standard_id : standard?.map((item) => item.id),
            origin : origin?.map((item) => item.value),
            etat_id : etat?.map((item) => item.id),
            inventorist_id : inventorist?.map((item) => item.value),
            batiment_id : building?.map((item) => item.id),
            local_id : local?.map((item) => item.id),
            level_id : level?.map((item) => item.id),
            site_id : site?.map((item) => item.id),
            begin_at : begin_at,
            end_at : end_at,
        }
        setEntitiesLoading(true);
        axios.post(`${exportData.filter_link}?page=${pageIndex}&per_page=${pageSize}&search=${search}`, data)
            .then(({data}) => {
                setEntities(data);
            })
            .catch(() => toast.error(GLOBAL_ERROR_MESSAGE, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" }))
            .finally(() => setEntitiesLoading(false));

    }, [exportData, level, standard, origin, etat, site, local, staff, building, inventory, inventorist, type_inventory_list, end_at, begin_at]);

    return(
        <div>
            <Row>
                <Col md={4} style={{cursor : 'pointer'}}>
                    <Card className={exportData.type === 'presents' ? 'bg-primary p-4 text-white' : 'p-4'}  onClick={() => {selectType({
                        link : 'api/export/properties-present',
                        filter_link : 'api/export/filter/properties-present',
                        file_name : 'rapport-biens-presents-' + new Date().getDate(),
                        type: 'presents'
                    })}}>
                        <h5 className={exportData.type === 'presents' ? 'text-white' : ''}>Etat des biens présents</h5>
                    </Card>
                </Col>
                <Col md={4} style={{cursor : 'pointer'}}>
                    <Card className={exportData.type === 'outs' ? 'bg-primary p-4 text-white' : 'p-4'} onClick={() => {
                        selectType({
                            link : 'api/export/properties-output',
                            filter_link : 'api/export/filter/properties-output',
                            file_name : 'rapport-biens-sortis-' + new Date().getDate(),
                            type: 'outs'
                        })
                    }}>
                        <h5 className={exportData.type === 'outs' ? 'text-white' : ''}>Etat des biens sortis</h5>
                    </Card>
                </Col>
                <Col md={4}  style={{cursor : 'pointer'}} onClick={() => {
                    selectType({
                        link : 'api/export/properties-inventory',
                        filter_link : 'api/export/filter/properties-inventory',
                        file_name : 'rapport-biens-inventories-' + new Date().getDate(),
                        type: 'invs'
                    })
                }}>
                    <Card className={exportData.type === 'invs' ? 'bg-primary p-4 text-white' : 'p-4'}>
                        <h5  className={exportData.type === 'invs' ? 'text-white' : ''}>Etat des biens inventoriés</h5>
                    </Card>
                </Col>
            </Row>
            <Card className={'p-4'}>
                {
                    showFilters && (
                        <div data-aos={"fade-in"}>

                            <Row className="g-2">
                                <h5 className={'mb-2'}>Exportation</h5>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        id="site"
                                        name="site"
                                        placeholder="Tous les sites"
                                        value={site}
                                        isMulti={true}
                                        onChange={handleSiteChange}
                                        options={filterData.sites}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        id="building"
                                        name="building"
                                        placeholder="Tous les batiments"
                                        value={building}
                                        isMulti={true}
                                        onChange={handleBuildingChange}
                                        options={filterData.buildings}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        placeholder="Tous les niveaux"
                                        value={level}
                                        isMulti={true}
                                        onChange={handleLevelChange}
                                        options={filterData.levels}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        id="local"
                                        name="local"
                                        placeholder="Tous les locaux"
                                        isMulti={true}
                                        value={local}
                                        onChange={value => {setLocal(value)}}
                                        options={filterData.locals}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        id="standard"
                                        name="standard"
                                        isMulti={true}
                                        placeholder="Tous les standards"
                                        value={standard}
                                        onChange={value => {setStandard(value)}}
                                        options={standards}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        id="etat"
                                        name="etat"
                                        placeholder="Tous les états"
                                        value={etat}
                                        isMulti={true}
                                        onChange={handleEtatChange}
                                        options={filterData.etats}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        placeholder="Toutes les origines"
                                        value={origin}
                                        onChange={handleOriginChange}
                                        isMulti={true}
                                        options={filterData.origins}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                <Col md={4} className={'mb-2'}>
                                    <ReactSelect
                                        id="staff"
                                        name="staff"
                                        placeholder={'Tout les personnel'}
                                        value={staff}
                                        onChange={handleStaffChange}
                                        options={users}
                                        isLoading={loadingCreate}
                                    />
                                </Col>
                                {
                                    exportData.type === 'invs' &&
                                    <>
                                        <Col md={4} className={'mb-2'}>
                                            <ReactSelect
                                                id="inventory"
                                                name="inventory"
                                                placeholder={'Tous les inventaires'}
                                                value={inventory}
                                                onChange={handleInventoryChange}
                                                options={filterData.inventories}
                                                isLoading={loadingCreate}
                                            />
                                        </Col>
                                        <Col md={4} className={'mb-2'}>
                                            <ReactSelect
                                                id="inventorist"
                                                name="inventorist"
                                                placeholder={'Tous les inventoristes'}
                                                value={inventorist}
                                                isMulti={true}
                                                onChange={handleInventoristChange}
                                                options={inventorists}
                                                isLoading={loadingCreate}
                                            />
                                        </Col>

                                            <Col md={4} className={'mb-2'}>
                                                <ReactSelect
                                                    id="type_inventory_list"
                                                    name="type_inventory_list"
                                                    placeholder="Tous les biens"
                                                    value={type_inventory_list}
                                                    onChange={handleTypeInventoristListChange}
                                                    options={[
                                                        {label : 'Biens inventoriés',value : 'inventoried'},
                                                        {label : 'Biens non inventoriés',value : 'not-inventoried'},
                                                        {label : 'Nouveaux biens retouvés',value : 'found'},
                                                    ]}
                                                    isLoading={loadingCreate}
                                                />
                                            </Col>
                                    </>
                                }

                                <Col md={4}>
                                    <Flatpickr
                                        placeholder={"Période"}
                                        className="form-control"
                                        options={
                                            {
                                                dateFormat: "Y-m-d",
                                                mode: "range",
                                                locale:French
                                            }
                                        }
                                        value={date}
                                        onChange={handleDateChange}
                                    />
                                </Col>
                            </Row>
                            <div className={'d-flex justify-content-end'}>
                                <button
                                    disabled={exporting}
                                    onClick={() => filterExportData()}
                                    className="btn btn-sm btn-danger">
                                    <i className="ri-filter-2-line align-bottom"></i> Filtrer
                                </button>&nbsp;
                                <button
                                    disabled={exporting}
                                    className="btn btn-sm btn-danger"
                                    onClick={() => exportToExel('pdf')}>
                                    <i className="ri-download-2-line align-bottom"></i> Exporter PDF
                                </button>&nbsp;
                                <button
                                    disabled={exporting}
                                    className="btn btn-sm btn-danger"
                                    onClick={() => exportToExel('xlsx')}>
                                    <i className="ri-download-2-line align-bottom"></i> Exporter Excel
                                </button>&nbsp;

                            </div>
                        </div>
                    )
                }
            </Card>
                <Card>
                    {
                        exportData.type === 'presents' &&
                            <PresentPropertiesList
                            data={entities}
                            entitiesLoading={entitiesLoading}
                            fetchData={fetchData}
                        />
                    }
                    {
                        exportData.type === 'outs' &&
                        <OutPropertiesList
                            data={entities}
                            entitiesLoading={entitiesLoading}
                            fetchData={fetchData}
                        />
                    }
                    {
                        exportData.type === 'invs' &&
                        <InventoriedPropertiesList
                            data={entities}
                            entitiesLoading={entitiesLoading}
                            fetchData={fetchData}
                        />
                    }
                </Card>
        </div>
    )

})


export default ExportFilters;
import {Container, Row} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
const PageContent = ({children, title = "Title", pageTitle = null, secondTitle = null}) => {
    return (
        <div className="page-content" id="top-page">
            <Container fluid>
                <Row>
                        <BreadCrumb
                        title={title}
                        pageTitle={pageTitle}
                        secondTitle={secondTitle}
                    />
                </Row>


                {children}
            </Container>
        </div>
    );
};

export default PageContent;